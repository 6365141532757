import React from 'react'
import styled from 'styled-components'
import { useBaseInfo, useDashboardIdpCertDetail } from 'apis/hooks'
import { Modal } from 'components/common/modal'
import { Card } from 'components/common/card'
import {
  BaseText,
  H5Text,
  MediumText,
  SemiBoldText,
} from 'components/common/text'
import { Column, Row } from 'components/common/layout'
import {
  FileWithIcon,
  LinkWithIcon,
} from 'components/scouts/common/attachment-with-icon'
import { TalentBadgeIcon } from 'components/certificate/talent-badge-icon'
import { IdpCertificate } from 'types/common'
import { HeaderText } from 'components/certificate/common/styles'
import { TraitTag } from 'components/certificate/common/certificate-tag'

export const TalentBadgeDetailModal: React.FC<{
  requestId: number
  idpCertificateId: number
  onClose: (e?: React.MouseEvent) => void
}> = ({ requestId, idpCertificateId, onClose }) => {
  const { data: baseInfo } = useBaseInfo()
  const { data: certDetail } = useDashboardIdpCertDetail(
    requestId,
    idpCertificateId,
  )
  if (!baseInfo || !certDetail) return null
  const idpCertificate = baseInfo.idpCertificates.find(
    (item) => item.id === idpCertificateId,
  ) as IdpCertificate
  return (
    <Modal width={580} onClose={onClose}>
      <Card style={{ padding: '32px 24px', gap: 36 }}>
        <Column style={{ alignItems: 'center', gap: 8 }}>
          <TalentBadgeIcon size={70} idpCertificate={idpCertificate} />
          <Column style={{ alignItems: 'center', gap: 16 }}>
            <Column style={{ alignItems: 'center', gap: 8 }}>
              <HeaderText>{idpCertificate.name}</HeaderText>
              <MediumText style={{ color: '#00325B', textAlign: 'center' }}>
                {idpCertificate.description}
              </MediumText>
            </Column>
            <Row style={{ gap: 8, flexWrap: 'wrap', justifyContent: 'center' }}>
              {idpCertificate.traits.map((trait, index) => (
                <TraitTag key={index} item={trait}>
                  {trait}
                </TraitTag>
              ))}
            </Row>
          </Column>
        </Column>
        {idpCertificate?.detailQuestions.length > 0 && (
          <Column style={{ gap: 32 }}>
            {certDetail.details.length > 0 ? (
              certDetail.details.map((item) => {
                const question = idpCertificate.detailQuestions.find(
                  (q) => q.id === item.questionId,
                )
                return (
                  <Column key={question?.id} style={{ gap: 8 }}>
                    <QuestionText>{question?.name}</QuestionText>
                    <AnswerCard>
                      <AnswerText>{item.answer}</AnswerText>
                    </AnswerCard>
                  </Column>
                )
              })
            ) : (
              <AnswerCard>
                <AnswerText style={{ textAlign: 'center' }}>
                  아직 상세 설명을 작성하지 않았어요.
                </AnswerText>
              </AnswerCard>
            )}
          </Column>
        )}
        {(certDetail.introFiles.length > 0 ||
          certDetail.introLinks.length > 0) && (
          <AttachmentContainer>
            {certDetail.introFiles.length > 0 && (
              <AttachmentSection>
                <SemiBoldText>파일</SemiBoldText>
                {certDetail.introFiles.map((item) => (
                  <FileWithIcon
                    key={item.id}
                    file={item}
                    isAccessible={item.isAccessible}
                  />
                ))}
              </AttachmentSection>
            )}
            {certDetail.introLinks.length > 0 && (
              <AttachmentSection>
                <SemiBoldText>웹 링크</SemiBoldText>
                {certDetail.introLinks.map((item) => (
                  <LinkWithIcon
                    key={item.id}
                    link={item}
                    isAccessible={item.isAccessible}
                  />
                ))}
              </AttachmentSection>
            )}
          </AttachmentContainer>
        )}
      </Card>
    </Modal>
  )
}

const AttachmentContainer = styled(Row)`
  gap: 24px;
  padding: 24px;
  align-items: start;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 0;
  }
`

const QuestionText = styled(H5Text)`
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.desc};
  }
`

const AnswerCard = styled(Card)`
  padding: 12px 16px;
  background-color: ${(p) => p.theme.color.background4};
`

const AnswerText = styled(BaseText)`
  line-height: 27px;
`

const AttachmentSection = styled(Column)`
  gap: 16px;
  flex: 1;
`
