import styled from 'styled-components'
import React from 'react'
import Image from 'next/legacy/image'

export const StartupLogo: React.FC<{
  src: string
  size: number
  mobileSize?: number
}> = ({ src, size, mobileSize }) => {
  return (
    <StartupLogoWrapper size={size} mobileSize={mobileSize ? mobileSize : size}>
      <Image
        src={src}
        layout='fill'
        objectFit='cover'
        alt='thumbnail'
        priority
      />
    </StartupLogoWrapper>
  )
}

const StartupLogoWrapper = styled.div<{ size: number; mobileSize: number }>`
  position: relative;
  width: ${(p) => p.size}px;
  min-width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 10%);
  border-radius: 8px;
  overflow: hidden;
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: ${(p) => p.mobileSize}px;
    min-width: ${(p) => p.mobileSize}px;
    height: ${(p) => p.mobileSize}px;
  }
`
