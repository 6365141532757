import { postRequestDashboardScoutProposalGenerate } from 'apis/request'
import { makeAutoObservable } from 'mobx'

export interface AutoMessage {
  name: string
  isLoading: boolean
  proposeReason?: string
  appealPoints?: string
}

export class AutoProposalMessageStore {
  autoMessages: {
    [requestId: number]: AutoMessage
  } = {}
  constructor() {
    makeAutoObservable(this)
  }

  setAutoMessages(requestId: number, params: AutoMessage) {
    this.autoMessages[requestId] = params
  }

  removeAutoMessage(requestId: number) {
    this.autoMessages = Object.keys(this.autoMessages)
      .filter((objKey) => Number(objKey) !== requestId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((newObj: any, key) => {
        newObj[Number(key)] = this.autoMessages[Number(key)]
        return newObj
      }, {})
  }

  removeAllAutoMessage() {
    this.autoMessages = {}
  }

  async requestAutoMessage(requestId: number, name: string) {
    this.setAutoMessages(requestId, { name, isLoading: true })
    const res = await postRequestDashboardScoutProposalGenerate(requestId, {
      fields: ['proposeReason', 'appealPoints'],
      refresh: false,
    })
    this.setAutoMessages(requestId, {
      name,
      isLoading: false,
      proposeReason: res.data?.proposeReason,
      appealPoints: res.data?.appealPoints,
    })
  }
}
