import styled from 'styled-components'
import { BaseText } from 'components/common/text'
import { Tag } from 'components/common/tag'

export const AssessmentDescText = styled(BaseText)`
  line-height: 24px;
`

export const ProfileItemTag = styled(Tag)`
  background: none;
`
