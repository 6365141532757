import React, { MouseEvent, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { ShadowCard } from 'components/common/card'
import { Column, Row } from 'components/common/layout'
import { BtnSize, BtnType, Button } from 'components/common/button'
import {
  NumberOrNull,
  WithIdName,
  WithIdNameNullableParentId,
  WithIdNameParentId,
} from 'types/common'
import {
  ChipSize,
  ChipType,
  ChipWrapper,
  DeletableChip,
} from 'components/common/chip'
import { getSelectedChildrenByParent } from 'utils/formatters'

export const DataPicker: React.FCC<{
  data?: WithIdNameParentId[]
  selectedData?: WithIdNameNullableParentId[]
  onClose?: () => void
  onReset?: () => void
  onDelete?: (item: WithIdName & { parentId?: NumberOrNull }) => void
}> = ({ children, data, selectedData, onClose, onReset, onDelete }) => {
  const [startX, setStartX] = useState(0)
  const [isDrag, setIsDrag] = useState(false)
  const deleteChipWrapperRef = useRef<HTMLDivElement>(null)
  const selectedChildrenByParent = useMemo(
    () => getSelectedChildrenByParent(data || [], selectedData || []),
    [data, selectedData],
  )

  const startDrag = (e: MouseEvent) => {
    if (!deleteChipWrapperRef) return
    setTimeout(() => setIsDrag(true), 100)
    setStartX(e.pageX + (deleteChipWrapperRef.current?.scrollLeft || 0))
  }
  const stopDrag = () => {
    setTimeout(() => setIsDrag(false), 100)
  }
  const moveDrag = (e: MouseEvent) => {
    if (!isDrag || !deleteChipWrapperRef) return
    deleteChipWrapperRef.current?.scrollTo({
      left: startX - e.pageX,
    })
  }
  const deleteChip = (item: WithIdName & { parentId?: NumberOrNull }) => {
    if (isDrag) return
    onDelete?.(item)
  }

  return (
    <Container>
      <Content>{children}</Content>
      <Column style={{ gap: 16 }}>
        {(data && data?.length > 0) ||
        (selectedData && selectedData?.length > 0) ? (
          <DeleteChipWrapper
            ref={deleteChipWrapperRef}
            onMouseDown={startDrag}
            onMouseUp={stopDrag}
            onMouseLeave={stopDrag}
            onMouseMove={moveDrag}
          >
            {data ? (
              <>
                {selectedChildrenByParent.map((parent) => {
                  if (parent.selectedChildren.length === 0) return
                  if (parent.total === parent.selectedChildren.length)
                    return (
                      <CustomDeletableChip
                        key={parent.id}
                        onClick={() => deleteChip(parent)}
                      >
                        {parent.name} 전체
                      </CustomDeletableChip>
                    )
                  return parent.selectedChildren.map((child) => {
                    return (
                      <CustomDeletableChip
                        key={child.id}
                        onClick={() => deleteChip(child)}
                      >
                        {parent.name} {child.name}
                      </CustomDeletableChip>
                    )
                  })
                })}
              </>
            ) : (
              <>
                {selectedData?.map((item) => (
                  <CustomDeletableChip
                    key={item.id}
                    onClick={() => deleteChip(item)}
                  >
                    {item.name}
                  </CustomDeletableChip>
                ))}
              </>
            )}
          </DeleteChipWrapper>
        ) : (
          ''
        )}
        <Bottom>
          {onReset && (
            <Button
              style={{ width: 120 }}
              size={BtnSize.MEDIUM}
              variant={BtnType.OUTLINE}
              onClick={onReset}
            >
              초기화
            </Button>
          )}
          {onClose && (
            <Button size={BtnSize.MEDIUM} onClick={onClose}>
              완료
            </Button>
          )}
        </Bottom>
      </Column>
    </Container>
  )
}

const CustomDeletableChip = styled(DeletableChip).attrs({
  isSelected: true,
  size: ChipSize.EXTRA_SMALL,
  variant: ChipType.SUPER_ROW,
})``

const Container = styled(ShadowCard)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    height: calc(100dvh - 40px);
    border: 0;
    padding: 20px;
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    width: 500px;
    min-height: 300px;
    max-height: 600px;
    border: 1px solid ${(p) => p.theme.color.line};
    padding: 24px;
  }
  z-index: 9999;
  gap: 16px;
  justify-content: space-between;
`

const Content = styled(Column)`
  overflow: hidden scroll;
  justify-content: flex-start;
`

const Bottom = styled(Row)`
  gap: 12px;
`

const DeleteChipWrapper = styled(ChipWrapper)`
  flex-wrap: nowrap !important;
  overflow: scroll;
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    display: none; // Chrome, Safari and Opera
  }
`
