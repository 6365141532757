import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { ScoutRequestAiAssessment } from 'types/common'
import { SemiBoldText } from 'components/common/text'
import { AssessmentDescText } from 'components/dashboard/scouts/profile/styles'
import { Image } from 'components/common/image'

const evaluateScore = (score: number, maxScore: number) => {
  const percentage = (score / maxScore) * 100
  if (percentage >= 85) {
    return 'good'
  } else if (percentage < 35) {
    return 'not'
  } else {
    return 'potential'
  }
}

export const AiAssessmentDescription: React.FC<{
  data: ScoutRequestAiAssessment
  isNarrow?: boolean
}> = ({ data, isNarrow }) => {
  return (
    <>
      {data.sections.map((x) => (
        <Item key={x.description}>
          <Row style={{ flex: 'none' }}>
            <Image
              src={`/${evaluateScore(x.score, x.maxScore)}-match-square.svg`}
              alt='match-square'
              width={24}
              height={24}
            />
          </Row>
          <Column style={{ gap: 8 }}>
            <TitleText>{x.description}</TitleText>
            {!isNarrow && <AssessmentDescText>{x.reason}</AssessmentDescText>}
          </Column>
        </Item>
      ))}
    </>
  )
}

const Item = styled(Row)`
  gap: 8px;
  align-items: flex-start;
`

const TitleText = styled(SemiBoldText)`
  line-height: 22px;
`
