import { Tag, TagSize, TagType } from 'components/common/tag'
import styled from 'styled-components'

export const IsStartupTag: React.FC = () => (
  <CommonTag variant={TagType.GREEN}>스타트업</CommonTag>
)

export const IsCsTag: React.FC = () => (
  <CommonTag variant={TagType.BLUE}>컴퓨터 관련 전공</CommonTag>
)

export const BirthYearTag: React.FC<{ birthYear: string }> = ({
  birthYear,
}) => <GrayTag size={TagSize.SMALL}>{birthYear.slice(2)}년생</GrayTag>

const CommonTag = styled(Tag).attrs({ size: TagSize.SMALL })`
  border-radius: 16px;
  line-height: normal;
`

const GrayTag = styled(CommonTag)`
  color: ${(p) => p.theme.color.gray1};
  border-color: ${(p) => p.theme.color.background2};
  background-color: ${(p) => p.theme.color.background2};
`
