import React from 'react'
import { useStores } from 'stores/globals'
import { ProfileNotFilledModal } from 'containers/positions/profile-not-filled-modal'
import { observer } from 'mobx-react'
import { useScoutMyRequest } from 'apis/hooks'
import { ProfileFillRequestWithFileModal } from 'containers/positions/profile-fill-request-with-file-modal'

const ProfileFillRequestModalWrapper = observer(() => {
  const { applyModalStore } = useStores()
  const { data: profile } = useScoutMyRequest()
  if (profile === undefined) return null
  return (
    <ProfileFillRequestWithFileModal
      profile={profile}
      onClose={() => applyModalStore.setIsProfileFillRequestModalOpen(false)}
    />
  )
})

export const PositionApplyPreconditionModal: React.FC = observer(() => {
  const { applyModalStore } = useStores()
  if (applyModalStore.isProfileNotFilledModalOpen)
    return <ProfileNotFilledModal />
  if (applyModalStore.isProfileFillRequestModalOpen)
    return <ProfileFillRequestModalWrapper />
  return null
})
