import styled from 'styled-components'
import { H4Text } from 'components/common/text'
import { Row } from 'components/common/layout'
import React from 'react'
import { StartupPositionDetail } from 'types/common'
import { MyLink } from 'components/common/link'
import { getDiffFromPast } from 'utils/formatters'
import { displayPositionName } from 'utils/displays'
import { StartupLogo } from 'components/startup/startup-logo'
import { Badge, BadgeColor } from 'components/common/badge'

export const PositionHeader: React.FC<{
  data: StartupPositionDetail
}> = ({ data }) => {
  const { name, careerType, experienceRange, createdAt, startup } = data
  return (
    <Row style={{ gap: 20 }}>
      <MyLink href={`/startups/${startup.id}`}>
        <StartupLogo src={startup.thumbnail} size={90} mobileSize={44} />
      </MyLink>
      <PositionInfoContainer>
        <PositionName>
          {displayPositionName(name, careerType, experienceRange)}
        </PositionName>
        {getDiffFromPast(createdAt) < 8 && (
          <Badge color={BadgeColor.ALERT} filled style={{ flex: 'none' }}>
            new
          </Badge>
        )}
      </PositionInfoContainer>
    </Row>
  )
}

const PositionName = styled(H4Text)`
  font-weight: ${(p) => p.theme.fontWeight.bold};
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h3};
  }
`

const PositionInfoContainer = styled(Row)`
  gap: 10px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`
