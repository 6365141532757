import React, { useState } from 'react'
import styled from 'styled-components'
import dynamic from 'next/dynamic'
import { DesktopHeaderTabs } from 'components/misc/header/desktop-header-tab-menu'
import { ResponsiveLayout, Row } from 'components/common/layout'
import { ContentContainer, MainContainer } from 'components/misc/header/styles'
import { ResponsiveDisplay } from 'components/common/layout'
import { HeaderLogo } from 'components/misc/header/header-logo'
import { PositionFilter } from 'components/positions/position-filter'
import { usePageScroll, useThrottle } from 'utils/hooks'

const DesktopHeaderProfile = dynamic(() => import('./desktop-header-profile'), {
  ssr: false,
})

const MobileHeaderTabs = dynamic(() => import('./mobile-header-tab-menu'), {
  ssr: false,
})

export const HeaderInPositionFilter: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true)
  usePageScroll(
    () => setIsVisible(false),
    () => setIsVisible(true),
    'scroll',
    100,
    useThrottle,
  )
  return (
    <>
      <MainContainer>
        <ContentContainer>
          <HeaderLogo />
          <Content>
            <DesktopHeaderTabs />
            <MobileHeaderTabs />
            <ResponsiveDisplay isDisableMobile>
              <DesktopHeaderProfile />
            </ResponsiveDisplay>
          </Content>
        </ContentContainer>
        <React.Suspense>
          {isVisible && (
            <Top>
              <PositionFilter />
            </Top>
          )}
        </React.Suspense>
      </MainContainer>
    </>
  )
}

const Content = styled(Row)`
  flex: 1;
  @media ${(p) => p.theme.deviceSize.desktop} {
    justify-content: space-between;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    position: relative;
    height: 100%;
  }
`

const Top = styled(ResponsiveLayout)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    padding: 20px;
  }
  padding: 20px 0;
  border-radius: 0;
`
