import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { IS_BROWSER } from 'infra/constants'
import { BaseInfo, Location, PositionType, WithIdName } from 'types/common'
import { StorageKey } from 'infra/storage-key'
import { getFitTechStacks } from 'utils/formatters'

export class PositionFilterStore {
  parentPositionTypes: number[] = []
  positionTypes: number[] = []
  careerTypes: number[] = []
  locations: number[] = []
  parentLocations: number[] = []
  techStacks: number[] = []
  isChanged: boolean = false
  resetScroll: boolean = false
  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: StorageKey.POSITION_FILTER_STORE,
      properties: [
        'parentPositionTypes',
        'positionTypes',
        'careerTypes',
        'locations',
        'parentLocations',
        'techStacks',
        'isChanged',
      ],
      storage: IS_BROWSER ? localStorage : undefined,
    })
  }

  setIsChanged(bool: boolean) {
    this.isChanged = bool
  }

  get isEmpty() {
    return [
      this.positionTypes,
      this.careerTypes,
      this.locations,
      this.techStacks,
    ].every((x) => x.length === 0)
  }

  setResetScroll(bool: boolean) {
    this.resetScroll = bool
  }
  // 유저 정보 기반 필터 적용 전략
  // 1. 최초 회원가입 시
  // 2. 로그인했는데 필터 하나도 없을 경우
  applyUserData(
    positionTypes: number[],
    experience: number,
    techStacks: number[],
  ) {
    this.positionTypes = positionTypes
    // TODO(gogo): 하드코딩 제거
    // NOTE(gogo): 1 => 신입, 2 => 경력
    this.careerTypes = experience === 1 ? [1] : [2]
    this.techStacks = techStacks
  }

  setPositionTypes(ids: number[]) {
    this.positionTypes = ids
    this.setResetScroll(true)
    this.setIsChanged(true)
  }

  setParentPositionTypes(ids: number[]) {
    this.parentPositionTypes = ids
  }

  toggleParentPositionType(id: number) {
    if (this.parentPositionTypes.includes(id)) {
      this.parentPositionTypes = this.parentPositionTypes.filter(
        (_id) => _id !== id,
      )
    } else {
      this.parentPositionTypes = [...this.parentPositionTypes, id]
    }
  }

  removeInvalidTechStacks(baseInfo?: BaseInfo) {
    if (!baseInfo) return
    const filteredTechStacks = new Set(
      getFitTechStacks(this.positionTypes, baseInfo.techStacks).map(
        (x) => x.id,
      ),
    )
    this.setTechStacks(this.techStacks.filter((x) => filteredTechStacks.has(x)))
  }

  toggleLocation(id: number) {
    if (this.locations.includes(id)) {
      this.locations = this.locations.filter((_id) => _id !== id)
    } else {
      this.locations = [...this.locations, id]
    }
    this.setResetScroll(true)
    this.setIsChanged(true)
  }

  setParentLocations(ids: number[]) {
    this.parentLocations = ids
  }

  toggleParentLocation(id: number) {
    if (this.parentLocations.includes(id)) {
      this.parentLocations = this.parentLocations.filter((_id) => _id !== id)
    } else {
      this.parentLocations = [...this.parentLocations, id]
    }
  }

  toggleTechStack(id: number) {
    if (this.techStacks.includes(id)) {
      this.techStacks = this.techStacks.filter((_id) => _id !== id)
    } else {
      this.techStacks = [...this.techStacks, id]
    }
    this.setResetScroll(true)
    this.setIsChanged(true)
  }

  resetCareerTypes() {
    this.careerTypes = []
  }

  toggleCareerTypes(id: number) {
    if (this.careerTypes.includes(id)) {
      this.careerTypes = this.careerTypes.filter((_id) => _id !== id)
    } else {
      this.careerTypes = [...this.careerTypes, id]
    }
    this.setResetScroll(true)
    this.setIsChanged(true)
  }

  togglePositionTypes(id: number) {
    if (this.positionTypes.includes(id)) {
      this.positionTypes = this.positionTypes.filter((_id) => _id !== id)
    } else {
      this.positionTypes = [...this.positionTypes, id]
    }
    this.setResetScroll(true)
    this.setIsChanged(true)
  }

  setLocations(ids: number[]) {
    this.locations = ids
    this.setResetScroll(true)
    this.setIsChanged(true)
  }

  setTechStacks(ids: number[]) {
    this.techStacks = ids
    this.setResetScroll(true)
    this.setIsChanged(true)
  }

  getSelectedValue(id: number[], data: WithIdName[]) {
    return data.filter((item) => id.includes(item.id)).map((item) => item.name)
  }

  getSelectedData(id: number[], data: WithIdName[]) {
    return data.filter((item) => id.includes(item.id))
  }

  getSelectedPositionTypes(id: number[], data: PositionType[]) {
    return data.filter((item) => id.includes(item.id))
  }

  getSelectedLocations(id: number[], data: Location[]) {
    return data.filter((item) => id.includes(item.id))
  }
}
