import React, { useState } from 'react'
import styled from 'styled-components'
import { ProposalMessageGuideBox } from 'components/scouts/proposal/proposal-message-guide-box'
import { TextArea } from 'components/common/input'
import { observer } from 'mobx-react'
import { useIsDesktop } from 'utils/hooks'

export const ProposalMessageInput: React.FC<{
  reason: string
  example: string
  value: string
  placeholder: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  autoFocus?: boolean
  onFocus?: () => void
  onBlur?: () => void
  activeInputRef?: React.RefObject<HTMLTextAreaElement>
}> = observer(
  ({
    reason,
    example,
    value,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    autoFocus,
    activeInputRef,
  }) => {
    const [isOpenBox, setIsOpenBox] = useState(false)
    const isDesktop = useIsDesktop()
    return (
      <div>
        <MessageArea
          as='textarea'
          ref={activeInputRef}
          value={value}
          onChange={onChange}
          onFocus={() => {
            setIsOpenBox(true)
            onFocus?.()
          }}
          onBlur={() => {
            setIsOpenBox(false)
            onBlur?.()
          }}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
        {isDesktop && isOpenBox && (
          <ProposalMessageGuideBox reason={reason} example={example} />
        )}
      </div>
    )
  },
)

const MessageArea = styled(TextArea)`
  :focus {
    border: 1px solid ${(p) => p.theme.color.primary4};
  }
`
