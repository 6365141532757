import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useBaseInfo } from 'apis/hooks'
import { observer } from 'mobx-react'
import { useStores } from 'stores/globals'
import { analytics } from 'infra/analytics'
import { Chip, ChipWrapper } from 'components/common/chip'
import { TechStackSelector } from 'components/misc/selector/tech-stack-selector'
import { displayFilterValue } from 'utils/displays'
import { DataPickerDropdown } from 'components/common/data-picker-dropdown'
import { CategorySelector } from 'components/misc/selector/category-selector'
import { WithIdNameNullableParentId, WithIdNameParentId } from 'types/common'
import { CheckBox } from 'components/common/check-box'
import { Column } from 'components/common/layout'

export const PositionFilter: React.FC = observer(() => {
  const { data: baseInfo } = useBaseInfo()
  const { positionFilterStore } = useStores()

  const remoteLocationId = useMemo(
    () => baseInfo?.locations.find((item) => item.isRemote)?.id,
    [baseInfo?.locations],
  )

  const positionTypeData = useMemo(
    () => baseInfo?.positionTypes.filter((item) => !item.isDeprecated) || [],
    [baseInfo?.positionTypes],
  )

  const locationData = useMemo(
    () => baseInfo?.locations.filter((item) => !item.isRemote) || [],
    [baseInfo?.locations],
  )

  const selectedPositionTypes = useMemo(
    () =>
      positionFilterStore.getSelectedPositionTypes(
        positionFilterStore.positionTypes,
        baseInfo?.positionTypes || [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseInfo?.positionTypes, positionFilterStore.positionTypes],
  )

  const selectedTechStacks = useMemo(
    () =>
      positionFilterStore.getSelectedData(
        positionFilterStore.techStacks,
        baseInfo?.techStacks || [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseInfo?.techStacks, positionFilterStore.techStacks],
  )

  const selectedCareerTypes = useMemo(
    () =>
      positionFilterStore.getSelectedData(
        positionFilterStore.careerTypes,
        baseInfo?.careerTypes || [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseInfo?.careerTypes, positionFilterStore.careerTypes],
  )

  const selectedLocations = useMemo(
    () =>
      positionFilterStore.getSelectedLocations(
        positionFilterStore.locations,
        baseInfo?.locations || [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseInfo?.locations, positionFilterStore.locations],
  )

  const resetPositionType = () => {
    positionFilterStore.setPositionTypes([])
    positionFilterStore.setParentPositionTypes([])
    positionFilterStore.removeInvalidTechStacks(baseInfo)
  }

  const deletePositionType = (item: WithIdNameNullableParentId) => {
    if (item.parentId) {
      positionFilterStore.togglePositionTypes(item.id)
    } else {
      positionFilterStore.setPositionTypes(
        selectedPositionTypes
          .filter((_item) => _item.parentId !== item.id)
          .map((_item) => _item.id),
      )
      positionFilterStore.togglePositionTypes(item.id)
    }
  }

  const deleteLocations = (item: WithIdNameNullableParentId) => {
    if (item.parentId) {
      positionFilterStore.toggleLocation(item.id)
    } else {
      positionFilterStore.setLocations(
        selectedLocations
          .filter((_item) => _item.parentId !== item.id)
          .map((_item) => _item.id),
      )
      positionFilterStore.toggleParentLocation(item.id)
    }
  }

  const getChangedParents = (
    selectedData: WithIdNameParentId[],
    data: WithIdNameParentId[],
  ) => {
    const parentIds = [...new Set(selectedData.map((item) => item.parentId))]
    const result: number[] = parentIds.filter(
      (parentId): parentId is number =>
        !!parentId &&
        data.filter((item) => item.parentId === parentId).length ===
          selectedData.filter((item) => item.parentId === parentId).length,
    )

    return result
  }

  return (
    <FilterWrapper>
      <DataPickerDropdown
        label='포지션'
        value={displayFilterValue(
          positionFilterStore.positionTypes,
          baseInfo?.positionTypes || [],
        )}
        data={positionTypeData}
        selectedData={selectedPositionTypes}
        badgeCount={selectedPositionTypes.length}
        onReset={resetPositionType}
        onDelete={deletePositionType}
      >
        <CategorySelector
          data={positionTypeData}
          selectedData={selectedPositionTypes}
          onChange={(positionTypes) => {
            positionFilterStore.setPositionTypes(
              positionTypes.map((item) => item.id),
            )
            positionFilterStore.setParentPositionTypes(
              getChangedParents(positionTypes, positionTypeData),
            )
            positionFilterStore.removeInvalidTechStacks(baseInfo)
          }}
          isFilter
        />
      </DataPickerDropdown>
      <DataPickerDropdown
        label='스킬'
        badgeCount={selectedTechStacks.length}
        value={selectedTechStacks[0]?.name}
        selectedData={selectedTechStacks}
        onReset={() => positionFilterStore.setTechStacks([])}
        onDelete={(item) => {
          positionFilterStore.setTechStacks(
            selectedTechStacks
              .filter((_item) => _item.id !== item.id)
              .map((_item) => _item.id),
          )
        }}
      >
        <TechStackSelector
          selectedPositionTypes={positionFilterStore.positionTypes}
          selected={positionFilterStore.techStacks}
          onClick={(item) => positionFilterStore.toggleTechStack(item.id)}
          isFilter
          isFullHight
        />
      </DataPickerDropdown>
      <DataPickerDropdown
        label='경력'
        badgeCount={selectedCareerTypes.length}
        value={selectedCareerTypes[0]?.name}
        selectedData={selectedCareerTypes}
        onReset={() => positionFilterStore.resetCareerTypes()}
        onDelete={(item) => positionFilterStore.toggleCareerTypes(item.id)}
      >
        <ChipWrapper>
          {baseInfo?.careerTypes
            .filter((item) => item.id !== 3)
            .map((item) => (
              <Chip
                key={item.id}
                isSelected={positionFilterStore.careerTypes.includes(item.id)}
                onClick={() => {
                  positionFilterStore.toggleCareerTypes(item.id)
                  analytics.track('click_fp_filter_career', {
                    careerId: positionFilterStore.careerTypes,
                  })
                }}
              >
                {item.name}
              </Chip>
            ))}
        </ChipWrapper>
      </DataPickerDropdown>
      <DataPickerDropdown
        label='위치'
        badgeCount={selectedLocations.length}
        value={displayFilterValue(
          positionFilterStore.locations,
          baseInfo?.locations || [],
        )}
        data={locationData}
        selectedData={selectedLocations}
        onReset={() => {
          positionFilterStore.setLocations([])
          positionFilterStore.setParentLocations([])
        }}
        onDelete={deleteLocations}
      >
        <Column style={{ gap: 16 }}>
          <div style={{ width: 'fit-content' }}>
            <CheckBox
              checked={
                remoteLocationId
                  ? positionFilterStore.locations.includes(remoteLocationId)
                  : false
              }
              onClick={() =>
                remoteLocationId &&
                positionFilterStore.toggleLocation(remoteLocationId)
              }
            >
              원격근무
            </CheckBox>
          </div>
          <CategorySelector
            data={locationData}
            selectedData={selectedLocations}
            onChange={(locations) => {
              positionFilterStore.setLocations(locations.map((item) => item.id))
              positionFilterStore.setParentLocations(
                getChangedParents(locations, locationData),
              )
            }}
            isFilter
          />
        </Column>
      </DataPickerDropdown>
    </FilterWrapper>
  )
})

const FilterWrapper = styled.div`
  width: 100%;
  @media ${(p) => p.theme.deviceSize.mobile} {
    display: grid;
    grid-template-columns: repeat(2, calc((100% - 10px) / 2));
    gap: 10px;
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
`
