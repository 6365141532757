import React from 'react'
import dynamic from 'next/dynamic'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'

const QuillEditor = dynamic(() => import('react-quill'), {
  ssr: false,
})

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ color: [] }, { background: [] }],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
  ],
}

const formats = [
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'indent',
  'color',
  'background',
  'link',
]

export const TextEditor: React.FC<{
  value: string
  onChange: (e: string) => void
  placeholder?: string
}> = ({ value, onChange, placeholder }) => (
  <CustomEditor
    value={value}
    onChange={onChange}
    modules={modules}
    formats={formats}
    placeholder={placeholder}
  />
)

export const TextViewer: React.FC<{
  value: string
  style?: React.CSSProperties
}> = ({ value, style }) => (
  <>
    <CustomViewer value={value} readOnly={true} style={style} />
    <div
      style={{ display: 'none' }}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  </>
)

const CustomEditor = styled(QuillEditor)`
  div.ql-snow {
    border-color: ${(p) => p.theme.color.gray3};
    background-color: ${(p) => p.theme.color.white1};
  }
  .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .ql-editor {
      height: 150px;
      font-family: Pretendard Variable;
      font-size: ${(p) => p.theme.fontSize.desc};
      color: ${(p) => p.theme.color.black1};
      line-height: 1.8;
      ul,
      ol {
        padding-left: 0;
        /* NOTE(JUNSEULGI): https://github.com/quilljs/quill/issues/2584 참고 */
        ${[...Array(9)].map((_, i) => {
          return `
          & li.ql-indent-${i + 1} {
            padding-left: ${i + 2.5}rem !important;
          }
        `
        })}
      }
    }
  }
`

export const CustomViewer = styled(CustomEditor)`
  white-space: pre-wrap;
  div.ql-snow {
    background: none;
  }
  .ql-toolbar {
    display: none;
  }
  .ql-container {
    border: none;
    .ql-editor {
      padding: 0;
      height: 100%;
      font-size: ${(p) => p.theme.fontSize.h5};
      @media ${(p) => p.theme.deviceSize.mobile} {
        font-size: ${(p) => p.theme.fontSize.default};
      }
    }
  }
`
