import React from 'react'
import { TagContainer } from 'components/common/tag'
import {
  DashboardScoutRequestOneQueryParams,
  Range,
  ScoutRequestFormDataKey,
  WithIdName,
} from 'types/common'
import { addHighlight } from 'utils/displays'
import { ProfileItemTag } from 'components/dashboard/scouts/profile/styles'

interface ConditionData {
  dataKey: ScoutRequestFormDataKey
  value: string
}

const getHighlightedConditions = (
  cond: ConditionData,
  salary: Range | null,
  serviceAreas: WithIdName[] | null,
  fundingRounds: WithIdName[] | null,
  params?: DashboardScoutRequestOneQueryParams,
) => {
  let value = cond.value

  if (!value.trim()) return null

  if (params) {
    if (
      cond.dataKey === 'salary' &&
      (params.maxSalary !== 10000 || params.minSalary !== 0) &&
      ((salary?.max === 10000 && salary.min === 0) ||
        (salary &&
          salary.min >= params.minSalary &&
          salary.min <= params.maxSalary))
    ) {
      value = addHighlight(value)
    }

    if (cond.dataKey === 'locations' && params.locations?.length) {
      value = addHighlight(value)
    }

    if (cond.dataKey === 'fundingRounds' && params.fundingRounds?.length) {
      const hasFundingRound = fundingRounds?.some((item) =>
        params.fundingRounds.includes(item.id),
      )
      if (hasFundingRound) value = addHighlight(value)
    }

    if (cond.dataKey === 'serviceAreas' && params.serviceAreas?.length) {
      const hasServiceArea = serviceAreas?.some((item) =>
        params.serviceAreas.includes(item.id),
      )
      if (hasServiceArea) value = addHighlight(value)
    }

    params.keywords?.forEach((keyword) => {
      value = addHighlight(value, keyword)
    })
  }

  return value
}

export const ConditionTags: React.FC<{
  data: ConditionData[]
  salary: Range | null
  serviceAreas: WithIdName[] | null
  fundingRounds: WithIdName[] | null
  params?: DashboardScoutRequestOneQueryParams
}> = ({ data, salary, serviceAreas, fundingRounds, params }) => {
  return (
    <TagContainer>
      {data.map((cond) => {
        const highlightedValue = getHighlightedConditions(
          cond,
          salary,
          serviceAreas,
          fundingRounds,
          params,
        )
        if (!highlightedValue) return null
        return (
          <ProfileItemTag
            key={cond.dataKey}
            dangerouslySetInnerHTML={{ __html: highlightedValue }}
          />
        )
      })}
    </TagContainer>
  )
}
