import React from 'react'
import styled from 'styled-components'
import { Column } from 'components/common/layout'
import { ProposalStore } from 'stores/scouts/proposal'
import { Condition, ScoutRequestFormDataKey } from 'types/common'
import { observer } from 'mobx-react'
import { analytics } from 'infra/analytics'
import { TextArea } from 'components/common/input'
import { CheckboxChip, ChipType } from 'components/common/chip'

const TEXT_AREA_PLACE_HOLDER = `조건에 대한 상세한 설명을 전달하면 인재가 더 긍정적으로 답장해올 거예요\n(선택 사항 / 상세 설명은 다음에도 사용하실 수 있도록 저장해드려요)`

export const ConditionItem: React.FC<{
  cond: Condition
  proposalStore: ProposalStore
  save: () => void
}> = observer(({ cond, proposalStore, save }) => {
  const selectFulfillment = (dataKey: ScoutRequestFormDataKey) => {
    proposalStore.setFulfillments(dataKey)
    analytics.track('click_dsp_important_condition', { dataKey })
  }

  return (
    <Column key={cond.dataKey} style={{ gap: 8 }}>
      <CheckboxChip
        variant={ChipType.FILLED_PRIMARY}
        onClick={() => {
          selectFulfillment(cond.dataKey)
          proposalStore.setIsLoading(true)
          save()
        }}
        isSelected={proposalStore.isDataKeySelected(cond.dataKey)}
      >
        {cond.value}
      </CheckboxChip>
      {proposalStore.isDataKeySelected(cond.dataKey) && (
        <TextInput
          value={proposalStore.getSelectedFulfillmentDesc(cond.dataKey)}
          onChange={(e) => {
            proposalStore.setConditionDescription(cond.dataKey, e.target.value)
            proposalStore.setIsLoading(true)
            save()
          }}
          placeholder={TEXT_AREA_PLACE_HOLDER}
          autoFocus
        />
      )}
    </Column>
  )
})

const TextInput = styled(TextArea)`
  :focus {
    border: 1px solid ${(p) => p.theme.color.primary4};
  }
`
