import React, { useRef } from 'react'
import { theme } from 'infra/theme'
import { Column } from 'components/common/layout'
import { Avatar } from 'components/common/avatar'
import { ManagerComment as ManagerCommentProps } from 'types/common'
import { useRouter } from 'next/router'
import { useDashboardStartupDetail } from 'apis/hooks'
import {
  ProfileDetailCommentCard,
  ProfileDetailCommentText,
  ProfileDetailNameText,
} from 'components/scouts/profile-detail/styles'

export const ManagerComment: React.FC<{
  managerComment: ManagerCommentProps
  lineClamp?: number
  isNarrow?: boolean
}> = ({ managerComment, lineClamp, isNarrow }) => {
  const { data: startup } = useDashboardStartupDetail()
  const router = useRouter()
  const textRef = useRef<HTMLSpanElement>(null)
  return (
    <ProfileDetailCommentCard>
      {!isNarrow && (
        <Column style={{ gap: 8, alignItems: 'center' }}>
          <Avatar size={40} src={managerComment.manager?.image} />
          <Column style={{ gap: 2, textAlign: 'center' }}>
            <ProfileDetailNameText>채용매니저</ProfileDetailNameText>
            <ProfileDetailNameText>
              {managerComment.manager?.name}
            </ProfileDetailNameText>
          </Column>
        </Column>
      )}
      <Column style={{ flex: 1, gap: 4, justifyContent: 'flex-start' }}>
        <ProfileDetailCommentText style={{ color: theme.color.gray2 }}>
          {router.pathname.includes('recommended')
            ? `${startup?.name}에 추천하는 이유`
            : '매니저 코멘트'}
        </ProfileDetailCommentText>
        <ProfileDetailCommentText ref={textRef} lineClamp={lineClamp}>
          {managerComment.comment}
        </ProfileDetailCommentText>
      </Column>
    </ProfileDetailCommentCard>
  )
}
