import React from 'react'
import styled from 'styled-components'
import { useRequestFormOptions, useScoutMyRequest, useUser } from 'apis/hooks'
import { Card } from 'components/common/card'
import { ProfileDetail } from 'components/scouts/search/profile-detail'
import { ApplicationFulfillment, ScoutRequestBase } from 'types/common'

export const ProfilePreview: React.FC<{
  shouldInduceToFill?: boolean
  joinReason?: string
  fitReason?: string
  fulfillments?: ApplicationFulfillment
}> = ({ shouldInduceToFill, joinReason, fitReason, fulfillments }) => {
  const { data: user } = useUser()
  const { data: myProfile } = useScoutMyRequest()
  const { data: option } = useRequestFormOptions()
  if (!user || !myProfile || !option) return null
  const request: ScoutRequestBase = {
    ...myProfile,
    isResumeAllowed: false,
    managerComment: null,
    isCommonCurated: false,
    image: user.image,
    summary: null,
    techStacks: user.interestedTechStacks,
    positions: user.interestedPositionTypes,
    assessMemo: null,
    workLevel: {
      id: myProfile.workLevel,
      name:
        option.workLevels.find((item) => myProfile.workLevel === item.id)
          ?.name || '',
    },
    files: myProfile.files.map((file) => {
      return { ...file, isAccessible: false }
    }),
    links: myProfile.links.map((link) => {
      return { ...link, isAccessible: false }
    }),
    locations: option.locations.filter((item) =>
      myProfile.locations.includes(item.id),
    ),
    serviceAreas: option.serviceAreas.filter((item) =>
      myProfile.serviceAreas.includes(item.id),
    ),
    fundingRounds: option.fundingRounds.filter((item) =>
      myProfile.fundingRounds.includes(item.id),
    ),
    idpCertificates: myProfile.idpCertificates
      .filter((idp) => idp.isCertified)
      .map((idp) => idp.id),
    saveLists: [],
    lastActiveAt: null,
    viewedMeAt: null,
    savedMeAt: null,
    respondedCount: 0,
    receivedMessageCount: 0,
    isProposed: false,
    hasPendingScoutProposal: false,
    hasApplication: false,
    birthYear: null,
    curatedAt: null,
    isDeleted: false,
    aiAssessment: null,
  }
  return (
    <Content>
      <ProfileDetail
        data={{ ...request, joinReason, fitReason, fulfillments }}
        shouldInduceToFill={shouldInduceToFill}
      />
    </Content>
  )
}

const Content = styled(Card)`
  padding: 56px 32px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 48px 16px;
  }
`
