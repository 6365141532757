import styled from 'styled-components'
import { theme } from 'infra/theme'
import { ShadowCard } from 'components/common/card'

export enum BtnSize {
  TEXT = 'text',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

const BtnSizeMapping = {
  [BtnSize.TEXT]: {
    padding: '8px 12px',
    fontSize: '15px',
  },
  [BtnSize.SMALL]: {
    padding: '8px 16px',
    fontSize: '13px',
  },
  [BtnSize.MEDIUM]: {
    padding: '12px',
    fontSize: '15px',
  },
  [BtnSize.LARGE]: {
    padding: '16px 24px',
    fontSize: '16px',
  },
}

export enum BtnType {
  PRIMARY = 'primary',
  PRIMARY2 = 'primary2',
  OUTLINE = 'outline',
  SECONDARY = 'secondary',
  KAKAO = 'kakao',
  DISABLE = 'disable',
  DISABLE_OUTLINE = 'disable-outline',
  ALERT = 'alert',
  ALERT_OUTLINE = 'alert-outline',
  LINEAR = 'linear',
  SUPER_LOW = 'super-low',
  SUPER_LOW_STRONG = 'super-low-strong',
  SUPER_LOW_OUTLINE = 'super-low-outline',
  MEDIUM = 'medium',
  TEXT_MEDIUM = 'text-medium',
  BLACK = 'black',
  WHITE = 'white',
  TEXT = 'text',
}

const HOVER_COLOR = '#FFFFFF1A'
const ACTIVE_COLOR = '#FFFFFF38'

const BtnTypeMapping = {
  [BtnType.PRIMARY]: {
    border: `1px solid ${theme.color.primary}`,
    background: theme.color.primary,
    hover: {
      background: `linear-gradient(${HOVER_COLOR}, ${HOVER_COLOR}), ${theme.color.primary}`,
    },
    active: {
      background: `linear-gradient(${ACTIVE_COLOR}, ${ACTIVE_COLOR}), ${theme.color.primary}`,
    },
  },
  [BtnType.PRIMARY2]: {
    border: `1px solid ${theme.color.primary3}`,
    background: theme.color.primary3,
    color: theme.color.primary4,
    hover: {
      background: `linear-gradient(#FFFFFF14, #FFFFFF14), ${theme.color.primary2}`,
    },
    active: {
      background: `linear-gradient(#FFFFFF1F, #FFFFFF1F), ${theme.color.primary2}`,
    },
  },
  [BtnType.OUTLINE]: {
    border: `1px solid ${theme.color.primary}`,
    background: theme.color.white1,
    color: theme.color.primary,
    hover: { background: `${theme.color.primary}14` },
    active: { background: `${theme.color.primary}1F` },
  },
  [BtnType.SECONDARY]: {
    background: theme.color.secondary,
    border: `1px solid ${theme.color.secondary}`,
    hover: {
      background: `linear-gradient(${HOVER_COLOR}, ${HOVER_COLOR}), ${theme.color.secondary}`,
    },
    active: {
      background: `linear-gradient(${ACTIVE_COLOR}, ${ACTIVE_COLOR}), ${theme.color.secondary}`,
    },
  },
  [BtnType.KAKAO]: {
    background: '#fee500',
    border: `1px solid #fee500`,
    color: '#3B4248',
    hover: '',
    active: '',
  },
  [BtnType.DISABLE]: {
    background: theme.color.gray4,
    border: `1px solid ${theme.color.gray4}`,
    cursor: 'default',
    hover: '',
    active: '',
  },
  [BtnType.DISABLE_OUTLINE]: {
    background: theme.color.white1,
    color: theme.color.gray2,
    border: `1px solid ${theme.color.gray2}`,
    cursor: 'default',
    hover: {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), ${theme.color.white1};`,
    },
    active: {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), ${theme.color.white1};`,
    },
  },
  [BtnType.ALERT]: {
    background: theme.color.alert,
    border: `1px solid ${theme.color.alert}`,
    hover: {
      background: `linear-gradient(#0000001A, #0000001A), ${theme.color.alert}`,
    },
    active: {
      background: `linear-gradient(#00000033, #00000033), ${theme.color.alert}`,
    },
  },
  [BtnType.ALERT_OUTLINE]: {
    border: `1px solid ${theme.color.alert}`,
    background: theme.color.white1,
    color: theme.color.alert,
    hover: '',
    active: '',
  },
  [BtnType.LINEAR]: {
    border: `1px solid ${theme.color.primary}`,
    background: theme.color.linear,
    hover: {
      background: `linear-gradient(${HOVER_COLOR}, ${HOVER_COLOR}), ${theme.color.linear}`,
    },
    active: {
      background: `linear-gradient(${ACTIVE_COLOR}, ${ACTIVE_COLOR}), ${theme.color.linear}`,
    },
  },
  [BtnType.SUPER_LOW]: {
    border: `1px solid #F0F0F0`,
    background: '#F0F0F0',
    color: theme.color.gray2,
    hover: {
      background: `linear-gradient(#AAAAAA1C, #AAAAAA1C), #F0F0F0`,
    },
    active: {
      background: `linear-gradient(#AAAAAA33, #AAAAAA33), #F0F0F0`,
    },
  },
  [BtnType.SUPER_LOW_STRONG]: {
    border: `1px solid #F0F0F0`,
    background: '#F0F0F0',
    color: theme.color.gray1,
    hover: {
      background: `linear-gradient(#AAAAAA1C, #AAAAAA1C), #F0F0F0`,
    },
    active: {
      background: `linear-gradient(#AAAAAA33, #AAAAAA33), #F0F0F0`,
    },
  },
  [BtnType.SUPER_LOW_OUTLINE]: {
    background: theme.color.white1,
    border: '1px solid #EDEDED',
    color: theme.color.gray2,
    hover: {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), ${theme.color.white1};`,
    },
    active: {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), ${theme.color.white1};`,
    },
  },
  [BtnType.MEDIUM]: {
    background: theme.color.primary6,
    border: '1px solid #006C4D',
    color: '#006C4D',
    hover: '',
    active: '',
  },
  [BtnType.TEXT_MEDIUM]: {
    background: 'none',
    border: `1px solid ${theme.color.white1}00`,
    color: theme.color.gray2,
    hover: {
      background: theme.color.primary6,
      color: '#006C4D',
      border: '1px solid #006C4D',
    },
    active: '',
  },
  [BtnType.BLACK]: {
    background: theme.color.black1,
    border: `1px solid ${theme.color.black1}`,
    color: theme.color.white1,
    hover: '',
    active: '',
  },
  [BtnType.WHITE]: {
    background: theme.color.white1,
    border: `1px solid ${theme.color.white1}`,
    color: theme.color.black1,
    hover: '',
    active: '',
  },
  [BtnType.TEXT]: {
    background: 'none',
    border: `1px solid ${theme.color.white1}00`,
    color: theme.color.gray2,
    hover: '',
    active: '',
  },
}

export const Button = styled.button<{
  variant?: BtnType
  size?: BtnSize
}>`
  width: 100%;
  line-height: 1.25;
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  white-space: nowrap;
  padding: ${(p) => BtnSizeMapping[p.size || BtnSize.LARGE].padding};
  font-size: ${(p) => BtnSizeMapping[p.size || BtnSize.LARGE].fontSize};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  color: ${(p) => p.theme.color.white1};
  user-select: none;
  cursor: pointer;
  ${(p) => BtnTypeMapping[p.variant || BtnType.PRIMARY]};
  :hover {
    ${(p) => BtnTypeMapping[p.variant || BtnType.PRIMARY].hover};
  }
  :active {
    ${(p) => BtnTypeMapping[p.variant || BtnType.PRIMARY].active};
  }
`

export const RoundButton = styled(Button)`
  border-radius: 50px;
`

export const TextButton = styled(Button).attrs({ size: BtnSize.TEXT })`
  font-weight: ${(p) => p.theme.fontWeight.medium};
`

export const FloatingButton = styled(ShadowCard)`
  position: fixed;
  right: 25px;
  bottom: 130px;
  width: 56px;
  height: 56px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  flex-direction: row;
  @media ${(p) => p.theme.deviceSize.mobile} {
    right: 20px;
    bottom: 24px;
    width: 48px;
    height: 48px;
  }
`
