import { EditableCareer, EditableOtherActivity } from 'types/common'

export const initialCareer: EditableCareer = {
  name: '',
  image: null,
  position: '',
  started: { year: '', month: '' },
  ended: { year: '', month: '' },
  isWorking: false,
  techStacks: [],
  description: '',
  isVerified: false,
  isCompleted: false,
  company: null,
  isStartup: false,
  serviceAreas: [],
  link: '',
  isCreatedByUser: true,
}

export const initialOtherActivity: EditableOtherActivity = {
  title: '',
  description: '',
  started: { year: '', month: '' },
  ended: { year: '', month: '' },
  isOnGoing: false,
  isCompleted: false,
  techStacks: [],
}
