import styled from 'styled-components'
import { H3Text, SemiBoldText } from 'components/common/text'

export const MainText = styled(H3Text)`
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h4};
  }
`

export const SubText = styled(SemiBoldText)<{ focus?: boolean }>`
  color: ${(p) => (p.focus ? p.theme.color.primary4 : p.theme.color.gray2)};
`
