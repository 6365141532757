import React, { Suspense, useEffect, useRef, useState } from 'react'
import Tippy from '@tippyjs/react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import {
  DashboardScoutRequestOneQueryParams,
  IdpCertificate,
} from 'types/common'
import { CertificateIcon } from 'components/certificate/common/certificate-icon'
import {
  ShadowBadgeDescCard,
  TalentBadgeDescCard,
} from 'components/certificate/talent-badge-desc'
import { TalentBadgeDetailModal } from 'components/scouts/profile/talent-badge-detail-modal'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useIsDesktopCSR } from 'utils/hooks'

const ICON_SIZE = 24
const ICON_STROKE_WIDTH = 1.3

export const CertificateIconSlider: React.FC<{
  data: IdpCertificate[]
  requestId: number
  params?: DashboardScoutRequestOneQueryParams
  isFullWidth?: boolean
}> = ({ data, requestId, params, isFullWidth = false }) => {
  const isDesktop = useIsDesktopCSR()
  const badgeWrapperRef = useRef<HTMLDivElement>(null)
  const [badgeModalId, setBadgeModalId] = useState<number | null>(null)
  const [curScrollLeft, setCurScrollLeft] = useState(0)
  const scrollToLeft = (badgeWrapperRef.current?.offsetWidth || 0) + 10
  const hasArrowBtn =
    badgeWrapperRef.current?.offsetWidth !==
    badgeWrapperRef.current?.scrollWidth
  useEffect(() => {
    badgeWrapperRef.current?.addEventListener('scroll', () => {
      setCurScrollLeft(badgeWrapperRef.current?.scrollLeft || 0)
    })
    return () => {
      badgeWrapperRef.current?.removeEventListener('scroll', () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setCurScrollLeft(badgeWrapperRef.current?.scrollLeft || 0)
      })
    }
  }, [badgeWrapperRef])

  return (
    <>
      <SliderWrapper>
        {hasArrowBtn && (
          <ArrowWrapper disabled={curScrollLeft <= 0}>
            <FiChevronLeft
              size={ICON_SIZE}
              strokeWidth={ICON_STROKE_WIDTH}
              onClick={(e) => {
                badgeWrapperRef.current?.scrollTo({
                  left: badgeWrapperRef.current.scrollLeft - scrollToLeft,
                  behavior: 'smooth',
                })
                e.stopPropagation()
              }}
            />
          </ArrowWrapper>
        )}
        <BadgeWrapper ref={badgeWrapperRef} isFullWidth={isFullWidth}>
          {data.map((item) => (
            <Tippy
              maxWidth={'100%'}
              key={item.id}
              delay={400}
              duration={0}
              placement='bottom-end'
              content={
                isDesktop ? (
                  <ShadowBadgeDescCard>
                    <TalentBadgeDescCard iconSize={48} idpCertificate={item} />
                  </ShadowBadgeDescCard>
                ) : undefined
              }
            >
              <IconWrapper
                onClick={(e) => {
                  setBadgeModalId(item.id)
                  e.stopPropagation()
                }}
              >
                {params?.idpCertificates.includes(item.id) && (
                  <BadgeHighlight />
                )}
                <CertificateIcon data={item} />
              </IconWrapper>
            </Tippy>
          ))}
        </BadgeWrapper>
        {hasArrowBtn && (
          <ArrowWrapper
            disabled={
              Math.ceil(
                curScrollLeft + (badgeWrapperRef.current?.offsetWidth || 0),
              ) >= (badgeWrapperRef.current?.scrollWidth || 0)
            }
          >
            <FiChevronRight
              size={ICON_SIZE}
              strokeWidth={ICON_STROKE_WIDTH}
              onClick={(e) => {
                badgeWrapperRef.current?.scrollTo({
                  left: badgeWrapperRef.current.scrollLeft + scrollToLeft,
                  behavior: 'smooth',
                })
                e.stopPropagation()
              }}
            />
          </ArrowWrapper>
        )}
      </SliderWrapper>
      {badgeModalId && (
        <Suspense>
          <TalentBadgeDetailModal
            requestId={requestId}
            idpCertificateId={badgeModalId}
            onClose={(e) => {
              setBadgeModalId(null)
              e?.stopPropagation()
            }}
          />
        </Suspense>
      )}
    </>
  )
}

const SliderWrapper = styled(Row)`
  gap: 8px;
  flex: none;
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
  }
`

const BadgeWrapper = styled(Row)<{ isFullWidth: boolean }>`
  gap: 12px;
  padding: 4px;
  max-width: ${(p) => (p.isFullWidth ? '100%' : `176px`)};
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    max-width: 100%;
  }
`

const IconWrapper = styled.div`
  flex: none;
  position: relative;
`

const BadgeHighlight = styled.div`
  z-index: 1;
  top: -3px;
  right: -3px;
  width: 54px;
  height: 54px;
  position: absolute;
  border-radius: 100px;
  background-color: #ffeb8faa;
`

const ArrowWrapper = styled(Row)<{ disabled: boolean }>`
  flex: none;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  justify-content: center;
  border: 1px solid ${(p) => p.theme.color.line};
  background-color: ${(p) => p.theme.color.white1};
  box-shadow: 0px 1px 8px rgba(161, 201, 255, 0.24);
  cursor: pointer;
  ${(p) => p.disabled && `opacity: 0.3`};
`
