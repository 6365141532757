import React from 'react'
import styled from 'styled-components'
import { Footer } from 'components/misc/footer'
import { Header } from 'components/misc/header'
import { DashboardHeader } from 'components/misc/header/dashboard'
import { useStartupUser } from 'apis/hooks'
import { getBannerHeight } from 'components/dashboard/header-banner'
import { AdminHeader } from 'components/admin/admin-header'
import { HeaderInPositionFilter } from 'components/misc/header/header-in-position-filter'

const Container = styled.div`
  padding-top: 60px;
`

const DashboardContainer = styled.div<{ top: number }>`
  padding-top: ${(p) => p.top}px;
`

export const DashboardBackground = styled.div`
  min-height: 100vh;
`

const AdminContainer = styled.div`
  padding: 40px 56px;
`

export const AdminBackground = styled.div`
  background-color: ${(p) => p.theme.color.background};
  min-height: 100vh;
`

export const WithHeaderInPositionFilterFooter: React.FCC = ({ children }) => {
  return (
    <>
      <HeaderInPositionFilter />
      <Container>{children}</Container>
      <Footer />
    </>
  )
}

export const WithHeaderFooter: React.FCC = ({ children }) => {
  return (
    <>
      <Header />
      <Container>{children}</Container>
      <Footer />
    </>
  )
}

export const WithHeader: React.FCC = ({ children }) => {
  return (
    <>
      <Header />
      <Container>{children}</Container>
    </>
  )
}

export const WithFooter: React.FCC = ({ children }) => {
  return (
    <>
      <Container>{children}</Container>
      <Footer />
    </>
  )
}

export const WithDashboardHeader: React.FCC<{ isHeaderDisabled?: boolean }> = ({
  children,
  isHeaderDisabled,
}) => {
  const { data: user } = useStartupUser()
  return (
    <DashboardBackground>
      {!isHeaderDisabled && <DashboardHeader />}
      <DashboardContainer
        top={isHeaderDisabled ? 0 : getBannerHeight(user) + 60}
      >
        {children}
      </DashboardContainer>
    </DashboardBackground>
  )
}

export const WithAdminHeader: React.FCC = ({ children }) => (
  <AdminBackground>
    <AdminHeader />
    <AdminContainer>{children}</AdminContainer>
  </AdminBackground>
)
