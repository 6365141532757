import { Column } from 'components/common/layout'
import { MyLink } from 'components/common/link'
import { SemiBoldText } from 'components/common/text'
import {
  DefaultErrorSubText,
  ErrorContainer,
  H1ErrorTitle,
  SmallErrorSubText,
} from 'components/misc/error'

export const ErrorFallback: React.FC = () => {
  return (
    <ErrorContainer hasReloadBtn>
      <Column>
        <SemiBoldText>서비스 이용에 불편을 드려 죄송합니다.</SemiBoldText>
        <H1ErrorTitle>오류가 발생했습니다.</H1ErrorTitle>
      </Column>
      <Column style={{ gap: 10 }}>
        <Column>
          <DefaultErrorSubText>
            요청하신 페이지를 찾던 중에 오류가 발생했습니다.
          </DefaultErrorSubText>
          <DefaultErrorSubText>
            같은 오류가 반복되면 알려주세요. 빠르게 해결하겠습니다.
          </DefaultErrorSubText>
        </Column>
        <MyLink
          href='https://accounts.kakao.com/login?continue=http%3A%2F%2Fpf.kakao.com%2F_xjWPxoK%2Fchat'
          newTab
        >
          <SmallErrorSubText>제보하기</SmallErrorSubText>
        </MyLink>
      </Column>
    </ErrorContainer>
  )
}
