import React from 'react'
import styled from 'styled-components'
import Image from 'next/legacy/image'
import { Row } from 'components/common/layout'
import { MyLink } from 'components/common/link'
import { DescText } from 'components/common/text'
import { useRouter } from 'next/router'
import { IS_MAIN_PROD } from 'infra/constants'

const ADMIN_BASE_PATH = '/admin'

export const AdminHeader = () => {
  const router = useRouter()
  return (
    <Container>
      <MyLink href={ADMIN_BASE_PATH}>
        <Image src='/admin.svg' width={80} height={40} alt='admin page logo' />
      </MyLink>
      <MyLink href={`${ADMIN_BASE_PATH}/tracking-hire-beta`}>
        <MenuText
          isSelected={router.pathname.includes(
            `${ADMIN_BASE_PATH}/tracking-hire-beta`,
          )}
        >
          채용 추적 Beta
        </MenuText>
      </MyLink>
      <MenuText>|</MenuText>
      <MyLink href={ADMIN_BASE_PATH}>
        <MenuText isSelected={router.pathname === ADMIN_BASE_PATH}>
          인재 추천
        </MenuText>
      </MyLink>
      <MyLink href={`${ADMIN_BASE_PATH}/tracking-hire`}>
        <MenuText
          isSelected={router.pathname === `${ADMIN_BASE_PATH}/tracking-hire`}
        >
          채용 추적
        </MenuText>
      </MyLink>
      <MyLink href={`${ADMIN_BASE_PATH}/hire-status`}>
        <MenuText
          isSelected={router.pathname === `${ADMIN_BASE_PATH}/hire-status`}
        >
          채용 현황
        </MenuText>
      </MyLink>
      <MenuText>|</MenuText>
      <MyLink href={`${ADMIN_BASE_PATH}/startups`}>
        <MenuText
          isSelected={router.pathname === `${ADMIN_BASE_PATH}/startups`}
        >
          기업 관리
        </MenuText>
      </MyLink>
      <MyLink href={`${ADMIN_BASE_PATH}/users`}>
        <MenuText isSelected={router.pathname === `${ADMIN_BASE_PATH}/users`}>
          인재 관리
        </MenuText>
      </MyLink>
      <MyLink href={`${ADMIN_BASE_PATH}/analytics`}>
        <MenuText
          isSelected={router.pathname === `${ADMIN_BASE_PATH}/analytics`}
        >
          기업 전환
        </MenuText>
      </MyLink>
      <MenuText>|</MenuText>
      <MyLink href={`${ADMIN_BASE_PATH}/file-anonymization`}>
        <MenuText
          isSelected={
            router.pathname === `${ADMIN_BASE_PATH}/file-anonymization`
          }
        >
          첨부 파일 익명화
        </MenuText>
      </MyLink>
      <MyLink href={`${ADMIN_BASE_PATH}/certificate-badge`}>
        <MenuText
          isSelected={
            router.pathname === `${ADMIN_BASE_PATH}/certificate-badge`
          }
        >
          인재 배지 관리
        </MenuText>
      </MyLink>
      {!IS_MAIN_PROD && (
        <>
          <MenuText>|</MenuText>
          <MyLink href={`${ADMIN_BASE_PATH}/create-account`}>
            <MenuText
              isSelected={
                router.pathname === `${ADMIN_BASE_PATH}/create-account`
              }
            >
              테스트 계정 관리
            </MenuText>
          </MyLink>
        </>
      )}
    </Container>
  )
}

const Container = styled(Row)`
  z-index: 6;
  position: sticky;
  top: 0;
  padding: 16px 56px;
  gap: 32px;
  background-color: ${(p) => p.theme.color.white1};
`

const MenuText = styled(DescText)<{ isSelected?: boolean }>`
  font-weight: ${(p) => p.theme.fontWeight.medium};
  color: ${(p) => p.theme.color.gray2};
  :hover {
    color: ${(p) => p.theme.color.black1};
  }
  ${(p) =>
    p.isSelected &&
    `color: ${p.theme.color.black1}; font-weight: ${p.theme.fontWeight.semiBold}`}
`
