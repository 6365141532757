import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { BaseText, H1Text } from 'components/common/text'
import { BtnSize, BtnType, Button } from 'components/common/button'

export const ErrorContainer: React.FCC<{ hasReloadBtn?: boolean }> = ({
  children,
  hasReloadBtn,
}) => {
  return (
    <Container>
      {children}
      <BtnWrapper>
        {hasReloadBtn && (
          <LinkBtn onClick={() => window.location.reload()}>새로고침</LinkBtn>
        )}
        <LinkBtn
          variant={hasReloadBtn ? BtnType.OUTLINE : BtnType.PRIMARY}
          onClick={() => {
            window.location.href = '/'
          }}
        >
          홈으로
        </LinkBtn>
      </BtnWrapper>
    </Container>
  )
}

export const H1ErrorTitle = styled(H1Text)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h3};
  }
`

export const DefaultErrorSubText = styled(BaseText)`
  color: ${(p) => p.theme.color.gray2};
`

export const SmallErrorSubText = styled(DefaultErrorSubText)`
  font-size: ${(p) => p.theme.fontSize.small};
  text-decoration: underline;
`

const Container = styled(Column)`
  margin: 0 20px;
  min-height: 100vh;
  gap: 50px;
  align-items: center;
  text-align: center;
`

const BtnWrapper = styled(Row)`
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`

const LinkBtn = styled(Button).attrs({ size: BtnSize.MEDIUM })`
  width: 160px;
`
