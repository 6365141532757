import { useRef } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

export const TriggerExceptTarget: React.FCC<{
  onTrigger: () => void
}> = ({ children, onTrigger }) => {
  const dropDownRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(dropDownRef, onTrigger)
  return <div ref={dropDownRef}>{children}</div>
}
