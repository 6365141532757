import { YOUTUBE_EMBED_URL } from 'infra/constants'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { LoadingSpinner } from 'components/common/loading-spinner'
import getYouTubeID from 'get-youtube-id'

export const Video: React.FC<{
  url: string
  onVideoClick?: () => void
}> = ({ url, onVideoClick }) => {
  const [isPlayed, setIsPlayed] = useState(false)
  const videoRef = useRef<HTMLIFrameElement>(null)
  const src = `${YOUTUBE_EMBED_URL}/${getYouTubeID(url)}`
  return (
    <FrameWrapper>
      <LoadingBox className='loading'>
        <LoadingSpinner />
      </LoadingBox>
      <Frame
        ref={videoRef}
        className='video'
        width='560'
        height='315'
        src={src}
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
      {!isPlayed && (
        <div
          className='overlay'
          onClick={() => {
            if (videoRef.current)
              videoRef.current.attributes[3].value = src + '?autoplay=1'
            onVideoClick?.()
            setIsPlayed(true)
          }}
        />
      )}
    </FrameWrapper>
  )
}

const FrameWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  & .overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`

const LoadingBox = styled(Row)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: ${(p) => p.theme.color.white1};
  border: 1px solid ${(p) => p.theme.color.gray3};
`

const Frame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
