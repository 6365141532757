import { useDashboardStartupDetail, useStartupUser } from 'apis/hooks'
import { ShadowCard } from 'components/common/card'
import { ResponsiveDisplay } from 'components/common/layout'
import { BoldText } from 'components/common/text'
import { LinkWithMenuTitle } from 'components/misc/header/link-with-menu-title'
import {
  HeaderMenuLine,
  LogoutHeaderMenuTitle,
} from 'components/misc/header/styles'
import { useRouter } from 'next/router'
import React from 'react'
import { useStores } from 'stores/globals'
import styled from 'styled-components'

export const DashboardDropdownMenu: React.FC = () => {
  const { loginStore } = useStores()
  const { data: user } = useStartupUser()
  const { data: startup } = useDashboardStartupDetail()
  const router = useRouter()
  return (
    <SmallDropdownMenu>
      <ResponsiveDisplay isDisableMobile>
        <StartupTitle>{user?.username || startup?.name}</StartupTitle>
        <HeaderMenuLine style={{ margin: '5px 0' }} />
      </ResponsiveDisplay>
      <LinkWithMenuTitle href='/dashboard/pricing'>
        채용 수수료
      </LinkWithMenuTitle>
      <ResponsiveDisplay isDisableDesktop>
        <LinkWithMenuTitle href='/dashboard/startup'>
          기업 정보
        </LinkWithMenuTitle>
      </ResponsiveDisplay>
      <LinkWithMenuTitle href='/dashboard/account'>설정</LinkWithMenuTitle>
      <LogoutHeaderMenuTitle
        onClick={async () => {
          await router.push('/dashboard/login')
          loginStore.logoutStartup()
        }}
      >
        로그아웃
      </LogoutHeaderMenuTitle>
    </SmallDropdownMenu>
  )
}

const StartupTitle = styled(BoldText)`
  display: block;
  padding: 8px 10px;
`

const SmallDropdownMenu = styled(ShadowCard)`
  z-index: 50;
  top: 42px;
  right: -10px;
  width: 100px;
  position: absolute;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid ${(p) => p.theme.color.line};
  border-radius: 8px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    top: 60px;
  }
`
