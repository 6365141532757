import { makeAutoObservable } from 'mobx'

export class PositionApplyModalStore {
  isOpen: boolean = false
  startupId: number = 0
  positionId: number = 0
  isProfileNotFilledModalOpen: boolean = false
  isProfileFillRequestModalOpen: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  openModal(positionId: number, startupId: number) {
    this.startupId = startupId
    this.positionId = positionId
    this.isOpen = true
  }

  closeModal() {
    this.startupId = 0
    this.positionId = 0
    this.isOpen = false
  }

  setIsProfileNotFilledModalOpen(isOpen: boolean) {
    this.isProfileNotFilledModalOpen = isOpen
  }

  setIsProfileFillRequestModalOpen(isOpen: boolean) {
    this.isProfileFillRequestModalOpen = isOpen
  }
}
