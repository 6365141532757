import React, { useState } from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { useRouter } from 'next/router'
import { DashboardMenuTitleWithLink } from 'components/misc/header/link-with-menu-title'
import { useDashboardApplicationsSummary } from 'apis/hooks'
import { AlertBadge } from 'components/common/badge'
import { GuideBalloon } from 'components/scouts/landing/cta-button'
import { FiX } from 'react-icons/fi'
import { HIDE_SCOUT_PAGE_NOTICE } from 'infra/constants'
import { analytics } from 'infra/analytics'
import { getScoutPageNoticeStore } from 'utils/storages'
import { sumApplicantCount } from 'utils/formatters'

export const DashboardHeaderTabMenu: React.FC = () => {
  const router = useRouter()
  const { data: summary } = useDashboardApplicationsSummary()
  const hideScoutProfileNotice = getScoutPageNoticeStore()
  const [isHideNotice, setIsHideNotice] = useState(hideScoutProfileNotice)
  const applicantCount = sumApplicantCount(summary || [])

  return (
    <Row>
      <div style={{ position: 'relative' }}>
        {!isHideNotice && (
          <BetaNotice isDisableAnimation>
            🔥 인재들의 평균 채팅 수락률 55%
            <FiX
              size={14}
              style={{ cursor: 'pointer', flex: 'none' }}
              onClick={() => {
                localStorage.setItem(HIDE_SCOUT_PAGE_NOTICE, 'true')
                setIsHideNotice(true)
                analytics.track(
                  'searchProfile_compu_click_scout_page_notice_close',
                )
              }}
            />
          </BetaNotice>
        )}
        <DashboardMenuTitleWithLink
          href='/dashboard/scouts/search/new-profile'
          isSelected={router.pathname.includes('/dashboard/scouts')}
        >
          스카우트 관리
        </DashboardMenuTitleWithLink>
      </div>
      <DashboardMenuTitleWithLink
        href='/dashboard/applicants'
        isSelected={router.pathname.includes('/dashboard/applicants')}
      >
        <div style={{ position: 'relative' }}>
          {applicantCount > 0 && (
            <AlertBadge top={-10} right={-10} size={20}>
              {applicantCount}
            </AlertBadge>
          )}
          지원자 관리
        </div>
      </DashboardMenuTitleWithLink>
      <DashboardMenuTitleWithLink
        href='/dashboard/positions'
        isSelected={router.pathname.includes('/dashboard/positions')}
      >
        채용 공고
      </DashboardMenuTitleWithLink>
      <DashboardMenuTitleWithLink
        href='/dashboard/startup'
        isSelected={router.pathname.includes('/dashboard/startup')}
      >
        기업 상세 페이지
      </DashboardMenuTitleWithLink>
    </Row>
  )
}

const BetaNotice = styled(GuideBalloon)`
  width: 218px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 50px;
  z-index: 10;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: ${(p) => p.theme.fontSize.small};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  @media ${(p) => p.theme.deviceSize.mobile} {
    display: none;
  }
  :after {
    top: calc(100% - 44px);
    transform: rotate(180deg);
  }
`
