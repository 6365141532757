import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; // vertically center
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; // horizontally center
`

export const ResponsiveLayout = styled.div`
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    width: 1060px;
    margin: 0 auto;
  }
`

export const ExtendResponsiveLayout = styled.div`
  padding: 0 20px;
  @media ${(p) => p.theme.deviceSize.extendDesktop} {
    padding: 0;
    width: 1280px;
    margin: 0 auto;
  }
`

export const ResponsiveDisplay = styled.div<{
  isDisableMobile?: boolean
  isDisableDesktop?: boolean
}>`
  @media ${(p) => p.theme.deviceSize.mobile} {
    ${(p) => p.isDisableMobile && 'display: none;'}
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    ${(p) => p.isDisableDesktop && 'display: none;'}
  }
`
