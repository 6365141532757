import React from 'react'
import Link from 'next/link'

export const MyLink: React.FCC<{
  href: string
  newTab?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}> = ({ href, children, newTab, onClick }) => (
  <Link href={href} onClick={onClick} target={newTab ? '_blank' : ''}>
    {children}
  </Link>
)

interface GoogleCalendarLinkProps {
  name: string
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  details: string // 설명
}

export const GoogleCalendarLink: React.FCC<GoogleCalendarLinkProps> = (
  props,
) => {
  const { name, startDate, startTime, endDate, endTime, details, children } =
    props

  // Construct the Google Calendar link
  const baseUrl = 'https://www.google.com/calendar/render'
  const params = new URLSearchParams({
    action: 'TEMPLATE',
    text: name,
    dates: `${startDate}T${startTime}/${endDate}T${endTime}`,
    ctz: 'Asia/Seoul',
    details,
  })
  const url = `${baseUrl}?${params.toString()}`

  return (
    <MyLink href={url} newTab>
      {children}
    </MyLink>
  )
}
