import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { BaseText } from 'components/common/text'

export const Hashtag: React.FC<{ data: string[] }> = ({ data }) => {
  return (
    <HashtagWrapper>
      {data.map((tag, index) => (
        <HashtagText key={index}>#{tag}</HashtagText>
      ))}
    </HashtagWrapper>
  )
}

const HashtagWrapper = styled(Row)`
  flex-wrap: wrap;
  gap: 10px;
`

const HashtagText = styled(BaseText)`
  padding: 0 10px;
  font-weight: ${(p) => p.theme.fontWeight.medium};
  line-height: 1.6;
  background-color: #e4f3eb;
  border-radius: 4px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
  }
`
