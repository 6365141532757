import React from 'react'
import styled from 'styled-components'
import { Column, ResponsiveLayout, Row } from 'components/common/layout'
import { MediumText, SemiBoldText } from 'components/common/text'
import Image from 'next/legacy/image'
import { BtnSize, BtnType, RoundButton } from 'components/common/button'
import { useStores } from 'stores/globals'
import { useRouter } from 'next/router'
import { mutate } from 'swr'
import toast from 'react-hot-toast'
import { Modal } from 'components/common/modal'
import { useUser } from 'apis/hooks'
import { postRequestAccountMeStatus } from 'apis/request'

const DeactivatedStatusContent: React.FC<{ onClose?: () => void }> = ({
  onClose,
}) => {
  const { loginStore } = useStores()
  const router = useRouter()
  const { data: user } = useUser()
  if (!user) return null
  return (
    <Content>
      <TitleText>
        {`${user.name}님, 휴면 계정 상태를\n해제하시겠어요?`}
      </TitleText>
      <Image
        src='/steve-light.svg'
        width={132}
        height={173}
        alt='steve light'
      />
      <SubText>
        {'휴면 계정 상태를 해제해야\n서비스를 이용할 수 있어요'}
      </SubText>
      <GrayText>
        {
          '스카우트 제안받기 / 채용 포지션 지원 /\n나에게 관심 있는 스타트업 알림 등'
        }
      </GrayText>
      <ButtonContainer>
        <RoundButton
          size={BtnSize.MEDIUM}
          style={{ width: 200 }}
          onClick={async () => {
            await postRequestAccountMeStatus({ isActive: true })
            await mutate('/accounts/me')
            toast.success(
              '휴면 계정 상태가 해제되었어요.\n프로필은 비공개 상태예요.',
              {
                duration: 6000,
              },
            )
            onClose?.()
          }}
        >
          휴면 해제하기
        </RoundButton>
        <RoundButton
          variant={BtnType.OUTLINE}
          size={BtnSize.MEDIUM}
          style={{ width: 110 }}
          onClick={async () => {
            loginStore.logout()
            router.replace('/')
          }}
        >
          유지하기
        </RoundButton>
      </ButtonContainer>
    </Content>
  )
}

export const DeactivatedStatusPage = () => {
  return (
    <ResponsiveLayout as={Column} style={{ height: '100vh' }}>
      <DeactivatedStatusContent />
    </ResponsiveLayout>
  )
}

export const DeactivatedStatusModal: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  return (
    <Modal width={560} onClose={onClose}>
      <Column style={{ padding: '48px 24px' }}>
        <DeactivatedStatusContent onClose={onClose} />
      </Column>
    </Modal>
  )
}

const Content = styled(Column)`
  align-items: center;
  gap: 32px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 24px;
  }
`

const TitleText = styled(SemiBoldText)`
  text-align: center;
  line-height: 1.4;
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h3};
    white-space: nowrap;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h4};
  }
`

const SubText = styled(MediumText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h5};
    white-space: nowrap;
  }
  line-height: 1.4;
`

const GrayText = styled(MediumText)`
  color: ${(p) => p.theme.color.black3};
  text-align: center;
  line-height: 1.4;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
  }
`

const ButtonContainer = styled(Row)`
  gap: 12px;
  width: 100%;
  justify-content: center;
`
