import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Chip, ChipWrapper } from 'components/common/chip'
import { Column, Row } from 'components/common/layout'
import { WithIdNameParentId } from 'types/common'
import { observer } from 'mobx-react'
import { useCategorySelector } from 'utils/hooks'

export const CategorySelector: React.FC<{
  data: WithIdNameParentId[]
  selectedData: WithIdNameParentId[]
  onChange: (p: WithIdNameParentId[]) => void
  isFilter?: boolean
}> = observer(({ selectedData, data, onChange, isFilter }) => {
  // 선택된 대분류 id
  const [selectedParentId, setSelectedParentId] = useState(0)
  const { parentData, childrenOfSelectedParent, isParentFullySelected } =
    useCategorySelector(data, selectedData, selectedParentId)

  const selectedParentIds = useMemo(
    () => selectedData.map((item) => item.parentId),
    [selectedData],
  )

  const selectedChildIds = useMemo(
    () => selectedData.map((item) => item.id),
    [selectedData],
  )

  // 전체 선택
  const selectAll = () => {
    let result = selectedData
    result = result.filter((item) => item.parentId !== selectedParentId)
    if (!isParentFullySelected) {
      result = [
        ...result,
        ...childrenOfSelectedParent.filter(
          (item) => item.parentId === selectedParentId,
        ),
      ]
    }
    onChange(result)
  }

  // 대분류 선택
  const toggleParent = (item: WithIdNameParentId) => {
    setSelectedParentId(item.id)
    if (isFilter && item.id === selectedParentId) {
      setSelectedParentId(0)
    }
  }

  // 포지션 선택
  const toggleChild = (item: WithIdNameParentId) => {
    let result = selectedData
    // 전체선택 상태에서 아이템 제거
    if (isFilter && isParentFullySelected) {
      result = result.filter((_item) => _item.id !== item.id)
      onChange(result)
      return
    }

    // 아이템 토글
    const isItemSelected = selectedChildIds.includes(item.id)
    if (isItemSelected) {
      result = result.filter((_item) => _item.id !== item.id)
    } else if (isFilter) {
      result = [...result, item]
    } else {
      result = [item]
    }
    onChange(result)
  }

  return (
    <Container>
      <ChipWrapper>
        {parentData.map((item) => (
          <Chip
            key={item.id}
            isSelected={
              selectedParentIds.includes(item.id) ||
              selectedParentId === item.id
            }
            onClick={() => toggleParent(item)}
          >
            {item.name}
          </Chip>
        ))}
      </ChipWrapper>
      {childrenOfSelectedParent.length > 0 && (
        <ChildrenContainer>
          <ChipWrapper>
            {isFilter && (
              <Chip
                isSelected={isParentFullySelected}
                onClick={selectAll}
                style={{ border: '1px solid' }}
              >
                전체
              </Chip>
            )}
            {childrenOfSelectedParent.map((item) => (
              <Chip
                key={item.id}
                isSelected={selectedChildIds.includes(item.id)}
                onClick={() => toggleChild(item)}
              >
                {item.name}
              </Chip>
            ))}
          </ChipWrapper>
        </ChildrenContainer>
      )}
    </Container>
  )
})

const Container = styled(Column)`
  gap: 16px;
`

const ChildrenContainer = styled(Row)`
  padding-top: 16px;
  border-top: 1px solid ${(p) => p.theme.color.line};
`
