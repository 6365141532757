import React from 'react'
import styled from 'styled-components'
import Image from 'next/legacy/image'
import { IdpCertificate } from 'types/common'

export const TalentBadgeIcon: React.FC<{
  size: number
  idpCertificate: IdpCertificate
  isActivated?: boolean
}> = ({ size, idpCertificate, isActivated = true }) => {
  return (
    <IconImageWrapper size={size} isActivated={isActivated}>
      <Image
        src={idpCertificate.imageUrl}
        alt={idpCertificate.name}
        layout='fill'
        objectFit='cover'
        priority
      />
    </IconImageWrapper>
  )
}

const IconImageWrapper = styled.div<{ size: number; isActivated: boolean }>`
  position: relative;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  border-radius: 16px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 1px 8px rgba(161, 201, 255, 0.24);
  overflow: hidden;
  @media ${(p) => p.theme.deviceSize.mobile} {
    border-radius: 8px;
  }
  ${(p) => !p.isActivated && 'opacity: 40%;'}
`
