import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import {
  BaseText,
  H1Text,
  H2Text,
  H3Text,
  H4Text,
  H5Text,
  SemiBoldText,
} from 'components/common/text'
import { Card } from 'components/common/card'
import { BtnType, Button, RoundButton } from 'components/common/button'

export const ButtonContainer = styled(Column)<{ isBottom?: boolean }>`
  @media ${(p) => p.theme.deviceSize.desktop} {
    gap: 12px;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 8px;
    ${(p) =>
      p.isBottom &&
      'position: fixed; bottom: 20px; z-index: 1; width: 100%; padding: 0 20px;'}
  }
`

export const CTAButton = styled(RoundButton)<{ isBottom?: boolean }>`
  width: 300px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    ${(p) => p.isBottom && 'border-radius: 8px; width: 100%;'}
  }
`

export const Center = styled(Column)`
  gap: 32px;
  align-items: center;
  text-align: center;
`

export const Content = styled(Row)`
  gap: 24px;
  height: 100%;
  @media ${(p) => p.theme.deviceSize.desktop} {
    max-height: calc(100vh - 120px);
    justify-content: space-between;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    flex-direction: column;
  }
`

export const RequestFormButtonContainer = styled(Row)`
  position: fixed;
  bottom: 32px;
  width: 100%;
  padding: 0 20px;
  justify-content: center;
  button {
    max-width: 500px;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    bottom: 20px;
  }
`

export const FloatingButton = styled(Button)`
  border-radius: 16px;
  box-shadow: ${(p) =>
    !p.variant || p.variant === BtnType.PRIMARY
      ? '4px 4px 20px 0px rgba(36, 213, 158, 0.5)'
      : '4px 4px 20px 0px rgba(0, 0, 0, 0.05)'};
`

export const RequestFormTop = styled(Column)`
  z-index: 3;
  position: fixed;
  top: 0;
  width: 100%;
  gap: 12px;
  background-color: ${(p) => p.theme.color.background2};
  box-shadow: 0px 20px 10px 0px ${(p) => p.theme.color.background2};
  @media ${(p) => p.theme.deviceSize.desktop} {
    gap: 24px;
  }
`

export const TopNavigator = styled(Row)`
  position: relative;
  justify-content: space-between;
`

export const ExitTextButton = styled(BaseText)`
  color: ${(p) => p.theme.color.gray2};
  cursor: pointer;
`

// TODO: 이 컴포넌트 정리
export const ScoutBackground = styled(Column)`
  min-height: calc(100vh - 60px);
  background-color: #fafafa;
`

export const MainText = styled(H1Text)`
  color: ${(p) => p.theme.color.black3};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  text-align: center;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-weight: ${(p) => p.theme.fontWeight.semiBold};
    font-size: ${(p) => p.theme.fontSize.h2};
  }
`

export const SubText = styled(H5Text)`
  color: ${(p) => p.theme.color.black3};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
`

export const HeaderText = styled(H3Text)`
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  white-space: pre-wrap;
  .essential {
    color: ${(p) => p.theme.color.primary};
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h4};
  }
`

export const Black3Text = styled(BaseText)`
  color: ${(p) => p.theme.color.black3};
`

export const QuestionText = styled(H2Text)`
  color: ${(p) => p.theme.color.black3};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  text-align: center;
  line-height: 1.4;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h4};
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    white-space: nowrap;
  }
`

export const QuestionSubText = styled(H5Text)`
  color: ${(p) => p.theme.color.black3};
  text-align: center;
  font-weight: ${(p) => p.theme.fontWeight.medium};
`

export const ExplainText = styled(H4Text)`
  font-weight: ${(p) => p.theme.fontWeight.medium};
  color: ${(p) => p.theme.color.black3};
`

export const ExplainSubText = styled(H5Text)`
  color: ${(p) => p.theme.color.black3};
`

export const FormBackground = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: ${(p) => p.theme.color.background2};
`

export const BlackHoverExplanation = styled(Row)`
  z-index: 3;
  position: absolute;
  top: -20px;
  display: none;
  padding: 4px 16px;
  justify-content: center;
  border-radius: 16px;
  background-color: ${(p) => p.theme.color.black1};
  color: #5efdc2;
  font-size: ${(p) => p.theme.fontSize.default};
`

export const ModalContent = styled(Card)`
  padding: 32px;
  gap: 24px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 24px 16px;
    gap: 16px;
  }
`

export const ModalMainText = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.h4};
  text-align: center;
  line-height: 1.4;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`

export const ModalSubText = styled(BaseText)`
  text-align: center;
  line-height: 1.4;
  color: ${(p) => p.theme.color.gray1};
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`
