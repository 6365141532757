import styled, { css } from 'styled-components'

const commonInput = css`
  width: 100%;
  font-size: ${(p) => p.theme.fontSize.default};
  font-weight: ${(p) => p.theme.fontWeight.regular};
  ::placeholder {
    color: ${(p) => p.theme.color.gray2};
  }
  :focus {
    border: 1px solid ${(p) => p.theme.color.primary4};
    outline: none;
  }
`

export const TextInput = styled.input`
  ${commonInput}
  padding: 12px 0;
  border: 0;
  border-bottom: 1px solid ${(p) => p.theme.color.line};
  background: none;
  :focus {
    border: 0;
    border-bottom: 1px solid ${(p) => p.theme.color.primary4};
  }
`

export const BoxInput = styled.input`
  ${commonInput}
  padding: 12px 16px;
  border: 1px solid ${(p) => p.theme.color.line};
  border-radius: 8px;
`

export const TextArea = styled.textarea<{
  height?: number
}>`
  ${commonInput};
  height: ${(p) => (p.height ? p.height + 'px' : '')};
  width: 100%;
  padding: 16px;
  resize: none;
  border: 1px solid ${(p) => p.theme.color.line};
  border-radius: 8px;
  white-space: pre-wrap;
  line-height: 1.6;
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    display: none; // Chrome, Safari and Opera
  }
`
