import React from 'react'
import { StartupDetail } from 'types/common'
import { StartupMap } from 'components/startup/startup-map'
import { EffortForDev } from 'components/startup/effort-for-dev'
import { SolvingProblem } from 'components/startup/solving-problem'
import { StartupStory } from 'components/startup/startup-story'
import { CompensationWelfare } from 'components/startup/compensation-welfare'
import { ReasonForInvesting } from 'components/startup/reason-for-investing'
import { Team } from 'components/startup/team'
import { StartupPresentation } from 'components/startup/startup-presentation'
import { Column } from 'components/common/layout'
import { StartupImages } from 'components/startup/startup-images'

export const StartupDetailInfo: React.FC<{
  data: StartupDetail
  width: number
}> = ({ data, width }) => {
  return (
    <Column style={{ gap: 72 }}>
      <EffortForDev name={data.name} effortForDev={data.effortForDev} />
      <SolvingProblem solvingProblem={data.solvingProblem} />
      <StartupImages images={data.images} width={width} />
      <StartupStory data={data} />
      <CompensationWelfare
        compensation={data.compensation}
        welfare={data.welfare}
      />
      <ReasonForInvesting
        name={data.name}
        reasonForInvesting={data.reasonForInvesting}
      />
      <Team culture={data.culture} members={data.members} />
      {data.pt && <StartupPresentation pt={data.pt} />}
      <StartupMap
        address={data.address}
        width={width}
        height={width * (9 / 16)}
      />
    </Column>
  )
}
