import React from 'react'
import styled from 'styled-components'
import { Column } from 'components/common/layout'
import { BaseText, H5Text } from 'components/common/text'

export const ProposalMessageGuideBox: React.FC<{
  reason: string
  example: string
}> = ({ reason, example }) => (
  <Box>
    <Content>
      <Title>왜 작성해야하나요?</Title>
      <Desc dangerouslySetInnerHTML={{ __html: reason }} />
    </Content>
    <Content>
      <Title>예시</Title>
      <Desc dangerouslySetInnerHTML={{ __html: example }} />
    </Content>
  </Box>
)

const Content = styled(Column)`
  gap: 8px;
`

const Title = styled(H5Text)`
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  color: ${(p) => p.theme.color.black3};
`

const Desc = styled(BaseText)`
  font-weight: ${(p) => p.theme.fontWeight.regular};
  color: ${(p) => p.theme.color.black3};
  line-height: 24px;
  span {
    font-weight: ${(p) => p.theme.fontWeight.bold};
  }
  ul {
    padding-inline-start: 20px;
  }
`

const Box = styled(Column)`
  z-index: 1000;
  width: 238px;
  position: fixed;
  right: 30px;
  bottom: 100px;
  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.color.background2};
  height: fit-content;
  @keyframes tipBoxFadeIn {
    0% {
      opacity: 0;
      bottom: 0px;
    }
    100% {
      opacity: 1;
      bottom: 100px;
    }
  }
  animation: tipBoxFadeIn 800ms 1 ease;
`
