import Image from 'next/legacy/image'
import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'

export const Bookmark: React.FC<{
  onClick?: (e: React.MouseEvent) => void
  isBookmarked?: Boolean
}> = ({ onClick, isBookmarked }) => {
  return (
    <BookmarkWrapper onClick={onClick}>
      <Image
        src={isBookmarked ? '/bookmark-primary.svg' : '/bookmark-empty.svg'}
        width={24}
        height={24}
        alt='bookmark'
      />
    </BookmarkWrapper>
  )
}

const BookmarkWrapper = styled(Row)`
  flex: none;
  padding: 6px;
  transition: 0.2s;
  border-radius: 100px;
  cursor: pointer;
  :hover {
    background: ${(p) => p.theme.color.gray3 + '70'};
  }
`
