import React from 'react'
import styled from 'styled-components'
import { Tag, TagType, TagTypeMapping } from 'components/common/tag'
import { InfoCircleIcon } from 'components/common/icon'
import { useIsDesktop } from 'utils/hooks'
import { BaseText } from 'components/common/text'

export const InformationBox: React.FCC<{
  variant?: TagType
  isNarrow?: boolean
}> = ({ children, variant, isNarrow }) => {
  const isDesktop = useIsDesktop()
  return (
    <Box variant={variant ? variant : TagType.BLUE}>
      <div style={{ flex: 'none' }}>
        <InfoCircleIcon
          color={
            variant
              ? TagTypeMapping[variant].color
              : TagTypeMapping[TagType.BLUE].color
          }
          size={isDesktop || !isNarrow ? 18 : 16}
        />
      </div>
      <DescText isNarrow={isNarrow}>{children}</DescText>
    </Box>
  )
}

const Box = styled(Tag)`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 16px;
  align-items: center;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 8px;
  }
`

const DescText = styled(BaseText)<{ isNarrow?: boolean }>`
  color: ${(p) => p.theme.color.black3};
  ${(p) =>
    p.isNarrow &&
    `
    font-size: ${p.theme.fontSize.small};
  `}
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
  }
`
