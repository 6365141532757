import EventEmitter from 'eventemitter3'
import { v4 as uuidv4 } from 'uuid'

const eventEmitter = new EventEmitter()

const genEmitter = <Args>(eventType: EventType) => {
  const listenerMap = new Map()
  return {
    emit: (args: Args) => {
      eventEmitter.emit(eventType, args)
    },
    on: (listener: (args: Args) => void) => {
      eventEmitter.on(eventType, listener)
      const listenerId = uuidv4()
      listenerMap.set(listenerId, listener)
      return listenerId
    },
    off: (listenerId: string) => {
      const listener = listenerMap.get(listenerId)
      listenerMap.delete(listenerId)
      eventEmitter.off(eventType, listener)
    },
  }
}

enum EventType {
  POSITION_IS_INTERESTED_CHANGED = 'POSITION_IS_INTERESTED_CHANGED',
  STARTUP_IS_INTERESTED_CHANGED = 'STARTUP_IS_INTERESTED_CHANGED',
}

interface PositionIsInterestedChangedArgs {
  positionId: number
  isInterested: boolean
}

interface StartupIsInterestedChangedArgs {
  startupId: number
  isInterested: boolean
}

export const emitter = {
  positionIsInterestedChanged: genEmitter<PositionIsInterestedChangedArgs>(
    EventType.POSITION_IS_INTERESTED_CHANGED,
  ),
  startupIsInterestedChanged: genEmitter<StartupIsInterestedChangedArgs>(
    EventType.STARTUP_IS_INTERESTED_CHANGED,
  ),
}
