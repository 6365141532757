import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import {
  BaseText,
  BoldText,
  MediumText,
  SemiBoldText,
  SmallText,
} from 'components/common/text'
import { Tag, TagType } from 'components/common/tag'
import { Card } from 'components/common/card'

export const ProfileDetailSectionSubText = styled(BaseText)`
  color: ${(p) => p.theme.color.gray2};
  flex: none;
  line-height: 24px;
`

export const ProfileDetailConditionWrapper = styled(Row)`
  gap: 16px;
  align-items: flex-start;
`

export const ProfileDetailContainer = styled(Column)`
  gap: 56px;
  position: relative;
  .highlighted {
    background-color: ${(p) => p.theme.color.highlight};
  }
`

export const ProfileDetailSection = styled(Column)`
  gap: 10px;
`

export const ProfileDetailSectionTitle = styled(BaseText)`
  color: #777a88;
`

export const ProfileDetailContentLayout = styled(Column)`
  gap: 24px;
`

export const ProfileDetailCoverLetterBox = styled(Column)`
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${(p) => p.theme.color.background};
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 16px;
  }
`

export const ProfileDetailCoverLetterSectionTitle = styled(SemiBoldText)`
  color: ${(p) => p.theme.color.gray1};
`

export const ProfileDetailSummaryCard = styled(Card)`
  padding: 16px;
  background-color: ${(p) => p.theme.color.background};
`

export const ProfileDetailSummaryList = styled.ol`
  padding-inline-start: 24px;
  line-height: 1.8;
  font-size: ${(p) => p.theme.fontSize.default};
  li {
    font-weight: ${(p) => p.theme.fontWeight.semiBold};
    margin-top: 12px;
  }
`

export const ProfileDetailUserInfoCardWrapper = styled(Column)`
  gap: 32px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 24px;
  }
`

export const ProfileDetailContactText = styled.a`
  text-decoration: underline;
  font-size: ${(p) => p.theme.fontSize.default};
`

export const ProfileDetailTextWrapper = styled(Row)`
  margin-left: 8px;
  padding-left: 16px;
  border-left: 2px solid ${(p) => p.theme.color.gray3};
`

export const ProfileDetailPlaceholderCard = styled(Card)`
  padding: 16px 24px;
  background-color: ${(p) => p.theme.color.background};
  gap: 8px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const ProfileDetailPlaceholderText = styled(MediumText)`
  color: #7e7e7e;
`

export const ProfileDetailTextButton = styled(MediumText)`
  color: ${(p) => p.theme.color.primary};
  text-decoration: underline;
  @media ${(p) => p.theme.deviceSize.mobile} {
    text-align: center;
  }
`

export const ProfileDetailTextButtonWrapper = styled(Row)`
  justify-content: flex-end;
  @media ${(p) => p.theme.deviceSize.mobile} {
    justify-content: center;
  }
`

export const ProfileDetailInformText = styled(SmallText)`
  color: ${(p) => p.theme.color.primary7};
  line-height: 1.4;
`

export const ProfileDetailUnpublishedBanner = styled(Tag).attrs({
  variant: TagType.RED,
})`
  span {
    white-space: nowrap;
  }
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  top: 67px;
  width: 100%;
  position: absolute;
`

export const ProfileDetailUserContainer = styled(Row)`
  gap: 16px;
  align-items: flex-start;
`

export const ProfileDetailUserInfoWrapper = styled(Row)<{ isNarrow?: boolean }>`
  gap: 32px;
  align-items: flex-start;
  justify-content: space-between;
  ${(p) => p.isNarrow && `flex-direction: column;`}
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 16px;
    flex-direction: column;
  }
`

export const ProfileDetailUserTextContainer = styled(Column)`
  flex: 1;
  gap: 4px;
`

export const ProfileDetailFlexWrapRow = styled(Row)`
  gap: 4px;
  flex-wrap: wrap;
`

export const ProfileDetailUserNameText = styled(BoldText)`
  font-size: 20px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`

export const ProfileDetailExperienceText = styled(ProfileDetailUserNameText)`
  font-weight: ${(p) => p.theme.fontWeight.medium};
`

export const ProfileDetailPositionText = styled(MediumText)`
  line-height: 20px;
  font-size: ${(p) => p.theme.fontSize.desc};
  color: ${(p) => p.theme.color.gray2};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
`

export const ProfileDetailCommentCard = styled(Card)`
  gap: 24px;
  padding: 16px;
  flex-direction: row;
  background-color: ${(p) => p.theme.color.background};
`

export const ProfileDetailNameText = styled(BaseText)`
  font-size: 12px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 10px;
  }
`

export const ProfileDetailCommentText = styled(BaseText)<{
  lineClamp?: number
}>`
  line-height: 24px;
  position: relative;
  display: -webkit-box;
  word-wrap: break-word;
  ${(p) =>
    p.lineClamp &&
    `-webkit-box-orient: vertical;
    -webkit-line-clamp: ${p.lineClamp};
    overflow: hidden;
    text-overflow: ellipsis;`}
  @media ${(p) => p.theme.deviceSize.mobile} {
    line-height: 22px;
    font-size: ${(p) => p.theme.fontSize.small};
  }
`

export const ProfileDetailLanguageContainer = styled(Column)<{
  isNarrow?: boolean
}>`
  gap: 8px;
`

export const ProfileDetailLanguageNameText = styled(MediumText)<{
  isNarrow?: boolean
}>`
  ${(p) => (p.isNarrow ? `padding-right: 8px;` : `width: 85px;`)}
  line-height: 29px;
  flex: none;
`

export const ProfileDetailDescriptionText = styled(BaseText)`
  color: ${(p) => p.theme.color.black3};
  line-height: 1.6;
`
