import React from 'react'
import { getWorkLevelExperienceFormat } from 'utils/displays'
import {
  ActivelySeekerBadge,
  CommonCurationBadge,
  IsWorkingBadge,
  JobInterestLevelBadge,
} from 'components/scouts/common/user-base-info-badge'
import { Avatar } from 'components/common/avatar'
import {
  CareerHistory,
  DashboardScoutRequestOneQueryParams,
  WithIdName,
} from 'types/common'
import { useBaseInfo } from 'apis/hooks'
import { CertificateIconSlider } from 'components/certificate/common/certificate-icon-slider'
import { BirthYearTag } from 'components/scouts/common/user-info-tag'
import { DEFAULT_MAX_EXPERIENCE, DEFAULT_MIN_EXPERIENCE } from 'infra/constants'
import {
  ProfileDetailExperienceText,
  ProfileDetailFlexWrapRow,
  ProfileDetailPositionText,
  ProfileDetailUserContainer,
  ProfileDetailUserInfoWrapper,
  ProfileDetailUserNameText,
  ProfileDetailUserTextContainer,
} from 'components/scouts/profile-detail/styles'

interface UserBaseInfoProps {
  id: number
  image: string | File
  name: string
  positions: WithIdName[]
  experience: number
  workLevel: WithIdName
  jobInterestLevel: number | null
  isActivelySeeker: boolean
  isCommonCurated: boolean
  idpCertificates: number[]
  careers: CareerHistory[]
  birthYear: string | null
}

export const UserBaseInfo: React.FC<{
  data: UserBaseInfoProps
  isNarrow?: boolean
  params?: DashboardScoutRequestOneQueryParams
}> = ({ data, isNarrow, params }) => {
  const { data: baseInfo } = useBaseInfo()
  const workLevelExperience = getWorkLevelExperienceFormat(
    data.workLevel.id,
    data.experience,
  )
  const isWorking = data.careers.find((item) => item.isWorking)
  const isWorkLevelExperienceHighlighted = params
    ? params.minExperience === DEFAULT_MIN_EXPERIENCE &&
      params.maxExperience === DEFAULT_MAX_EXPERIENCE
      ? params.workLevels.length > 0
      : data.experience >= params.minExperience &&
        data.experience <= params.maxExperience
    : false

  return (
    <ProfileDetailUserInfoWrapper isNarrow={isNarrow}>
      <ProfileDetailUserContainer>
        <Avatar
          src={
            data.image
              ? typeof data.image === 'string'
                ? data.image
                : URL.createObjectURL(data.image)
              : ''
          }
        />
        <ProfileDetailUserTextContainer>
          <ProfileDetailFlexWrapRow>
            <ProfileDetailUserNameText>{data.name}</ProfileDetailUserNameText>
            <ProfileDetailExperienceText
              className={isWorkLevelExperienceHighlighted ? 'highlighted' : ''}
            >
              {workLevelExperience}
            </ProfileDetailExperienceText>
            {data.birthYear && <BirthYearTag birthYear={data.birthYear} />}
            <ProfileDetailFlexWrapRow>
              <JobInterestLevelBadge jobInterestLevel={data.jobInterestLevel} />
              {data.isActivelySeeker && <ActivelySeekerBadge />}
              {isWorking && <IsWorkingBadge />}
              {data.isCommonCurated && <CommonCurationBadge />}
            </ProfileDetailFlexWrapRow>
          </ProfileDetailFlexWrapRow>
          <ProfileDetailPositionText
            dangerouslySetInnerHTML={{
              __html: data.positions.map((item) => item.name).join(' ‧ '),
            }}
          />
        </ProfileDetailUserTextContainer>
      </ProfileDetailUserContainer>
      {data.idpCertificates.length > 0 && (
        <CertificateIconSlider
          data={
            baseInfo?.idpCertificates.filter((idpCertificate) =>
              data.idpCertificates.includes(idpCertificate.id),
            ) || []
          }
          params={params}
          requestId={data.id}
          isFullWidth={isNarrow}
        />
      )}
    </ProfileDetailUserInfoWrapper>
  )
}
