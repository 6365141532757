import React from 'react'
import {
  SectionDesc,
  SectionPaper,
  SubSectionTitle,
  SectionTitle,
  SubSectionWrapper,
} from 'components/startup/startup-styles'
import { Column } from 'components/common/layout'
import { StartupDetail } from 'types/common'
import { checkEditorDefaultValue, isEndWithConsonant } from 'utils/validations'
import { Video } from 'components/common/video'
import { TextViewer } from 'components/common/text-editor'
import { analytics } from 'infra/analytics'

export const StartupStory: React.FC<{ data: StartupDetail }> = ({ data }) => {
  return (
    <Column id='startupStory'>
      <SectionTitle>창업 스토리</SectionTitle>
      <SectionDesc>
        {data.name}의 창업 히스토리와 비전을 소개합니다.
      </SectionDesc>
      <SectionPaper>
        {data.howToStartup && !checkEditorDefaultValue(data.howToStartup) && (
          <SubSectionWrapper>
            <SubSectionTitle>대표님 어떻게 창업하셨나요?</SubSectionTitle>
            <TextViewer value={data.howToStartup} />
          </SubSectionWrapper>
        )}
        {data.whatKindCompany &&
          !checkEditorDefaultValue(data.whatKindCompany) && (
            <SubSectionWrapper>
              <SubSectionTitle>
                {data.name}
                {isEndWithConsonant(data.name) ? '은' : '는'} 어떤 회사인가요?
              </SubSectionTitle>
              <TextViewer value={data.whatKindCompany} />
            </SubSectionWrapper>
          )}
        {data.pitch && (
          <Video
            url={data.pitch}
            onVideoClick={() =>
              analytics.track('click_st_pitch', {
                startupId: data.id,
                startupName: data.name,
              })
            }
          />
        )}
        {data.curOutcome && !checkEditorDefaultValue(data.curOutcome) && (
          <SubSectionWrapper>
            <SubSectionTitle>현재까지 성과</SubSectionTitle>
            <TextViewer value={data.curOutcome} />
          </SubSectionWrapper>
        )}
        {data.vision && !checkEditorDefaultValue(data.vision) && (
          <SubSectionWrapper>
            <SubSectionTitle>
              대표님이 그리시는 미래, 비전이 궁금해요
            </SubSectionTitle>
            <TextViewer value={data.vision} />
          </SubSectionWrapper>
        )}
        {data.lookForDev && !checkEditorDefaultValue(data.lookForDev) && (
          <SubSectionWrapper>
            <SubSectionTitle>어떤 분을 찾고 계신가요?</SubSectionTitle>
            <TextViewer value={data.lookForDev} />
          </SubSectionWrapper>
        )}
      </SectionPaper>
    </Column>
  )
}
