import React, { useEffect, useState } from 'react'
import { useBaseInfo } from 'apis/hooks'
import { observer } from 'mobx-react'
import { TechStackWithPositionTypes, WithIdName } from 'types/common'
import { SearchInput } from 'components/common/search-input'
import styled from 'styled-components'
import { Column } from 'components/common/layout'
import { getFitTechStacks } from 'utils/formatters'
import { Chip, ChipSize, ChipWrapper } from 'components/common/chip'
import { SmallText } from 'components/common/text'

export const TechStackSelector: React.FC<{
  selectedPositionTypes: number[]
  selected: number[]
  onClick: (item: WithIdName) => void
  isFilter?: boolean
  isSmall?: boolean
  isFullHight?: boolean
}> = observer(
  ({
    selectedPositionTypes,
    selected,
    onClick,
    isFilter,
    isSmall,
    isFullHight,
  }) => {
    const { data: baseInfo } = useBaseInfo()
    const [input, setInput] = useState('')
    const [fitTechStacks, setFitTechStacks] = useState<
      TechStackWithPositionTypes[]
    >([])
    useEffect(() => {
      const fitSorted = getFitTechStacks(
        selectedPositionTypes,
        baseInfo?.techStacks || [],
      )
      setFitTechStacks(
        isFilter
          ? fitSorted.sort((a, b) => {
              if (selected.includes(a.id)) return -1
              if (selected.includes(b.id)) return 1
              return 0
            })
          : fitSorted,
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPositionTypes])

    if (!baseInfo) return null

    const data = input
      ? baseInfo.techStacks.filter((item) =>
          item.name.toLowerCase().includes(input.toLowerCase()),
        )
      : fitTechStacks

    return (
      <Wrapper>
        <SearchInput
          value={input}
          placeholder='찾으시는 스킬을 입력해주세요'
          onChange={(e) => setInput(e.target.value)}
        />
        {data.length > 0 ? (
          <ScrollChipContainer isFullHight={isFullHight}>
            {data.map((item) => (
              <Chip
                key={item.id}
                size={isSmall ? ChipSize.SMALL : undefined}
                isSelected={selected.includes(item.id)}
                onClick={() => onClick(item)}
              >
                {item.name}
              </Chip>
            ))}
          </ScrollChipContainer>
        ) : (
          <RequestSkillWrapper>
            <SmallText>찾으시는 스킬이 없으신가요?</SmallText>
            <LinkText
              onClick={() =>
                (window.location.href = 'https://groupbycs.channel.io/home')
              }
            >
              스킬 등록 요청하기
            </LinkText>
          </RequestSkillWrapper>
        )}
      </Wrapper>
    )
  },
)

const Wrapper = styled(Column)`
  overflow: hidden;
  gap: 16px;
`

const ScrollChipContainer = styled(ChipWrapper)<{ isFullHight?: boolean }>`
  overflow-y: scroll;
  ${(p) => !p.isFullHight && `max-height: 164px;`}
`

const RequestSkillWrapper = styled(Column)`
  gap: 8px;
  align-items: center;
  span {
    color: ${(p) => p.theme.color.secondary};
  }
`

const LinkText = styled(SmallText)`
  font-weight: ${(p) => p.theme.fontWeight.bold};
  text-decoration: underline;
  cursor: pointer;
`
