import React, { createContext } from 'react'
import { LoginStore } from 'stores/login'
import { PositionFilterStore } from 'stores/position-filter'
import { StartupModalStore } from 'stores/startup-modal'
import { PositionModalStore } from 'stores/position-modal'
import { PositionListStore } from 'stores/position-list'
import { ConditionFormStore } from 'stores/scouts/condition-form'
import { ScoutProfileFormStore } from 'stores/scouts/profile-form'
import { PositionApplyModalStore } from 'stores/position-apply-modal'
import { AutoProposalMessageStore } from 'stores/auto-proposal-message'

// STEP1: 전역 인스턴스 생성
const stores = {
  loginStore: new LoginStore(),
  positionFilterStore: new PositionFilterStore(),
  positionListStore: new PositionListStore(),
  startupModalStore: new StartupModalStore(),
  positionModalStore: new PositionModalStore(),
  applyModalStore: new PositionApplyModalStore(),
  scoutConditionFormStore: new ConditionFormStore(),
  scoutProfileFormStore: new ScoutProfileFormStore(),
  autoProposalMessageStore: new AutoProposalMessageStore(),
}

export const StoresContext = createContext<typeof stores>(stores)

// 각 페이지마다 login 컴포넌트 선언 없이 상태값 변경만으로 모달 생성 가능
export const StoresProvider: React.FCC = (props) => {
  return (
    <StoresContext.Provider value={stores}>
      {props.children}
    </StoresContext.Provider>
  )
}

// STEP2: import 해서 사용
// [예시] const { userStore } = useStores()
// store 사용할 컴포넌트를 observer 로 감싸는 것 잊지 말기!
export const useStores = () => {
  return React.useContext(StoresContext)
}
