import React from 'react'
import { Modal } from 'components/common/modal'
import { observer } from 'mobx-react'
import {
  ButtonContainer,
  CheckList,
  CheckListTitle,
  CheckListWrapper,
  MainButton,
  MainContent,
  ModalContent,
  ModalTitle,
  SubButton,
} from 'containers/positions/check-modal'
import { PositionApplicationStore } from 'stores/position-application'
import { getPositionApplicationPreviewSkipStorage } from 'utils/storages'
import { PositionApplicationNoticeText } from 'containers/positions/application/styles'
import { Column } from 'components/common/layout'
import { useStores } from 'stores/globals'
import { usePositionDetail } from 'apis/hooks'
import {
  hasFitApplicationFulfillment,
  hasFitApplicationFulfillmentDescription,
} from 'utils/validations'

export const ApplicationMessageCheckModal: React.FC<{
  store: PositionApplicationStore
}> = observer(({ store }) => {
  const { positionListStore } = useStores()
  const { data: position } = usePositionDetail(store.positionDetail?.id)
  const isSkipPreview = getPositionApplicationPreviewSkipStorage()
  const checkItemList = [
    { name: '합류하고 싶은 이유', isChecked: !!store.joinReason },
    {
      name: `적합하다 생각하는 이유${
        !hasFitApplicationFulfillment(store.fulfillments)
          ? ''
          : !hasFitApplicationFulfillmentDescription(store.fulfillments)
          ? ' (서술)'
          : ''
      }`,
      isChecked:
        hasFitApplicationFulfillment(store.fulfillments) &&
        hasFitApplicationFulfillmentDescription(store.fulfillments),
    },
  ]
  return (
    <Modal width={400} disableCloseBtn>
      <ModalContent>
        <MainContent>
          <ModalTitle>작성하지 않은 지원 메시지가 있어요</ModalTitle>
          <CheckListWrapper>
            <CheckListTitle>지원 메시지 항목</CheckListTitle>
            <CheckList data={checkItemList} />
          </CheckListWrapper>
        </MainContent>
        <Column style={{ gap: 12 }}>
          <PositionApplicationNoticeText>
            성의 있는 메시지로 서류 합격 가능성을 최대 <span>6배</span>{' '}
            높여보세요!
          </PositionApplicationNoticeText>
          <ButtonContainer>
            <SubButton
              onClick={async () => {
                try {
                  store.setIsOpenMessageCheckModal(false)
                  if (!isSkipPreview) return store.setIsOpenPreviewModal(true)
                  if (!confirm(store.applicationAlert)) return
                  await store.apply(!!position?.userApplication.isPossibleApply)
                  positionListStore.mutatePositionList()
                  store.setIsOpenDoneModal(true)
                } catch (e) {
                  alert(e)
                }
              }}
            >
              이대로 지원하기
            </SubButton>
            <MainButton
              onClick={() => {
                store.setCurStep(!store.joinReason ? 1 : 2)
                store.setIsOpenMessageCheckModal(false)
              }}
            >
              지원 메시지 작성하기
            </MainButton>
          </ButtonContainer>
        </Column>
      </ModalContent>
    </Modal>
  )
})
