import styled from 'styled-components'
import React, { useEffect } from 'react'
import Image from 'next/legacy/image'
import { Row } from 'components/common/layout'
import { Column } from 'components/common/layout'
import { MyLink } from 'components/common/link'
import { useRouter } from 'next/router'
import { SmallText } from 'components/common/text'
import { FiX } from 'react-icons/fi'

interface ModalProps {
  width: number
  onClose: () => void
  closeBtn?: boolean
  disableBackgroundClick?: boolean
  disableScrollBar?: boolean
}

interface FunctionModalProps extends ModalProps {
  pageLink?: string
  newTab?: boolean
  clickPageLink?: () => void // 페이지로 열기 링크 이동 전 클릭
  functionComponent?: React.ReactNode
}

export const Modal: React.FCC<ModalProps> = ({
  children,
  width,
  onClose,
  closeBtn,
  disableBackgroundClick,
}) => {
  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && !disableBackgroundClick) onClose?.()
    })
    const body = document.body
    body.style.overflowY = 'hidden'
    return () => {
      window.removeEventListener('keydown', (e) => {
        if (e.key === 'Escape' && !disableBackgroundClick) onClose?.()
      })
      body.style.overflow = ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalBackground
      onClick={() => {
        if (!disableBackgroundClick) onClose()
      }}
    >
      <ModalContent width={width} onClick={(e) => e.stopPropagation()}>
        {closeBtn && (
          <Top>
            <FiX size={24} onClick={onClose} strokeWidth={1} />
          </Top>
        )}
        {children}
      </ModalContent>
    </ModalBackground>
  )
}

export const FunctionModal: React.FCC<FunctionModalProps> = ({
  children,
  width,
  onClose,
  pageLink,
  clickPageLink,
  newTab,
  disableScrollBar = false,
  functionComponent,
}) => {
  const router = useRouter()

  useEffect(() => {
    const body = document.body
    body.style.overflowY = 'hidden'
    return () => {
      body.style.overflow = ''
    }
  }, [])

  useEffect(() => {
    router.events.on('routeChangeStart', onClose)
    return () => {
      router.events.off('routeChangeStart', onClose)
    }
  }, [router.events, onClose])

  return (
    <ModalBackground onClick={onClose}>
      <PageModalContent
        id='modal-content'
        width={width}
        onClick={(e) => e.stopPropagation()}
        disableScrollBar={disableScrollBar}
      >
        <PageFunctionBox>
          {functionComponent || <div />}
          <Row style={{ gap: 16 }}>
            {pageLink && (
              <FunctionWrapper>
                <MyLink href={pageLink} newTab={newTab} onClick={clickPageLink}>
                  <Row style={{ gap: 2 }}>
                    <Image
                      alt='external-link-gray2'
                      src='/external-link-gray2.svg'
                      width={20}
                      height={20}
                    />
                    <SubText>
                      {newTab ? '새탭으로 열기' : '페이지로 열기'}
                    </SubText>
                  </Row>
                </MyLink>
              </FunctionWrapper>
            )}
            <FunctionWrapper>
              <SubText onClick={onClose}>닫기</SubText>
            </FunctionWrapper>
          </Row>
        </PageFunctionBox>
        {children}
      </PageModalContent>
    </ModalBackground>
  )
}

const SubText = styled(SmallText)`
  color: ${(p) => p.theme.color.gray2};
`

const PageFunctionBox = styled(Row)`
  padding: 10px 20px;
  position: sticky;
  justify-content: space-between;
  top: 0;
  z-index: 2;
  background-color: ${(p) => p.theme.color.white1};
  border-bottom: 1px solid ${(p) => p.theme.color.line};
`

const FunctionWrapper = styled(Row)`
  color: ${(p) => p.theme.color.gray2};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

export const ModalBackground = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  @media ${(p) => p.theme.deviceSize.desktop} {
    display: flex;
  }
`

const ModalContent = styled(Column)<{
  width: number
}>`
  position: relative;
  justify-content: start;
  overflow: hidden scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  background-color: ${(p) => p.theme.color.white1};
  @media ${(p) => p.theme.deviceSize.mobile} {
    /* TODO(JUNSEULGI): 모바일로 모달 열릴 때 컨텐츠가 위에 달라붙는 문제 */
    height: 100%;
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    width: ${(p) => p.width}px;
    max-height: calc(100vh - 40px);
    margin: auto;
    border-radius: 16px;
  }
`

const PageModalContent = styled(ModalContent)<{ disableScrollBar: boolean }>`
  justify-content: flex-start;
  @media ${(p) => p.theme.deviceSize.desktop} {
    margin: 20px auto;
  }
  ${(p) =>
    p.disableScrollBar &&
    `  
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
    display: none;
    }
    `}
`

const Top = styled(Row)`
  position: sticky;
  top: 0;
  padding: 16px 16px 8px;
  justify-content: flex-end;
  svg {
    cursor: pointer;
  }
`
