import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'components/common/modal'
import { Column, Row } from 'components/common/layout'
import { DescText, SemiBoldText } from 'components/common/text'
import { FiChevronRight } from 'react-icons/fi'
import { useIsDesktop } from 'utils/hooks'
import { useScoutMyRequest } from 'apis/hooks'
import { useRouter } from 'next/router'
import { getProfileFillNeedModalStorage } from 'utils/storages'
import { HIDE_PROFILE_FILL_NEED_MODAL_KEY } from 'infra/constants'
import { useIsClient } from 'usehooks-ts'
import { getProfileCompleteStatus } from 'utils/handlers'
import { mutate } from 'swr'

export const ProfileFillNeedModal: React.FC = () => {
  const isClient = useIsClient()
  if (!isClient) return null
  return <ProfileFillNeedContent />
}

const ProfileFillNeedContent: React.FC = () => {
  const { data: myRequest } = useScoutMyRequest()
  const router = useRouter()
  const isDesktop = useIsDesktop()
  const [isHidden, setIsHidden] = useState(getProfileFillNeedModalStorage())
  const {
    isCompletedEducation,
    isCompletedCareer,
    isCompletedResume,
    isCompletedIntroduction,
    isCompletedExpectation,
    isCompletedLanguage,
    isCompletedJobSearchingReason,
  } = getProfileCompleteStatus(myRequest)
  const isExperienced = myRequest?.workLevel === 3
  const isCompletedAll =
    isCompletedEducation &&
    isCompletedCareer &&
    isCompletedResume &&
    isCompletedIntroduction &&
    isCompletedExpectation &&
    isCompletedLanguage &&
    (isExperienced ? isCompletedJobSearchingReason : true)

  if (
    !myRequest ||
    isHidden ||
    (myRequest.isPublished && isCompletedAll) ||
    !myRequest.hasResolvedInfoFill ||
    (myRequest.infoFillResolvedAt &&
      new Date(myRequest.updatedByUserAt).getTime() >
        new Date(myRequest.infoFillResolvedAt).getTime())
  )
    return null

  const isInfoFillRequired =
    !!myRequest?.educations.length &&
    (!!myRequest.careers.length || !!myRequest.otherActivities.length)
  const mainTitle = isInfoFillRequired
    ? '아직 작성하지 않은 정보가 있어요!'
    : '아직 스카우트 제안을 받을 수 없어요!'
  const subTitle = isInfoFillRequired
    ? `입력되지 않은 정보가 있어 프로필을 완성하지 못했어요\n프로필을 완성하고 <b>더욱 매력적인</b> 스카우트 제안을 받아보세요`
    : `<b>입력되지 않은 필수 정보가</b> 있어 프로필을 공개하지 못했어요\n필수 정보를 추가하고 스카우트 제안을 받아보세요`
  return (
    <Modal width={460} disableCloseBtn disableBackgroundClick>
      <Content>
        <MainText>{mainTitle}</MainText>
        <SubText dangerouslySetInnerHTML={{ __html: subTitle }} />
      </Content>
      <Row>
        <Button
          id='subBtn'
          onClick={() => {
            localStorage.setItem(HIDE_PROFILE_FILL_NEED_MODAL_KEY, 'true')
            setIsHidden(true)
            mutate('/scouts/requests/me/request-info-fill/drafts')
          }}
        >
          나중에 하기
        </Button>
        <Button
          onClick={() => {
            localStorage.setItem(HIDE_PROFILE_FILL_NEED_MODAL_KEY, 'true')
            setIsHidden(true)
            router.push('/scouts/my-profile?resolvedInfoFill=true')
            mutate('/scouts/requests/me/request-info-fill/drafts')
          }}
        >
          프로필 완성하러 가기 <FiChevronRight size={isDesktop ? 24 : 18} />
        </Button>
      </Row>
    </Modal>
  )
}

const Content = styled(Column)`
  gap: 24px;
  text-align: center;
  padding: 60px 0 40px;
`

const Button = styled(Row)`
  gap: 4px;
  width: 100%;
  padding: 32px 0;
  justify-content: center;
  color: ${(p) => p.theme.color.white1};
  font-size: ${(p) => p.theme.fontSize.desc};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  background-color: ${(p) => p.theme.color.secondary};
  border-top: 1px solid ${(p) => p.theme.color.secondary};
  cursor: pointer;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
  }
  &#subBtn {
    color: ${(p) => p.theme.color.gray2};
    font-weight: ${(p) => p.theme.fontWeight.medium};
    background-color: ${(p) => p.theme.color.white1};
    border-top: 1px solid ${(p) => p.theme.color.line};
  }
`

const MainText = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.h3};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`

const SubText = styled(DescText)`
  font-weight: ${(p) => p.theme.fontWeight.regular};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
    line-height: 18px;
  }
`
