import styled from 'styled-components'
import { Column } from 'components/common/layout'

export const Card = styled(Column)`
  border-radius: 16px;
  background-color: ${(p) => p.theme.color.white1};
`

export const ShadowCard = styled(Card)`
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.03);
`

export const OutlinedCard = styled(Column)`
  border: 1px solid ${(p) => p.theme.color.line};
  border-radius: 8px;
`
