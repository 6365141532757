import React, { useState } from 'react'
import styled from 'styled-components'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { Modal } from 'components/common/modal'
import { Column, Row } from 'components/common/layout'
import { Tag, TagType } from 'components/common/tag'
import {
  BaseText,
  BoldText,
  H4Text,
  MediumText,
  SemiBoldText,
  SmallText,
} from 'components/common/text'
import { TbClock } from 'react-icons/tb'
import { WithDashboardHeader } from 'components/misc/with-header-footer'
import { MyLink } from 'components/common/link'
import { analytics } from 'infra/analytics'
import { useIsDesktopCSR } from 'utils/hooks'
import { useRouter } from 'next/router'
import { InformationBox } from 'components/misc/information-box'

export const LockAccountProcedurePage: React.FC<{
  hasToBeSigned?: boolean
  hasToBePublished?: boolean
}> = (props) => (
  <WithDashboardHeader>
    <PageLayout>
      <LockAccountProcedure {...props} />
    </PageLayout>
  </WithDashboardHeader>
)

export const LockAccountProcedureModal: React.FC<{
  onClose: () => void
  hasToBeSigned?: boolean
  hasToBePublished?: boolean
}> = ({ onClose, hasToBeSigned, hasToBePublished }) => (
  <Modal width={877} onClose={onClose}>
    <LockAccountProcedure
      hasToBeSigned={hasToBeSigned}
      hasToBePublished={hasToBePublished}
    />
  </Modal>
)

const LockAccountProcedure: React.FC<{
  hasToBeSigned?: boolean
  hasToBePublished?: boolean
}> = ({ hasToBeSigned, hasToBePublished }) => {
  const router = useRouter()
  const isDesktop = useIsDesktopCSR()
  const [isOpen, setIsOpen] = useState(false)
  const BenefitLink = (
    <BenefitLinkWrapper>
      <MyLink newTab href='https://groupbycs.channel.io/support-bots/47107'>
        <LinkText>할인 혜택 알아보기</LinkText>
      </MyLink>
    </BenefitLinkWrapper>
  )

  return (
    <>
      <BoxContainer>
        <ContentWrapper>
          <Column style={{ gap: 8, textAlign: 'center' }}>
            <MainText>간단하게 인재 채용을 시작해보세요.</MainText>
            <SubText>
              {`${
                hasToBePublished
                  ? '기업 상세 페이지 공개'
                  : '서비스 사용 동의서 작성'
              } 후,\n그룹바이의 서비스를 이용하실 수 있어요`}
            </SubText>
          </Column>
          <Column style={{ gap: 16 }}>
            <PanelCard
              onClick={() => {
                if (hasToBePublished) {
                  analytics.track('click_dsu_lock_startup_info_publish_card')
                  router.push('/dashboard/startup')
                }
                if (hasToBeSigned) {
                  analytics.track('click_dsu_lock_service_confirm_card')
                  setIsOpen(true)
                }
              }}
            >
              <CardTitleWrapper>
                <CardMainText>
                  {hasToBePublished
                    ? '기업 상세 페이지 작성하기'
                    : '서비스 사용 동의서 작성하기'}
                </CardMainText>
                <Row>
                  <Tag variant={TagType.BLUE}>
                    <Row style={{ gap: 4 }}>
                      <TbClock width={10} />
                      {hasToBePublished
                        ? '5분 ~ 20분 소요'
                        : '10분 ~ 1시간 소요'}
                    </Row>
                  </Tag>
                </Row>
              </CardTitleWrapper>
              <CardSubText>
                {hasToBePublished
                  ? '필수 정보만 입력하면 빠르게 공개할 수 있어요.'
                  : `계약서 내용을 확인하고 승인하기까지 10분 ~ 1시간이 소요될 수 있어요.\n1시간이 지나도 승인이 되지 않으면, 채널톡으로 문의 부탁드립니다.`}
              </CardSubText>
            </PanelCard>
            <InformationBox>
              2024년부터 계약서 및 이용약관이 업데이트되었습니다. 신규 약관에
              서명하시면 다시 서비스 이용이 가능합니다.
            </InformationBox>
          </Column>
        </ContentWrapper>
      </BoxContainer>
      {isOpen && (
        <Modal width={580} onClose={() => setIsOpen((prev) => !prev)}>
          <Column style={{ padding: 32, gap: 16 }}>
            <Column style={{ gap: 24 }}>
              <SignModalHeaderWrapper>
                <SignModalMainText>{`그룹바이 채용 서비스 사용 동의서\n서명을 진행해주세요 ✍🏻`}</SignModalMainText>
                {isDesktop && BenefitLink}
              </SignModalHeaderWrapper>
              <Column style={{ gap: 8 }}>
                <SignModalSectionHeader>
                  동의서 주요 내용
                </SignModalSectionHeader>
                <ol
                  style={{
                    paddingLeft: 24,
                    fontSize: 15,
                    lineHeight: '24px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                  }}
                >
                  <li>
                    채용 확정 이전까지 서비스는 무료로 이용할 수 있으며 실제
                    채용이 발생할 경우 채용 사실을 고지해야해요. 이 경우 연봉의
                    4~6%에 해당하는 수수료가 부과돼요.(VAT 별도)
                  </li>
                  <li>
                    채용 후 2주가 지났음에도 먼저 고지하지 않은 채용 사실을
                    그룹바이가 발견 시 할인 혜택 없이 7%의 수수료가 부과되니, 꼭
                    먼저 알려주세요!
                  </li>
                  <li>
                    채용수수료는 채용자 1개월 이내 퇴사시 100%를, 1~3개월 이내
                    퇴사시 75%를 환불해드려요.(정규직 기준)
                  </li>
                  <li>
                    계약은 2024.12.31까지 유효하며 특별한 변동사항이나 고지가
                    없으면 자동으로 갱신돼요.
                  </li>
                </ol>
              </Column>
            </Column>
            <MyLink
              href='https://app.modusign.co.kr/link/a6dfc960-e119-11ee-a268-5f132480897c/authentication?shareType=URL'
              newTab
              onClick={() =>
                analytics.track('click_dsu_service_confirm_sign_modal_btn')
              }
            >
              <Button
                variant={BtnType.PRIMARY}
                size={isDesktop ? BtnSize.LARGE : BtnSize.MEDIUM}
              >
                계약서 서명하기
              </Button>
              {!isDesktop && <Row>{BenefitLink}</Row>}
            </MyLink>
          </Column>
        </Modal>
      )}
    </>
  )
}

const ContentWrapper = styled(Column)`
  gap: 48px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 16px;
  }
`

const CardTitleWrapper = styled(Row)`
  justify-content: space-between;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 6px;
    align-items: start;
    flex-direction: column;
  }
`

const LinkText = styled(SmallText)`
  color: ${(p) => p.theme.color.secondary};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  text-decoration: underline;
`

const PageLayout = styled(Column)`
  height: calc(100vh - 60px);
  overflow: scroll;
  @media ${(p) => p.theme.deviceSize.desktop} {
    align-items: center;
    padding: 86px 0 60px;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 56px 20px;
    justify-content: flex-start;
  }
`

const MainText = styled(BoldText)`
  font-size: ${(p) => p.theme.fontSize.h2};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`

const SubText = styled(BaseText)`
  color: ${(p) => p.theme.color.black3};
  line-height: 1.5;
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h5};
    white-space: nowrap;
  }
`

const BoxContainer = styled(Column)`
  border-radius: 16px;
  background-color: ${(p) => p.theme.color.white1};
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.03);
  @media ${(p) => p.theme.deviceSize.desktop} {
    width: 877px;
    padding: 54px 43px;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 24px 16px;
  }
`

const PanelCard = styled(Column)`
  gap: 8px;
  position: relative;
  padding: 24px;
  background-color: ${(p) => p.theme.color.white1};
  border: 1px solid ${(p) => p.theme.color.line};
  border-radius: 16px;
  cursor: pointer;
  :hover {
    box-shadow: 0 0 0 3px #00325b inset;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 16px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.03);
  }
`

const CardMainText = styled(H4Text)`
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  line-height: 32px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`

const CardSubText = styled(MediumText)`
  color: ${(p) => p.theme.color.gray1};
  line-height: 24px;
`

const SignModalHeaderWrapper = styled(Row)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    align-items: end;
    justify-content: space-between;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const SignModalMainText = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.h3};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`

const BenefitLinkWrapper = styled(Row)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    justify-content: center;
    padding-top: 8px;
  }
`

const SignModalSectionHeader = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`
