import React from 'react'
import { Row } from 'components/common/layout'
import Image from 'next/legacy/image'
import styled from 'styled-components'
import { MediumText } from 'components/common/text'

export const LocationMemberInfo: React.FC<{
  memberCount: number
  devCount: number
  location: string
}> = ({ memberCount, devCount, location }) => {
  return (
    <LocationMemberContainer>
      <SectionHeaderDesc>{location ? location : '-'}</SectionHeaderDesc>
      <Row style={{ gap: 15 }}>
        <IconGapRow>
          <Image
            src='/people.svg'
            width={16}
            height={16}
            alt='people icon'
            title='직원수'
          />
          <SectionHeaderDesc>
            {memberCount ? memberCount : '-'}명
          </SectionHeaderDesc>
        </IconGapRow>
        <IconGapRow>
          <Image
            src='/github.svg'
            width={16}
            height={16}
            alt='people icon'
            title='개발자수'
          />
          <SectionHeaderDesc>{devCount ? devCount : '-'}명</SectionHeaderDesc>
        </IconGapRow>
      </Row>
    </LocationMemberContainer>
  )
}

const IconGapRow = styled(Row)`
  gap: 3px;
`

const LocationMemberContainer = styled(Row)`
  flex-wrap: wrap;
  gap: 0 25px;
`

const SectionHeaderDesc = styled(MediumText)`
  color: ${(p) => p.theme.color.gray2};
`
