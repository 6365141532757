import { theme } from 'infra/theme'
import styled from 'styled-components'

export enum BalloonType {
  PRIMARY = 'primary',
  BLACK = 'black',
  LOW_BLACK = 'low-black',
  ERROR = 'error',
  SECONDARY = 'secondary',
}

const BalloonTypeMapping = {
  [BalloonType.PRIMARY]: {
    background: '#f4fff7',
    borderRadius: '5px',
    color: theme.color.primary4,
    fontSize: theme.fontSize.small,
  },
  [BalloonType.BLACK]: {
    background: theme.color.black1,
    borderRadius: '16px',
    color: '#5efdc2',
    fontSize: theme.fontSize.default,
  },
  [BalloonType.LOW_BLACK]: {
    background: theme.color.black2,
    borderRadius: '8px',
    color: theme.color.white1,
    fontSize: theme.fontSize.small,
  },
  [BalloonType.ERROR]: {
    background: '#ffeaee',
    borderRadius: '5px',
    color: theme.color.alert,
    fontSize: theme.fontSize.small,
  },
  [BalloonType.SECONDARY]: {
    background: '#d8efff',
    borderRadius: '5px',
    color: '#1f8ff0',
    fontSize: theme.fontSize.small,
  },
}

export const Balloon = styled.div<{
  variant?: BalloonType
  isTopArrow?: boolean
}>`
  padding: 4px 12px;
  text-align: center;
  font-weight: ${(p) => p.theme.fontWeight.medium};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  ${(p) => BalloonTypeMapping[p.variant || BalloonType.PRIMARY]}
  span {
    color: ${(p) => BalloonTypeMapping[p.variant || BalloonType.PRIMARY].color};
  }
  :before {
    ${(p) =>
      p.isTopArrow &&
      `border-bottom: 10px solid
      ${BalloonTypeMapping[p.variant || BalloonType.PRIMARY].background};
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    content: '';
    position: absolute;
    bottom: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);`}
  }
  :after {
    ${(p) =>
      !p.isTopArrow &&
      `border-top: 10px solid
      ${BalloonTypeMapping[p.variant || BalloonType.PRIMARY].background};
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: '';
    position: absolute;
    top: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);`}
  }
`
