import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { BaseText, H5Text } from 'components/common/text'
import Image from 'next/legacy/image'
import { StartupMember } from 'types/common'
import { Card } from 'components/common/card'

export const TeamMember: React.FC<{
  data: StartupMember
  backgroundColor?: string
}> = ({ data, backgroundColor }) => (
  <MemberCard backgroundColor={backgroundColor}>
    <Row style={{ gap: 8 }}>
      <Image src='/search.svg' width={24} height={24} alt='search icon' />
      <MemberTitle>
        {data.name} {data.role}
      </MemberTitle>
    </Row>
    {data.career && (
      <LinkText href={data.career} target='_blank'>
        상세이력
      </LinkText>
    )}
    <MemberDesc>{data.intro}</MemberDesc>
  </MemberCard>
)

const MemberCard = styled(Card)<{ backgroundColor?: string }>`
  gap: 16px;
  padding: 16px;
  background-color: ${(p) => p.backgroundColor || p.theme.color.white1};
`

const MemberTitle = styled(H5Text)`
  font-weight: ${(p) => p.theme.fontWeight.bold};
`

const MemberDesc = styled(BaseText)`
  color: #49454f;
  line-height: 1.5;
`

const LinkText = styled.a`
  font-size: ${(p) => p.theme.fontSize.default};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  color: ${(p) => p.theme.color.secondary};
  text-decoration: underline;
`
