import React from 'react'
import { CertificateTrait } from 'types/common'
import { Tag, TagSize, TagType } from 'components/common/tag'
import styled from 'styled-components'

const getTagType = (item: CertificateTrait) => {
  switch (item) {
    case '자기주도성':
      return TagType.RED
    case '커뮤니케이션':
      return TagType.ORANGE
    case '오너십':
      return TagType.YELLOW
    case '책임감':
      return TagType.GREEN
    case '실무 능력':
      return TagType.BLUE
    case '스타트업 이해도':
      return TagType.NAVY
    case '문제해결력':
      return TagType.PURPLE
  }
}

export const TraitTag: React.FCC<{
  item: CertificateTrait
  size?: TagSize
}> = ({ item, size, children }) => (
  <NowrapTag variant={getTagType(item)} size={size}>
    {children}
  </NowrapTag>
)

const NowrapTag = styled(Tag)`
  white-space: nowrap;
`
