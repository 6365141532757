import React, { useState } from 'react'
import { Column } from 'components/common/layout'
import { Modal } from 'components/common/modal'
import { ScoutProfile, WithIdLink } from 'types/common'
import { observer } from 'mobx-react'
import {
  AttachmentSubText,
  ModalButton,
  ModalContent,
  ModalExplainText,
  ModalMainText,
  ModalSection,
  ModalSectionTitle,
  ProfileFillFile,
} from 'components/scouts/profile/profile-fill-request'
import { requestToast } from 'components/common/toast'
import {
  postRequestScoutRequestMeInfoFill,
  putRequestScoutRequestMe,
} from 'apis/request'
import { mutate } from 'swr'
import { analytics } from 'infra/analytics'
import { useRouter } from 'next/router'
import { BtnType } from 'components/common/button'

export const ProfileFillRequestWithFileModal: React.FC<{
  profile?: ScoutProfile
  onClose: () => void
}> = observer(({ profile, onClose }) => {
  const router = useRouter()
  const [mainFile, setMainFile] = useState<number | null>(null)
  const [files, setFiles] = useState<WithIdLink[]>(profile?.files || [])
  const [isLoading, setIsLoading] = useState(false)

  const submit = async () => {
    if (isLoading) return
    setIsLoading(true)
    try {
      if (files.length === 0) throw '파일을 한 개 이상 올려주세요.'
      if (files.length > 1 && !mainFile) throw '대표 파일을 선택해주세요.'
      const fileIds = profile?.files.map((item) => item.id) || []
      const uploadFiles = files.filter((item) => !fileIds.includes(item.id))
      await putRequestScoutRequestMe({
        files: [...fileIds, ...uploadFiles.map((item) => item.id)],
        addedLinks: [],
      })
      await mutate('/scouts/requests/me')
      const _mainFile = files.length > 1 ? mainFile : files[0].id
      const payload = {
        mainFile: _mainFile,
        files: files
          .filter((item) => item.id !== _mainFile) // 메인파일을 제외하고 보내야함
          .map((item) => item.id),
        links: [],
      }
      await requestToast(
        postRequestScoutRequestMeInfoFill(payload).then(() =>
          mutate('/scouts/requests/me'),
        ),
        {
          loading: '요청중',
          success:
            '이력서를 전달했어요!\n그룹바이가 정보를 작성하고 알림을 드릴게요.',
        },
        { icon: '🎉', duration: 8000 },
      )
      analytics.track('click_groupby_toss_submit', {
        path: router.asPath,
        isMyProfile: false,
      })
      onClose()
    } catch (e) {
      alert(e)
    }
    setIsLoading(false)
  }

  return (
    <Modal width={500} onClose={onClose} disableBackgroundClick disableCloseBtn>
      <ModalContent style={{ paddingBottom: 20 }}>
        <ModalSection>
          <ModalMainText>
            {`그룹바이가 대신\n프로필을 완성해 드릴게요`}
          </ModalMainText>
          <ModalExplainText>
            {`준비하신 이력서, 경력기술서, 자기소개서가 있다면\n`}
            <strong>5분 안에</strong>
            {` 프로필이 완성돼요`}
          </ModalExplainText>
        </ModalSection>
        <Column style={{ gap: 56 }}>
          <ModalSection>
            <Column style={{ gap: 8 }}>
              <ModalSectionTitle>파일</ModalSectionTitle>
              <AttachmentSubText>
                자기소개, 경력 및 활동을 확인할 수 있는 파일을 업로드해 주세요
              </AttachmentSubText>
            </Column>
            <ProfileFillFile
              files={files}
              setFiles={setFiles}
              mainFile={mainFile}
              setMainFile={setMainFile}
            />
          </ModalSection>
        </Column>
        <Column style={{ gap: 8 }}>
          <ModalButton onClick={submit}>그룹바이에게 토스할게요</ModalButton>
          <ModalButton variant={BtnType.TEXT} onClick={onClose}>
            괜찮아요
          </ModalButton>
        </Column>
      </ModalContent>
    </Modal>
  )
})
