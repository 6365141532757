import styled from 'styled-components'
import { Column } from 'components/common/layout'
import {
  BoldText,
  H4Text,
  H5Text,
  MediumText,
  SmallText,
} from 'components/common/text'
import { LinearText } from 'components/scouts/landing/text'

export const Background = styled(Column)`
  background-color: ${(p) => p.theme.color.background2};
`

export const MainText = styled(LinearText)`
  font-size: ${(p) => p.theme.fontSize.h3};
  line-height: 36px;
  text-align: center;
`

export const SubText = styled(SmallText)`
  line-height: 19px;
  color: ${(p) => p.theme.color.black3};
  text-align: center;
`

export const Sub2Text = styled(MediumText)`
  color: ${(p) => p.theme.color.gray2};
  font-size: 11px;
  text-align: center;
`

export const PointText = styled(BoldText)`
  font-size: ${(p) => p.theme.fontSize.small};
  text-align: center;
  background: linear-gradient(
    97.26deg,
    #24d59e 0%,
    #24d59e 23.28%,
    #36c8f5 127.6%,
    #24d59e 127.61%,
    #36c8f5 127.63%,
    rgba(46, 206, 205, 0.914124) 127.64%,
    rgba(54, 200, 245, 0.84) 127.65%,
    #36c8f5 127.66%,
    #34caea 127.68%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`

export const TalentBadgeDescText = styled(H5Text)`
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
  color: #00325b;
  white-space: nowrap;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.desc};
  }
`

export const HeaderText = styled(H4Text)`
  font-weight: ${(p) => p.theme.fontWeight.bold};
  color: #00325b;
`
