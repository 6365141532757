import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { BaseText, BoldText } from 'components/common/text'
import { EditIcon } from 'components/common/icon'
import { theme } from 'infra/theme'
import { TALENT_NAME_VARIABLE } from 'infra/constants'
import { highlightNameInText, matchNameVariable } from 'utils/formatters'
import { Balloon, BalloonType } from 'components/common/balloon'

export const ProposalMessageViewer: React.FC<{
  name: string
  message: string
  isActiveInput: boolean
  onClick: () => void
  onChange: (v: string) => void
}> = ({ name, message, isActiveInput, onClick, onChange }) => {
  const matchedNameVariable = matchNameVariable(message)
  let html = matchedNameVariable.message.replaceAll(/\n/g, '<br/>')
  const matchedName = highlightNameInText(html, name)

  if (matchedName.isHighlightedName && !matchedNameVariable.hasWrongVariable) {
    html = matchedName.text
  }

  if (!matchedName.isHighlightedName && !matchedNameVariable.hasWrongVariable) {
    html = html.replaceAll(
      TALENT_NAME_VARIABLE,
      `<span>${TALENT_NAME_VARIABLE}</span>`,
    )
  }

  return (
    <MessageViewerContainer onClick={onClick}>
      <EditIconWrapper>
        <EditIcon color={theme.color.gray1} size={24} />
      </EditIconWrapper>
      <Column style={{ width: '100%' }}>
        <Row style={{ justifyContent: 'center' }}>
          {(matchedNameVariable.hasWrongVariable ||
            matchedName.isHighlightedName) &&
            !isActiveInput && (
              <NoticeBalloon
                variant={
                  matchedNameVariable.hasWrongVariable
                    ? BalloonType.ERROR
                    : BalloonType.SECONDARY
                }
                onClick={(e) => {
                  e.stopPropagation()
                  if (matchedNameVariable.hasWrongVariable) {
                    onChange(message.replaceAll(/#\{[^}]+\}/g, '#{인재이름}'))
                  } else {
                    onChange(message.replaceAll(name, '#{인재이름}'))
                  }
                }}
              >
                <BaseText>
                  {matchedNameVariable.hasWrongVariable
                    ? `#{인재이름} 형식 그대로 사용해야 변수가 적용되어요!`
                    : '직접 이름을 쓰지 않아도 자동으로 입력할 수 있어요!'}
                </BaseText>
                <BalloonCTA>변수 적용하기</BalloonCTA>
              </NoticeBalloon>
            )}
        </Row>
        <ViewerTextWrapper onClick={onClick}>
          <TextField dangerouslySetInnerHTML={{ __html: html }} />
        </ViewerTextWrapper>
      </Column>
    </MessageViewerContainer>
  )
}

const ViewerTextWrapper = styled.div`
  cursor: pointer;
`

const TextField = styled.p`
  text-align: left;
  line-height: 1.6;
  .error {
    color: ${(p) => p.theme.color.alert};
    background-color: #ffeaee;
  }
  span {
    display: inline-block;
    padding: 0 4px;
    line-height: 1.4;
    border-radius: 4px;
    color: ${(p) => p.theme.color.secondary};
    background-color: #d8efff;
  }
`

const NoticeBalloon = styled(Balloon)`
  gap: 2px;
  top: -45px;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  cursor: pointer;
  animation: noticeBalloonSlideProposalMessageViewer 0.8s ease-in-out infinite;
  @keyframes noticeBalloonSlideProposalMessageViewer {
    from {
      top: -45px;
    }
    50% {
      top: -50px;
    }
    to {
      top: -45px;
    }
  }
`

const BalloonCTA = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
  }
  text-decoration: underline;
`

const MessageViewerContainer = styled.div`
  cursor: pointer;
  position: relative;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.color.line};
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    svg {
      display: none;
    }
    padding: 16px;
    border-radius: 8px;
    :hover {
      background-color: ${(p) => p.theme.color.line};
      svg {
        display: block;
      }
    }
  }
`
const EditIconWrapper = styled.div`
  top: 8px;
  right: 8px;
  padding: 4px;
  position: absolute;
  border-radius: 100px;
  background-color: ${(p) => p.theme.color.background3};
  @media ${(p) => p.theme.deviceSize.mobile} {
    display: none;
  }
`
