import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'

export const AttachmentBlurWrapper: React.FCC<{ isAccessible: boolean }> = ({
  children,
  isAccessible,
}) => {
  return (
    <ItemWrapper isAccessible={isAccessible}>
      {children}
      {!isAccessible && <BlockTouchLayout />}
    </ItemWrapper>
  )
}

const ItemWrapper = styled(Column)<{ isAccessible: boolean }>`
  position: relative;
  display: inline-block;
  flex: 1;
  filter: ${(p) => (p.isAccessible ? 'unset' : 'blur(5px)')};
`

const BlockTouchLayout = styled(Row)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
