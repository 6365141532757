import { Column } from 'components/common/layout'
import { SemiBoldText } from 'components/common/text'
import {
  DefaultErrorSubText,
  ErrorContainer,
  H1ErrorTitle,
  SmallErrorSubText,
} from 'components/misc/error'

export const NotFound: React.FC<{ title?: string; desc?: string }> = ({
  title = '페이지를 찾을 수 없습니다.',
  desc = `존재하지 않는 주소를 입력하셨거나\n요청하신 페이지의 주소가 변경 또는 삭제되어 찾을 수 없습니다.`,
}) => (
  <ErrorContainer>
    <Column>
      <SemiBoldText>서비스 이용에 불편을 드려 죄송합니다.</SemiBoldText>
      <H1ErrorTitle>{title}</H1ErrorTitle>
    </Column>
    <Column style={{ gap: 10 }}>
      <Column>
        <DefaultErrorSubText>{desc}</DefaultErrorSubText>
      </Column>
      <a
        href='https://accounts.kakao.com/login?continue=http%3A%2F%2Fpf.kakao.com%2F_xjWPxoK%2Fchat'
        target='_blank'
        rel='noreferrer'
      >
        <SmallErrorSubText>제보하기</SmallErrorSubText>
      </a>
    </Column>
  </ErrorContainer>
)
