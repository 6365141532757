import React, { Fragment } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { Column, Row } from 'components/common/layout'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { PositionApplicationStore } from 'stores/position-application'
import { POSITION_APPLICATION_STEP } from 'infra/constants'
import { Tag, TagSize, TagType } from 'components/common/tag'
import { BsCaretRightFill } from 'react-icons/bs'
import { theme } from 'infra/theme'
import { useIsDesktopCSR } from 'utils/hooks'

const TAB = ['채용 공고', '기업 정보']

export const PositionApplicationTab: React.FC<{
  store: PositionApplicationStore
}> = observer(({ store }) => {
  return (
    <TabContainer>
      <TabWrapper>
        {TAB.map((item, index) => (
          <Button
            key={index}
            size={BtnSize.MEDIUM}
            variant={store.curTab === index ? BtnType.SECONDARY : BtnType.TEXT}
            onClick={() => store.setCurTab(index)}
          >
            {item}
          </Button>
        ))}
      </TabWrapper>
    </TabContainer>
  )
})

export const ApplicationStatusTab: React.FC<{
  store: PositionApplicationStore
}> = observer(({ store }) => {
  const isDesktop = useIsDesktopCSR()
  return (
    <>
      {POSITION_APPLICATION_STEP.map((item, step) => (
        <Fragment key={step}>
          <Tag
            variant={
              step === store.curStep - 1 ? TagType.BLUE : TagType.LIGHT_GRAY
            }
            size={isDesktop ? TagSize.MEDIUM : TagSize.SMALL}
            style={{
              flex: 1,
              borderRadius: 4,
              textAlign: 'center',
            }}
          >
            {item}
          </Tag>
          {step < 1 && <BsCaretRightFill color={theme.color.gray3} size={12} />}
        </Fragment>
      ))}
    </>
  )
})

const TabContainer = styled(Column)`
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  border-radius: 0;
  background-color: ${(p) => p.theme.color.white1};
  @media ${(p) => p.theme.deviceSize.desktop} {
    position: static;
  }
`

const TabWrapper = styled(Row)`
  gap: 10px;
  padding: 4px;
`
