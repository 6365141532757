import { KAKAO_REDIRECT_URI, KAKAO_REST_API_KEY } from 'infra/constants'

class Kakao {
  login(redirectUrl?: string) {
    window.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${KAKAO_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&state=${encodeURIComponent(
      redirectUrl || window.location.href,
    )}`
  }
}

export const kakao = new Kakao()
