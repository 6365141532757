import styled from 'styled-components'
import { BaseText, DescText, SemiBoldText } from 'components/common/text'
import { Column } from 'components/common/layout'
import { Button } from 'components/common/button'
import { NumberOrNull, WithIdLink } from 'types/common'
import { FileInputBox, FileSelectedBox } from 'components/apply/file-input'
import { uploadFile } from 'utils/handlers'
import { getUrlLastSegment } from 'utils/formatters'
import { requestToast } from 'components/common/toast'

export const ProfileFillFile: React.FC<{
  files: WithIdLink[]
  setFiles: (prev: WithIdLink[]) => void
  mainFile: NumberOrNull
  setMainFile: (fileId: NumberOrNull) => void
}> = ({ files, setFiles, mainFile, setMainFile }) => {
  return (
    <Column style={{ gap: 8 }}>
      {files.length > 1 && <BaseText>대표 파일을 선택해 주세요</BaseText>}
      {files.map((item) => (
        <FileSelectedBox
          file={item}
          key={item.link}
          isSelected={files.length < 2 ? undefined : mainFile === item.id}
          onSelect={() => setMainFile(item.id)}
          onRemove={() => {
            setFiles(files.filter((_item) => _item.id !== item.id))
            if (item.id === mainFile) setMainFile(null)
          }}
        />
      ))}
      <FileInputBox
        onChangeEvent={async (e) => {
          if (!e.target.files?.length) return
          const file = e.target.files[0]
          if (
            files.filter((item) =>
              getUrlLastSegment(item.link).includes(file.name),
            ).length > 0
          )
            return alert('중복된 파일입니다.')
          requestToast(
            uploadFile(e, '/accounts/me/application-files').then((res) => {
              if (!res) return
              setFiles([...files, res])
            }),
            {
              loading: '요청중',
              success: '파일이 업로드되었어요.',
            },
          )
        }}
        isRecommendingPDF
      />
    </Column>
  )
}

export const ModalContent = styled(Column)`
  padding: 48px 24px;
  gap: 48px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 32px 20px;
    gap: 32px;
  }
`

export const ModalMainText = styled(SemiBoldText)`
  text-align: center;
  font-size: ${(p) => p.theme.fontSize.h3};
  line-height: 36px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    line-height: 28px;
    font-size: ${(p) => p.theme.fontSize.h4};
  }
`

export const ModalExplainText = styled(DescText)`
  text-align: center;
  color: ${(p) => p.theme.color.gray1};
  font-weight: ${(p) => p.theme.fontWeight.regular};
  @media ${(p) => p.theme.deviceSize.mobile} {
    line-height: 20px;
    font-size: ${(p) => p.theme.fontSize.default};
  }
`

export const ModalButton = styled(Button)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 12px;
  }
`

export const ModalTextButton = styled(SemiBoldText)`
  display: flex;
  justify-content: center;
  font-size: ${(p) => p.theme.fontSize.h5};
  color: ${(p) => p.theme.color.secondary};
  cursor: pointer;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
`

export const ModalSection = styled(Column)`
  gap: 16px;
`

export const ModalSectionTitle = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.h5};
`

export const AttachmentSubText = styled(BaseText)`
  color: ${(p) => p.theme.color.gray2};
  font-weight: ${(p) => p.theme.fontWeight.regular};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
  }
`
