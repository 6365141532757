import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { Tag, TagContainer, TagSize } from 'components/common/tag'
import { SmallText } from 'components/common/text'
import { TechStack } from 'types/common'

export const TechStackList: React.FC<{
  techStacks: TechStack[]
  isNarrow?: boolean
}> = ({ techStacks, isNarrow }) => {
  const [isVisible, setIsVisible] = useState(false)
  const skillContainerRef = useRef<HTMLDivElement>(null)
  const skills = Array.from(skillContainerRef?.current?.children || []) as
    | HTMLElement[] // children이 SVGElement일 수 있어서 HTMLElement이 아니라 Element라고 타입을 추정하지만 여기서는 svg일 가능성 없음. https://github.com/microsoft/TypeScript/issues/34694
    | []
  const baseOffset = skills[0]?.offsetTop
  const breakIndex = skills.findIndex((item) => item.offsetTop > baseOffset)
  const isBroken = !isVisible && breakIndex > 0
  return (
    <Row
      style={{ gap: 8, cursor: isBroken ? 'pointer' : 'default' }}
      onClick={() => setIsVisible(true)}
    >
      <TagContainer
        ref={skillContainerRef}
        style={
          isVisible
            ? undefined
            : { height: skills[0]?.offsetHeight, overflow: 'hidden' }
        }
      >
        {techStacks.map((item) => (
          <Tag
            key={item.id}
            size={isNarrow ? TagSize.SMALL : TagSize.MEDIUM}
            dangerouslySetInnerHTML={{ __html: item.name }}
          />
        ))}
      </TagContainer>
      {isBroken && (
        <ShortenedCountText>
          +{techStacks.length - breakIndex}
        </ShortenedCountText>
      )}
    </Row>
  )
}

const ShortenedCountText = styled(SmallText)`
  flex: none;
  color: ${(p) => p.theme.color.gray1};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  cursor: pointer;
`
