import React, { useEffect } from 'react'
import { useStores } from 'stores/globals'
import { observer } from 'mobx-react'
import { useUser } from 'apis/hooks'
import { analytics } from './analytics'

export const LoginVerifier: React.FCC = observer((props) => {
  const { loginStore, positionFilterStore } = useStores()
  const { data: user } = useUser()
  useEffect(() => {
    loginStore.verify()
    loginStore.verifyStartup()
    loginStore.blockMultiLogin()
  }, [loginStore])
  useEffect(() => {
    analytics.setIdentify({ isFiltered: !positionFilterStore.isEmpty })
    if (user && positionFilterStore.isEmpty && !positionFilterStore.isChanged) {
      positionFilterStore.applyUserData(
        user.interestedPositionTypes.map((x) => x.id),
        user.experience,
        user.interestedTechStacks.map((x) => x.id),
      )
    }
  }, [positionFilterStore, user])
  return <>{props.children}</>
})
