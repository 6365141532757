import React from 'react'
import styled from 'styled-components'
import Image from 'next/legacy/image'
import { AttachedFile, AttachedLink } from 'components/apply/attachments'
import { StrongText } from 'components/scouts/profile/common/styles'
import { TbLink } from 'react-icons/tb'
import { BsFillFileEarmarkFill } from 'react-icons/bs'
import { WithIdLink } from 'types/common'
import { Column, Row } from 'components/common/layout'
import { AttachmentBlurWrapper } from 'components/scouts/common/attachment-blur-wrapper'

export const FileWithIcon: React.FC<{
  file: WithIdLink
  isAccessible?: boolean
}> = ({ file, isAccessible = true }) => {
  return (
    <AttachmentWrapper>
      <IconWrapper>
        <BsFillFileEarmarkFill size={20} />
      </IconWrapper>
      <AttachmentBlurWrapper isAccessible={isAccessible}>
        <AttachedFile file={file.link} />
      </AttachmentBlurWrapper>
    </AttachmentWrapper>
  )
}

export const LinkWithIcon: React.FC<{
  link: WithIdLink
  isAccessible?: boolean
}> = ({ link, isAccessible = true }) => {
  return (
    <AttachmentWrapper>
      {link.link.includes('github') ? (
        <Image src='/github-fill.svg' width={35} height={35} alt='github' />
      ) : (
        <IconWrapper>
          <TbLink size={20} />
        </IconWrapper>
      )}
      <Column style={{ alignContent: 'space-between' }}>
        <StrongText>
          {link.link.includes('github') ? 'Github' : 'Web'}
        </StrongText>
        <AttachmentBlurWrapper isAccessible={isAccessible}>
          <AttachedLink link={link.link} />
        </AttachmentBlurWrapper>
      </Column>
    </AttachmentWrapper>
  )
}

const IconWrapper = styled(Row)`
  min-width: 35px;
  height: 35px;
  justify-content: center;
  border-radius: 100px;
  background-color: #f4f4f4;
`

const AttachmentWrapper = styled(Row)`
  gap: 16px;
  height: 42px;
`
