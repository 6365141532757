import styled from 'styled-components'
import toast, { ToastOptions } from 'react-hot-toast'
import { theme } from 'infra/theme'
import { Row } from 'components/common/layout'

export const commonToastStyle = {
  borderRadius: 16,
  color: theme.color.primary4,
  fontSize: theme.fontSize.desc,
  fontWeight: theme.fontWeight.semiBold,
  border: `1px solid ${theme.color.primary4}`,
  backgroundColor: theme.color.primary6,
}

export const customToast = (children: React.ReactNode, option?: ToastOptions) =>
  toast.custom(<CustomToastContainer>{children}</CustomToastContainer>, option)

export const requestToast = async <T,>(
  api: Promise<T>,
  message: { loading: string; success: string },
  option?: ToastOptions,
) => {
  const id = toast.loading(message.loading)
  const result = await api.catch((err) => {
    toast.remove(id) // 에러 캐치했을 때 로딩 토스트 제거 후 에러 쓰루
    throw err
  })
  toast.success(message.success, { id, ...option })
  return result
}

const CustomToastContainer = styled(Row)`
  ${commonToastStyle}
  padding: 8px 16px;
  justify-content: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
  white-space: pre-wrap;
`
