import styled from 'styled-components'
import { BaseText } from 'components/common/text'
import { Column, Row } from 'components/common/layout'
import { TabMenu, TabMenuContainer, TabMenuText } from 'components/common/tab'

export const MainContainer = styled(Column)`
  width: 100%;
  position: fixed;
  z-index: 100;
  align-items: center;
  background-color: ${(p) => p.theme.color.white1};
  border-bottom: 1px solid ${(p) => p.theme.color.line};
`

export const ContentContainer = styled(Row)`
  width: 100%;
  height: 60px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 0 20px;
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    gap: 50px;
    justify-content: space-between;
    width: 1080px;
  }
`

export const DashboardContentContainer = styled(ContentContainer)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    width: 100%;
    padding: 0 20px;
  }
  @media ${(p) => p.theme.deviceSize.extendDesktop} {
    justify-content: space-between;
    width: 1320px;
  }
`

export const HeaderMenuTitle = styled(BaseText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 15px;
  }
  display: block;
  padding: 8px 10px;
  white-space: nowrap;
  cursor: pointer;
`

export const LogoutHeaderMenuTitle = styled(HeaderMenuTitle)`
  color: ${(p) => p.theme.color.alert};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  :hover {
    color: ${(p) => `${p.theme.color.alert}aa`};
  }
`

export const HeaderMenuLine = styled.hr`
  width: 100%;
  margin: 8px 0;
  border: 0.5px solid ${(p) => p.theme.color.gray3};
`

export const DashboardHeaderMenuTitle = styled(HeaderMenuTitle)<{
  isSelected: boolean
}>`
  padding: 8px 16px;
  font-weight: ${(p) =>
    p.isSelected ? p.theme.fontWeight.bold : p.theme.fontWeight.regular};
`

// 모바일 탭
export const MobileTabMenuContainer = styled(TabMenuContainer)`
  width: 100%;
  height: 100%;
  gap: 24px;
  overflow: visible;
  justify-content: end;
  align-items: end;
  @media ${(p) => p.theme.deviceSize.desktop} {
    display: none;
  }
`

export const MobileTabMenu = styled(TabMenu)`
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`

export const MobileTabMenuText = styled(TabMenuText)`
  text-align: center;
  height: 24px;
  min-width: 35px;
  color: #003827;
  font-size: ${(p) => p.theme.fontSize.small};
  ${(p) => !p.isSelected && `font-weight: ${p.theme.fontWeight.regular}`};
`
