import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Image from 'next/legacy/image'
import { observer } from 'mobx-react'
import { useUser } from 'apis/hooks'
import { TextInput } from 'components/common/input'
import { Column, Row } from 'components/common/layout'
import {
  PositionApplicationFormContainer,
  PositionApplicationNextBtn,
  PositionApplicationStepTitleText,
} from 'containers/positions/application/styles'
import {
  convertHyphenPhoneToNumber,
  convertNumberToHyphenPhone,
} from 'utils/displays'
import { PositionApplicationStore } from 'stores/position-application'
import { putAccountsMe } from 'apis/request'
import { isEmailValid, isNameValid, isPhoneValid } from 'utils/validations'
import { INVALID_PHONE_MSG } from 'infra/constants'
import { mutate } from 'swr'

export const PositionApplicationCheckContactStep: React.FC<{
  store: PositionApplicationStore
}> = observer(({ store }) => {
  const { data: user } = useUser()
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (!user || name || phone || email) return
    setName(user.name)
    setPhone(user.phone)
    setEmail(user.email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => store.setCurTab(0), [])

  if (!user) return null
  return (
    <PositionApplicationFormContainer>
      <Column style={{ gap: 16 }}>
        <PositionApplicationStepTitleText>
          연락처를 확인해주세요
        </PositionApplicationStepTitleText>
        <Row style={{ gap: 16 }}>
          <IconWrapper>
            <Image
              src='/user-circle-black2.svg'
              width={24}
              height={24}
              alt='user'
            />
          </IconWrapper>
          <TextInput
            placeholder='이름 입력'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Row>
        <Row style={{ gap: 16 }}>
          <IconWrapper>
            <Image src='/phone.svg' width={24} height={24} alt='user' />
          </IconWrapper>
          <TextInput
            placeholder='전화번호 입력'
            value={convertNumberToHyphenPhone(phone)}
            onChange={(e) => {
              if (e.target.value.length > 13) return
              setPhone(convertHyphenPhoneToNumber(e.target.value))
            }}
          />
        </Row>
        <Row style={{ gap: 16 }}>
          <IconWrapper>
            <Image src='/mail.svg' width={24} height={24} alt='user' />
          </IconWrapper>
          <TextInput
            placeholder='이메일 입력'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Row>
      </Column>
      <PositionApplicationNextBtn
        onClick={async () => {
          const _name = name.trim()
          const _phone = phone.trim()
          const _email = email.trim()
          if (!isNameValid(_name))
            return alert(
              '공백, 특수문자, 숫자 등이 포함되지 않은 유효한 이름을 입력해주세요.',
            )
          if (!isPhoneValid(_phone)) return alert(INVALID_PHONE_MSG)
          if (!isEmailValid(_email))
            return alert('유효한 이메일 주소를 입력해주세요.')
          if (
            user.name !== _name ||
            user.phone !== _phone ||
            user.email !== _email
          )
            await putAccountsMe({
              name: _name,
              phone: _phone,
              email: _email,
            }).then(() => mutate('/accounts/me'))
          store.moveNextStep()
        }}
      >
        다음
      </PositionApplicationNextBtn>
    </PositionApplicationFormContainer>
  )
})

const IconWrapper = styled(Column)`
  flex: none;
`
