import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { FiStar } from 'react-icons/fi'
import { theme } from 'infra/theme'
import { BoldText } from 'components/common/text'

export const AssessmentScore: React.FC<{
  score: number
}> = ({ score }) => {
  return (
    <Row style={{ gap: 8 }}>
      <Row style={{ flex: 'none' }}>
        <FiStar fill={theme.color.gold} color={theme.color.gold} size={18} />
      </Row>
      <AssessmentScoreText>{score} 점</AssessmentScoreText>
    </Row>
  )
}

export const AssessmentScoreText = styled(BoldText)`
  color: ${(p) => p.theme.color.secondary};
  font-size: ${(p) => p.theme.fontSize.h5};
`
