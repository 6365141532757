import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import Image from 'next/legacy/image'
import { Row } from 'components/common/layout'
import { BaseText, MediumText } from 'components/common/text'
import { useOnClickOutside } from 'usehooks-ts'
import { useIsDesktop } from 'utils/hooks'
import { Modal } from 'components/common/modal'
import { observer } from 'mobx-react'
import { ShadowCard } from 'components/common/card'
import { textEllipsis } from 'components/common/styles'
import { DataPicker } from 'components/common/data-picker'
import { NumberOrNull, WithIdName, WithIdNameParentId } from 'types/common'

export enum DataPickerDropdownSize {
  DEFAULT = 'default',
  SMALL = 'small',
}

const SizeMapping = {
  [DataPickerDropdownSize.DEFAULT]: {
    padding: '15px 20px',
  },
  [DataPickerDropdownSize.SMALL]: {
    padding: '10px 15px',
  },
}

export const DataPickerDropdown: React.FCC<{
  value: string
  label?: string
  badgeCount?: number
  defaultValue?: string
  size?: DataPickerDropdownSize
  data?: WithIdNameParentId[] // 상위 분류가 있는 필터만 사용
  selectedData?: (WithIdName & { parentId?: NumberOrNull })[]
  onReset?: () => void
  onDelete?: (item: WithIdName & { parentId?: NumberOrNull }) => void
  onClick?: (isOpen: boolean) => void
}> = observer(
  ({
    children,
    value,
    selectedData,
    onReset,
    onDelete,
    label,
    badgeCount = 0,
    defaultValue = '전체',
    size,
    data,
    onClick,
  }) => {
    const isDesktop = useIsDesktop()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside(dropdownRef, () => {
      setIsOpen(false)
      onClick?.(false)
    })

    const content = (
      <DataPicker
        data={data}
        selectedData={selectedData}
        onClose={() => setIsOpen(false)}
        onReset={onReset}
        onDelete={onDelete}
      >
        {children}
      </DataPicker>
    )

    return (
      <DropdownContainer ref={dropdownRef}>
        {badgeCount > 0 && <CountBadge>{badgeCount}</CountBadge>}
        <DropdownWrapper
          size={size}
          isActivated={isOpen || !!value}
          onClick={() => {
            setIsOpen(!isOpen)
            onClick?.(!isOpen)
          }}
        >
          {label && <DropdownLabel>{label}</DropdownLabel>}
          <SelectedWrapper>
            <ValueWrapper>
              <SelectedValue isActivated={!!value}>
                {value || defaultValue}
              </SelectedValue>
            </ValueWrapper>
            <Image
              src='/caret-down-gray2.svg'
              width={16}
              height={16}
              alt='caret-down'
              priority
            />
          </SelectedWrapper>
        </DropdownWrapper>
        {isOpen &&
          (isDesktop ? (
            <DropdownMenuContainer
              offsetLeft={dropdownRef.current?.offsetLeft || 0}
            >
              {content}
            </DropdownMenuContainer>
          ) : (
            <Modal width={100} onClose={() => setIsOpen(false)} disableCloseBtn>
              {content}
            </Modal>
          ))}
      </DropdownContainer>
    )
  },
)

const DropdownContainer = styled.div`
  flex: 1;
  position: relative;
`

const DropdownWrapper = styled(Row)<{
  isActivated: boolean
  size?: DataPickerDropdownSize
}>`
  justify-content: space-between;
  border: 1px solid
    ${(p) => (p.isActivated ? p.theme.color.primary : p.theme.color.gray3)};
  border-radius: 16px;
  cursor: pointer;
  transition: 0.2s;
  ${(p) => p.size && `padding: ${SizeMapping[p.size].padding} !important`};
  padding: 16px 24px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 12px 24px;
  }
  gap: 20px;
  background-color: ${(p) => p.theme.color.white1};
`

const DropdownLabel = styled(MediumText)`
  white-space: nowrap;
`

const SelectedWrapper = styled(Row)`
  gap: 10px;
  white-space: nowrap;
  overflow: hidden;
`

const ValueWrapper = styled(Row)`
  flex: 1;
  gap: 5px;
  white-space: nowrap;
  overflow: hidden;
`

const SelectedValue = styled(BaseText)<{ isActivated: boolean }>`
  ${textEllipsis}
  color: ${(p) =>
    p.isActivated ? p.theme.color.primary : p.theme.color.gray2};
  font-weight: ${(p) =>
    p.isActivated ? p.theme.fontWeight.semiBold : p.theme.fontWeight.medium};
`

const DropdownMenuContainer = styled(ShadowCard)<{
  offsetLeft: number
}>`
  ${(p) => (p.offsetLeft > 800 ? 'right:0' : 'left:0')};
  top: 58px;
  position: absolute;
`

const CountBadge = styled(Row)`
  position: absolute;
  justify-content: center;
  right: -5px;
  top: -5px;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  background-color: ${(p) => p.theme.color.primary};
  color: ${(p) => p.theme.color.white1};
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
`
