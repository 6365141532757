import {
  postRequestDashboardScoutRequestProposalFinalize,
  putRequestDashboardScoutRequestProposal,
} from 'apis/request'
import { makeAutoObservable } from 'mobx'
import { mutate } from 'swr'
import {
  DashboardInitialScoutProposal,
  DashboardScoutRequestDetail,
  DashboardStartupDetail,
  DashboardTempScoutProposal,
  ScoutFulfillment,
  ScoutRequestFormDataKey,
  StartupUser,
} from 'types/common'
import { getRandomIntroTemplate, getRandomOutroTemplate } from 'utils/templates'

export class ProposalStore {
  request: DashboardScoutRequestDetail
  initial: DashboardInitialScoutProposal
  temp: DashboardTempScoutProposal
  user: StartupUser
  startup: DashboardStartupDetail
  tempFulfillments: ScoutFulfillment[]
  initialFulfillments: ScoutFulfillment[]
  isOpenPreviewModal: boolean = false
  focusInputIndex: number = 0
  pageIndex: number = 0
  isOpenLockAccount: boolean = false
  isCheckedDoNotSee: boolean = false
  isConfirmModalOpen: boolean = false
  isLoading: boolean | null = null

  constructor({
    temp,
    initial,
    request,
    user,
    startup,
  }: {
    temp: DashboardTempScoutProposal
    initial: DashboardInitialScoutProposal
    request: DashboardScoutRequestDetail
    user: StartupUser
    startup: DashboardStartupDetail
  }) {
    this.temp = {
      ...temp,
      isResumeRequest: false,
      fulfillments: temp.fulfillments || initial.fulfillments || '',
      introPerson: temp.introPerson || initial.introPerson || '',
      outro: temp.outro || initial.outro || '',
      appealPoints: temp.appealPoints || '',
      proposeReason: temp.proposeReason || '',
    }
    this.startup = startup
    this.initial = initial
    this.request = request
    this.user = user
    this.tempFulfillments = temp.fulfillments
    this.initialFulfillments = initial.fulfillments
    if (!this.temp.introPerson)
      this.temp.introPerson = getRandomIntroTemplate(startup.name || '')
    if (!this.temp.outro)
      this.temp.outro = getRandomOutroTemplate(startup.name || '')
    makeAutoObservable(this)
    if (!initial.introPerson) return
    if (!this.temp.outro) this.setFocusInputIndex(4) // 지금은 위에서 this.temp.outro 가 없으면 무조건 채워줘서 조건 충족 안 됨
    if (!this.temp.appealPoints) this.setFocusInputIndex(3)
    if (!this.temp.proposeReason) this.setFocusInputIndex(2)
    if (!this.temp.introPerson) this.setFocusInputIndex(1) // 지금은 위에서 this.temp.introPerson 가 없으면 무조건 채워줘서 조건 충족 안 됨
  }

  get isAbleNext() {
    return this.pageIndex === 0
      ? !!this.temp.appealPoints &&
          !!this.temp.introPerson &&
          !!this.temp.outro &&
          !!this.temp.proposeReason
      : this.pageIndex === 1
      ? this.temp.fulfillments.length > 0
      : true
  }

  get fullMessage() {
    return (
      this.temp.introPerson +
      this.temp.proposeReason +
      this.temp.appealPoints +
      this.temp.outro
    )
  }

  get isIntroPersonActive() {
    return this.focusInputIndex === 1
  }

  get isProposeReasonActive() {
    return this.focusInputIndex === 2
  }

  get isAppealPointsActive() {
    return this.focusInputIndex === 3
  }

  get isOutroActive() {
    return this.focusInputIndex === 4
  }

  get isNeedEditTemplate() {
    return (
      this.temp.introPerson.includes('(괄호까지 지우고 여기에 "직책" 입력)') ||
      this.temp.introPerson.includes('(괄호까지 지우고 여기에 "이름" 입력)')
    )
  }

  setIsCheckedDoNotSee(v: boolean) {
    this.isCheckedDoNotSee = v
  }

  setIsOpenLockAccount(v: boolean) {
    this.isOpenLockAccount = v
  }

  setIsConfirmModalOpen(v: boolean) {
    this.isConfirmModalOpen = v
  }

  setPageIndex(index: number) {
    this.pageIndex = index
  }

  setFocusInputIndex(index: number) {
    this.focusInputIndex = index
  }

  getSelectedFulfillmentDesc(dataKey: ScoutRequestFormDataKey) {
    return (
      this.temp.fulfillments.find((_cond) => dataKey === _cond.dataKey)
        ?.description || ''
    )
  }

  setPrevMessage() {
    this.temp = {
      ...this.temp,
      proposeReason: this.initial.proposeReason || '',
      appealPoints: this.initial.appealPoints || '',
    }
  }

  setIsResumeRequest(bool: boolean) {
    this.temp.isResumeRequest = bool
  }

  setIsOpenPreviewModal(bool: boolean) {
    this.isOpenPreviewModal = bool
  }

  setAppealPoints(v: string) {
    this.temp.appealPoints = v
  }

  setIntroPerson(v: string) {
    this.temp.introPerson = v
  }

  setProposeReason(v: string) {
    this.temp.proposeReason = v
  }

  setOutro(v: string) {
    this.temp.outro = v
  }

  togglePosition(id: number) {
    if (this.temp.positions.includes(id)) {
      this.temp.positions = this.temp.positions.filter(
        (position) => position !== id,
      )
    } else {
      this.temp.positions = [...this.temp.positions, id]
    }
  }

  setFulfillments(dataKey: ScoutRequestFormDataKey) {
    if (this.isDataKeySelected(dataKey)) {
      this.temp.fulfillments = this.temp.fulfillments.filter(
        (cond) => cond.dataKey !== dataKey,
      )
    } else {
      this.temp.fulfillments = [
        ...this.temp.fulfillments,
        {
          dataKey,
          description:
            this.tempFulfillments.find((item) => item.dataKey === dataKey)
              ?.description ||
            '' ||
            this.initialFulfillments.find((item) => item.dataKey === dataKey)
              ?.description ||
            '',
        },
      ]
    }
  }

  isDataKeySelected(dataKey: ScoutRequestFormDataKey) {
    return this.temp.fulfillments.map((cond) => cond.dataKey).includes(dataKey)
  }

  setConditionDescription(dataKey: string, description: string) {
    this.temp.fulfillments = this.temp.fulfillments.map((cond) => {
      if (cond.dataKey === dataKey) cond.description = description
      return cond
    })
  }

  setIsLoading(v: boolean) {
    this.isLoading = v
  }

  checkValidity() {
    if (this.temp.fulfillments.map((item) => item.dataKey).length === 0)
      throw '"조건 선택"에서 인재를 만족시킬 수 있는 조건을 선택해주세요.'
    if (!this.temp.introPerson.trim())
      throw '"메시지 보내는 사람 소개"를 작성해주세요.'
    if (!this.temp.proposeReason.trim())
      throw '"메시지를 보내는 이유"를 작성해주세요.'
    if (!this.temp.appealPoints.trim())
      throw '"맞춤형 매력 어필"을 작성해주세요.'
    if (!this.temp.outro.trim()) throw '"끝맺음 인사"를 작성해주세요.'
  }

  async finalize(id: number): Promise<number> {
    const res = await postRequestDashboardScoutRequestProposalFinalize(id)
    return res.data?.proposalId || 0 // 정상적으로 요청 성공하면 undefined일리 없지만 타입상 기본값 0으로 처리해줌
  }

  async save() {
    return putRequestDashboardScoutRequestProposal(
      this.request.id,
      false,
      this.temp,
    ).finally(() => this.setIsLoading(false))
  }

  async updateTemp() {
    return mutate(
      `/dashboard/scouts/requests/${this.request.id}/proposal`,
    ).then(({ data }) => {
      this.temp = { ...data, isResumeRequest: true }
    })
  }
}
