import React from 'react'
import styled from 'styled-components'
import dynamic from 'next/dynamic'
import { DesktopHeaderTabs } from 'components/misc/header/desktop-header-tab-menu'
import { Row } from 'components/common/layout'
import { ContentContainer, MainContainer } from 'components/misc/header/styles'
import { ResponsiveDisplay } from 'components/common/layout'
import { HeaderLogo } from 'components/misc/header/header-logo'

const DesktopHeaderProfile = dynamic(() => import('./desktop-header-profile'), {
  ssr: false,
})

const MobileHeaderTabs = dynamic(() => import('./mobile-header-tab-menu'), {
  ssr: false,
})

export const Header: React.FC = () => {
  return (
    <>
      <MainContainer>
        <ContentContainer>
          <HeaderLogo />
          <Content>
            <DesktopHeaderTabs />
            <MobileHeaderTabs />
            <ResponsiveDisplay isDisableMobile>
              <DesktopHeaderProfile />
            </ResponsiveDisplay>
          </Content>
        </ContentContainer>
      </MainContainer>
    </>
  )
}

const Content = styled(Row)`
  flex: 1;
  @media ${(p) => p.theme.deviceSize.desktop} {
    justify-content: space-between;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    position: relative;
    height: 100%;
  }
`
