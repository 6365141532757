import React from 'react'
import styled from 'styled-components'
import { BaseText, MediumText, SemiBoldText } from 'components/common/text'
import { Column, Row } from 'components/common/layout'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { Checker } from 'components/common/icon'

const CheckListBox = styled(Column)`
  padding: 10px 32px;
  gap: 8px;
  border: 1px solid ${(p) => p.theme.color.primary};
  border-radius: 8px;
`

const CheckListContainer = styled(Column)`
  gap: 8px;
`

const CheckListItemWrapper = styled(Row)`
  gap: 10px;
`

const CheckListItemText = styled(BaseText)<{ isChecked: boolean }>`
  color: ${(p) => p.theme.color.black2};
  ${(p) => p.isChecked && 'text-decoration: line-through;'}
`

export const CheckList: React.FC<{
  data: { name: string; isChecked: boolean }[]
}> = ({ data }) => {
  return (
    <CheckListBox>
      <CheckListContainer>
        {data.map((item, index) => (
          <CheckListItemWrapper key={index}>
            <Checker isChecked={item.isChecked} />
            <CheckListItemText isChecked={item.isChecked}>
              {item.name}
            </CheckListItemText>
          </CheckListItemWrapper>
        ))}
      </CheckListContainer>
    </CheckListBox>
  )
}

export const ModalContent = styled(Column)`
  padding: 20px;
  gap: 32px;
`

const Center = styled(Column)`
  margin: 0 auto;
`

export const MainContent = styled(Center)`
  gap: 24px;
`

export const ModalTitle = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.desc};
`

export const CheckListWrapper = styled(Center)`
  gap: 8px;
`

export const CheckListTitle = styled(MediumText)`
  font-size: ${(p) => p.theme.fontSize.small};
  color: ${(p) => p.theme.color.gray1};
  text-align: center;
`

export const ButtonContainer = styled(Row)`
  gap: 8px;
`

const CheckModalButton = styled(Button).attrs({ size: BtnSize.MEDIUM })`
  padding: 12px 0;
`

export const SubButton = styled(CheckModalButton).attrs({
  variant: BtnType.SUPER_LOW,
})`
  width: 120px;
`

export const MainButton = styled(CheckModalButton)`
  flex: 1;
`
