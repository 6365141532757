import { TraitTag } from 'components/certificate/common/certificate-tag'
import { TalentBadgeDescText } from 'components/certificate/common/styles'
import { TalentBadgeIcon } from 'components/certificate/talent-badge-icon'
import { Column, Row } from 'components/common/layout'
import React from 'react'
import styled from 'styled-components'
import {
  DashboardScoutRequestListQueryParams,
  IdpCertificate,
} from 'types/common'

export const TalentBadgeDescCard: React.FC<{
  iconSize: number
  idpCertificate: IdpCertificate
  row?: boolean
  onClick?: () => void
  params?: DashboardScoutRequestListQueryParams
}> = ({ iconSize, idpCertificate, row = false, onClick, params }) => (
  <DescCard onClick={onClick} $cursor={!!onClick}>
    <TalentBadgeIcon size={iconSize} idpCertificate={idpCertificate} />
    <ResponsibleContainer row={row}>
      <TalentBadgeDescText style={{ flex: 1 }}>
        {params?.idpCertificates.includes(idpCertificate.id) ? (
          <span className='highlighted'>{idpCertificate.name}</span>
        ) : (
          idpCertificate.name
        )}
      </TalentBadgeDescText>
      <TraitTagWrapper>
        {idpCertificate.traits.map((trait, index) => (
          <TraitTag item={trait} key={index}>
            {trait}
          </TraitTag>
        ))}
      </TraitTagWrapper>
    </ResponsibleContainer>
  </DescCard>
)

const DescCard = styled(Row)<{ $cursor: boolean }>`
  padding: 24px;
  width: 100%;
  gap: 16px;
  border-radius: 16px;
  background-color: ${(p) => p.theme.color.background3};
  ${(p) => p.$cursor && 'cursor: pointer;'}
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 16px;
  }
`

const ResponsibleContainer = styled(Column)<{ row: boolean }>`
  gap: 8px;
  flex: 1;
  ${(p) => p.row && 'flex-direction: row; flex: 1;'}
`

const TraitTagWrapper = styled(Row)`
  gap: 8px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    display: none;
  }
`

export const ShadowBadgeDescCard = styled(Row)`
  border-radius: 16px;
  box-shadow: 0px 1px 8px rgba(161, 201, 255, 0.24);
  & div {
    background-color: ${(p) => p.theme.color.white1};
  }
`
