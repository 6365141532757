import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { BUGSNAG_API_KEY } from 'infra/constants'
import { useIsClient } from 'usehooks-ts'
import { ErrorFallback } from 'components/misc/error-fallback'

// NOTE(seulgi): https://github.com/bugsnag/bugsnag-js/issues/811#issuecomment-703853628
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (!Bugsnag._client) {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY as string,
    plugins: [new BugsnagPluginReact()],
  })
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ErrorComponent = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

export const ErrorBoundary: React.FCC = ({ children }) => {
  const isClient = useIsClient()
  return (
    <>
      {isClient ? (
        <ErrorComponent FallbackComponent={ErrorFallback}>
          {children}
        </ErrorComponent>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
