import { OtherActivityHistory } from 'types/common'
import { LineLimitedText } from 'components/scouts/profile-detail/desc-more-view'
import { Column, Row } from 'components/common/layout'
import { MediumText } from 'components/common/text'
import { displayOtherActivityPeriod } from 'utils/displays'
import {
  WorkExperienceHeader,
  WorkExperiencePeriodText,
} from 'components/scouts/profile/common/styles'
import { theme } from 'infra/theme'
import styled from 'styled-components'
import { TechStackList } from 'components/scouts/profile/common/tech-stack-list'

export const OtherActivityDetail: React.FC<{
  data: OtherActivityHistory
  isNarrow?: boolean
}> = ({ data, isNarrow }) => (
  <Row style={{ gap: 12, alignItems: 'start' }}>
    <span>💡</span>
    <Column style={{ gap: 10, width: '100%' }}>
      <WorkExperienceHeader isNarrow={isNarrow}>
        <Column style={{ gap: 10, width: '100%' }}>
          <HeaderTextWrapper isNarrow={isNarrow}>
            <MediumText dangerouslySetInnerHTML={{ __html: data.title }} />
            <WorkExperiencePeriodText>
              {data.started && displayOtherActivityPeriod(data)}
            </WorkExperiencePeriodText>
          </HeaderTextWrapper>
          {data.techStacks.length > 0 && (
            <TechStackList techStacks={data.techStacks} />
          )}
        </Column>
      </WorkExperienceHeader>
      {data.description && (
        <div>
          <LineLimitedText text={data.description} color={theme.color.black3} />
        </div>
      )}
    </Column>
  </Row>
)

const HeaderTextWrapper = styled(Column)<{ isNarrow?: boolean }>`
  @media ${(p) => p.theme.deviceSize.desktop} {
    ${(p) =>
      !p.isNarrow
        ? 'justify-content: space-between; flex-direction: row; gap: 40px;'
        : 'gap: 4px;'}
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 4px;
  }
`
