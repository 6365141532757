import { makeAutoObservable } from 'mobx'
import { StartupPositionReqParams } from 'types/common'
import qs from 'query-string'
import { mutate } from 'swr'

export class PositionListStore {
  count: number = 1
  scroll: number = 0
  params: StartupPositionReqParams | null = null
  constructor() {
    makeAutoObservable(this)
  }

  setCount(v: number) {
    this.count = v
  }
  setScroll(v: number) {
    this.scroll = v
  }

  setParams(v: StartupPositionReqParams) {
    this.params = v
  }

  mutatePositionList() {
    this.params && mutate(`/startup-positions?${qs.stringify(this.params)}`)
  }
}
