import React from 'react'
import {
  SectionDesc,
  SectionPaper,
  SubSectionTitle,
  SectionTitle,
  SubSectionWrapper,
} from 'components/startup/startup-styles'
import { Column } from 'components/common/layout'
import { TextViewer } from 'components/common/text-editor'
import { checkEditorDefaultValue } from 'utils/validations'

export const CompensationWelfare: React.FC<{
  compensation: string
  welfare: string
}> = ({ compensation, welfare }) => {
  const hasNotCompensation =
    !compensation || checkEditorDefaultValue(compensation)
  const hasNotWelfare = !welfare || checkEditorDefaultValue(welfare)
  if (hasNotCompensation && hasNotWelfare) return null
  return (
    <Column id='benefit'>
      <SectionTitle>보상 및 복지</SectionTitle>
      <SectionDesc>친화적인 업무환경을 확인해보세요.</SectionDesc>
      <SectionPaper>
        {!hasNotCompensation && (
          <SubSectionWrapper>
            <SubSectionTitle>약속드리는 보상</SubSectionTitle>
            <TextViewer value={compensation} />
          </SubSectionWrapper>
        )}
        {!hasNotWelfare && (
          <SubSectionWrapper>
            <SubSectionTitle>매력적인 복지</SubSectionTitle>
            <TextViewer value={welfare} />
          </SubSectionWrapper>
        )}
      </SectionPaper>
    </Column>
  )
}
