import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { BaseText, DescText, SemiBoldText } from 'components/common/text'
import { Card } from 'components/common/card'
import { useDebounce, useIsDesktopCSR } from 'utils/hooks'
import { ProposalStore } from 'stores/scouts/proposal'
import { observer } from 'mobx-react'
import { ProposalMessageInput } from 'components/scouts/proposal/proposal-message-input'
import { getMessageTipTemplate } from 'utils/templates'
import { MainText, SubText } from 'components/dashboard/scouts/proposal/styles'
import { InfoCircleIcon } from 'components/common/icon'
import { theme } from 'infra/theme'
import { FiRefreshCcw } from 'react-icons/fi'
import { Badge, BadgeColor } from 'components/common/badge'
import { BsStars } from 'react-icons/bs'
import { useStores } from 'stores/globals'
import { LoadingSpinnerIcon } from 'components/common/loading-spinner'
import { Balloon } from 'components/common/balloon'
import { TALENT_NAME_VARIABLE } from 'infra/constants'
import { Tag } from 'components/common/tag'
import { ProposalMessageViewer } from 'components/dashboard/scouts/proposal/proposal-message-viewer'
import { VariableAddingButton } from 'components/dashboard/scouts/proposal/variable-adding-button'

export const ProposalWriteMessage: React.FC<{
  proposalStore: ProposalStore
}> = observer(({ proposalStore }) => {
  const activeInputRef = useRef<HTMLTextAreaElement>(null)
  const saveDebounce = useDebounce(1500, () => proposalStore.save())
  const { autoProposalMessageStore } = useStores()
  const autoProposalMessage =
    autoProposalMessageStore.autoMessages[proposalStore.request.id]
  const autoProposalDisabled =
    (proposalStore.temp.proposeReason.trim() &&
      !proposalStore.isProposeReasonActive) ||
    (proposalStore.temp.appealPoints.trim() &&
      !proposalStore.isAppealPointsActive) ||
    (autoProposalMessage && autoProposalMessage.isLoading)
  const isDesktop = useIsDesktopCSR()
  const messageTipTemplate = getMessageTipTemplate(
    proposalStore.startup.name || '',
  )
  const getVariableInsertedMsg = (text: string) => {
    const insertIndex = activeInputRef.current?.selectionEnd
    return (
      text.slice(0, insertIndex) +
      TALENT_NAME_VARIABLE +
      text.slice(insertIndex)
    )
  }

  useEffect(() => {
    if (
      autoProposalMessage &&
      autoProposalMessage.proposeReason &&
      autoProposalMessage.appealPoints
    ) {
      proposalStore.setFocusInputIndex(0)
      proposalStore.setAppealPoints(autoProposalMessage.appealPoints)
      proposalStore.setProposeReason(autoProposalMessage.proposeReason)
      saveDebounce()
      autoProposalMessageStore.removeAutoMessage(proposalStore.request.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoProposalMessage])

  const isActiveInput =
    proposalStore.isIntroPersonActive ||
    proposalStore.isOutroActive ||
    proposalStore.isAppealPointsActive ||
    proposalStore.isProposeReasonActive

  return (
    <Column style={{ gap: 16 }}>
      <MainTextWrapper>
        <MainText>메시지 작성</MainText>
        {proposalStore.initial.appealPoints &&
          proposalStore.initial.proposeReason && (
            <RefreshBtn
              onClick={() => {
                proposalStore.setPrevMessage()
                proposalStore.setIsLoading(true)
                saveDebounce()
              }}
            >
              <FiRefreshCcw
                size={isDesktop ? 18 : 14}
                color={theme.color.gray1}
              />
              <BringMessageText>이전 메시지 불러오기</BringMessageText>
            </RefreshBtn>
          )}
      </MainTextWrapper>
      {!isDesktop && (
        <InfoContainer>
          <InfoCircleIcon size={16} color={theme.color.gray4} />
          <InfoText>
            <span
              style={{ fontWeight: theme.fontWeight.semiBold }}
            >{`#{인재이름}`}</span>
            을 입력하면 메시지를 보낼 때 인재의 이름으로 자동 변환돼요
          </InfoText>
        </InfoContainer>
      )}
      <CardContainer>
        {proposalStore.isNeedEditTemplate && (
          <GuideBalloon onClick={() => proposalStore.setFocusInputIndex(1)}>
            자동 입력된 템플릿에 직책과 이름을 직접 입력해주세요
          </GuideBalloon>
        )}
        {proposalStore.isIntroPersonActive ||
        !proposalStore.temp.introPerson ? (
          <Section>
            <SectionTitleContainer>
              <SubText focus={proposalStore.isIntroPersonActive}>
                메시지 보내는 사람 소개
              </SubText>
              {proposalStore.isIntroPersonActive && (
                <VariableAddingButton
                  onClick={() => {
                    proposalStore.setIntroPerson(
                      getVariableInsertedMsg(proposalStore.temp.introPerson),
                    )
                    proposalStore.setIsLoading(true)
                    saveDebounce()
                  }}
                />
              )}
            </SectionTitleContainer>
            <ProposalMessageInput
              activeInputRef={
                proposalStore.isIntroPersonActive ? activeInputRef : undefined
              }
              autoFocus={proposalStore.isIntroPersonActive}
              value={proposalStore.temp.introPerson}
              onChange={(e) => {
                proposalStore.setIntroPerson(e.target.value)
                proposalStore.setIsLoading(true)
                saveDebounce()
              }}
              reason={messageTipTemplate.introPerson.reason}
              example={messageTipTemplate.introPerson.example}
              placeholder={`메시지를 보내는 사람이 누구인지 인재에게 알려주세요\n(다음에도 사용하실 수 있도록 저장해드려요)`}
              onFocus={() => proposalStore.setFocusInputIndex(1)}
              onBlur={() =>
                setTimeout(
                  () =>
                    proposalStore.isIntroPersonActive &&
                    proposalStore.setFocusInputIndex(0),
                  100,
                )
              }
            />
          </Section>
        ) : (
          <ProposalMessageViewer
            name={proposalStore.request.name}
            message={proposalStore.temp.introPerson}
            isActiveInput={isActiveInput}
            onClick={() => {
              proposalStore.setFocusInputIndex(1)
            }}
            onChange={(v) => {
              proposalStore.setIntroPerson(v)
              proposalStore.setIsLoading(true)
              saveDebounce()
            }}
          />
        )}
        <Column style={{ gap: 8 }}>
          <AutoMessageContainer isDisabled={autoProposalDisabled || !isDesktop}>
            {autoProposalMessage && autoProposalMessage.isLoading ? (
              <AutoMessageTextContainer>
                <AutoMessageText>
                  {`${proposalStore.request.name}님에게 딱 맞는 메시지를 생성중입니다. 다음 단계를 먼저 진행하거나 모달을 닫고 다른 프로필을 보셔도 괜찮아요.`}
                </AutoMessageText>
                <LoadingSpinnerIcon style={{ flex: 'none' }} />
              </AutoMessageTextContainer>
            ) : (
              <>
                {proposalStore.isProposeReasonActive ||
                !proposalStore.temp.proposeReason ? (
                  <Section style={{ zIndex: 1 }}>
                    <SectionTitleContainer>
                      <SubText focus={proposalStore.isProposeReasonActive}>
                        {proposalStore.request.name}님에게 관심이 생긴 이유
                      </SubText>
                      {proposalStore.isProposeReasonActive && (
                        <VariableAddingButton
                          onClick={() => {
                            proposalStore.setProposeReason(
                              getVariableInsertedMsg(
                                proposalStore.temp.proposeReason,
                              ),
                            )
                            proposalStore.setIsLoading(true)
                            saveDebounce()
                          }}
                        />
                      )}
                    </SectionTitleContainer>
                    <ProposalMessageInput
                      activeInputRef={
                        proposalStore.isProposeReasonActive
                          ? activeInputRef
                          : undefined
                      }
                      autoFocus={proposalStore.isProposeReasonActive}
                      value={proposalStore.temp.proposeReason}
                      onChange={(e) => {
                        proposalStore.setProposeReason(e.target.value)
                        proposalStore.setIsLoading(true)
                        saveDebounce()
                      }}
                      reason={messageTipTemplate.proposalReason.reason}
                      example={messageTipTemplate.proposalReason.example}
                      placeholder='인재의 어떤 점 때문에 관심이 있어서 메시지를 보내게 되었는지 알려주세요'
                      onFocus={() => proposalStore.setFocusInputIndex(2)}
                      onBlur={() =>
                        setTimeout(
                          () =>
                            proposalStore.isProposeReasonActive &&
                            proposalStore.setFocusInputIndex(0),
                          100,
                        )
                      }
                    />
                  </Section>
                ) : (
                  <ProposalMessageViewer
                    name={proposalStore.request.name}
                    message={proposalStore.temp.proposeReason}
                    isActiveInput={isActiveInput}
                    onClick={() => {
                      proposalStore.setFocusInputIndex(2)
                    }}
                    onChange={(v) => {
                      proposalStore.setProposeReason(v)
                      proposalStore.setIsLoading(true)
                      saveDebounce()
                    }}
                  />
                )}
                {proposalStore.isAppealPointsActive ||
                !proposalStore.temp.appealPoints ? (
                  <Section
                    style={{
                      zIndex: proposalStore.isProposeReasonActive ? 0 : 1,
                    }}
                  >
                    <SectionTitleContainer>
                      <SubText focus={proposalStore.isAppealPointsActive}>
                        {proposalStore.request.name}님이 우리 회사와 채용에
                        관심을 가질 만한 이유
                      </SubText>
                      {proposalStore.isAppealPointsActive && (
                        <VariableAddingButton
                          onClick={() => {
                            proposalStore.setAppealPoints(
                              getVariableInsertedMsg(
                                proposalStore.temp.appealPoints,
                              ),
                            )
                            proposalStore.setIsLoading(true)
                            saveDebounce()
                          }}
                        />
                      )}
                    </SectionTitleContainer>
                    <ProposalMessageInput
                      activeInputRef={
                        proposalStore.isAppealPointsActive
                          ? activeInputRef
                          : undefined
                      }
                      autoFocus={proposalStore.isAppealPointsActive}
                      value={proposalStore.temp.appealPoints}
                      onChange={(e) => {
                        proposalStore.setAppealPoints(e.target.value)
                        proposalStore.setIsLoading(true)
                        saveDebounce()
                      }}
                      reason={messageTipTemplate.appealPoints.reason}
                      example={messageTipTemplate.appealPoints.example}
                      placeholder={`인재가 스타트업에 기대하는 점을 고려한 우리 기업의 매력을 어필해 주세요\n단 전화번호, 이메일, 링크는 메시지에 작성할 수 없어요`}
                      onFocus={() => proposalStore.setFocusInputIndex(3)}
                      onBlur={() =>
                        setTimeout(
                          () =>
                            proposalStore.isAppealPointsActive &&
                            proposalStore.setFocusInputIndex(0),
                          100,
                        )
                      }
                    />
                  </Section>
                ) : (
                  <ProposalMessageViewer
                    name={proposalStore.request.name}
                    message={proposalStore.temp.appealPoints}
                    isActiveInput={isActiveInput}
                    onClick={() => {
                      proposalStore.setFocusInputIndex(3)
                    }}
                    onChange={(v) => {
                      proposalStore.setAppealPoints(v)
                      proposalStore.setIsLoading(true)
                      saveDebounce()
                    }}
                  />
                )}
              </>
            )}
          </AutoMessageContainer>
          <AutoMessageBtn isDisabled={autoProposalDisabled || !isDesktop}>
            <Badge
              filled
              color={BadgeColor.SECONDARY}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                autoProposalMessageStore.requestAutoMessage(
                  proposalStore.request.id,
                  proposalStore.request.name,
                )
              }
            >
              <Row style={{ gap: 8 }}>
                <BsStars size={16} />
                AI 메시지 자동 생성하기
              </Row>
            </Badge>
          </AutoMessageBtn>
        </Column>
        {proposalStore.isOutroActive || !proposalStore.temp.outro ? (
          <Section>
            <SectionTitleContainer>
              <SubText focus={proposalStore.isOutroActive}>
                채팅 수락 요청
              </SubText>
              {proposalStore.isOutroActive && (
                <VariableAddingButton
                  onClick={() => {
                    proposalStore.setOutro(
                      getVariableInsertedMsg(proposalStore.temp.outro),
                    )
                    proposalStore.setIsLoading(true)
                    saveDebounce()
                  }}
                />
              )}
            </SectionTitleContainer>
            <ProposalMessageInput
              activeInputRef={
                proposalStore.isOutroActive ? activeInputRef : undefined
              }
              autoFocus={proposalStore.isOutroActive}
              value={proposalStore.temp.outro}
              onChange={(e) => {
                proposalStore.setOutro(e.target.value)
                proposalStore.setIsLoading(true)
                saveDebounce()
              }}
              reason={messageTipTemplate.outro.reason}
              example={messageTipTemplate.outro.example}
              placeholder={`인재와 어떤 대화를 원하는지 알려주면서, 정중한 인사로 메시지를 마쳐보세요\n(다음에도 사용하실 수 있도록 저장해드려요)`}
              onFocus={() => proposalStore.setFocusInputIndex(4)}
              onBlur={() =>
                setTimeout(
                  () =>
                    proposalStore.isOutroActive &&
                    proposalStore.setFocusInputIndex(0),
                  100,
                )
              }
            />
          </Section>
        ) : (
          <ProposalMessageViewer
            name={proposalStore.request.name}
            message={proposalStore.temp.outro}
            isActiveInput={isActiveInput}
            onClick={() => {
              proposalStore.setFocusInputIndex(4)
            }}
            onChange={(v) => {
              proposalStore.setOutro(v)
              proposalStore.setIsLoading(true)
              saveDebounce()
            }}
          />
        )}
      </CardContainer>
    </Column>
  )
})

const AutoMessageText = styled(DescText)`
  color: ${(p) => p.theme.color.gray1};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
`

const AutoMessageContainer = styled(Column)<{ isDisabled: boolean }>`
  gap: 16px;
  border-radius: 8px;
  ${(p) => !p.isDisabled && `padding: 8px`};
  border: ${(p) =>
    p.isDisabled ? undefined : `1.5px solid ${p.theme.color.secondary};`};
`

const AutoMessageBtn = styled(Row)<{ isDisabled: boolean }>`
  display: ${(p) => (p.isDisabled ? 'none' : 'flex')};
  justify-content: flex-end;
`

const CardContainer = styled(Card)`
  position: relative;
  gap: 16px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    padding: 16px;
    background-color: ${(p) => p.theme.color.background};
  }
`

const InfoText = styled(BaseText)`
  font-size: 12px;
  color: ${(p) => p.theme.color.black3};
  line-height: 1.4;
`

const GuideBalloon = styled(Balloon)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  left: 50%;
  cursor: pointer;
  @media ${(p) => p.theme.deviceSize.desktop} {
    animation: guideBalloonSlideDesktop 0.8s ease-in-out infinite;
    @keyframes guideBalloonSlideDesktop {
      from {
        top: 0;
      }
      50% {
        top: -5px;
      }
      to {
        top: 0;
      }
    }
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    top: -20px;
    white-space: nowrap;
    animation: guideBalloonSlideMobile 0.8s ease-in-out infinite;
    @keyframes guideBalloonSlideMobile {
      from {
        top: -20px;
      }
      50% {
        top: -25px;
      }
      to {
        top: -20px;
      }
    }
  }
`

const Section = styled(Column)`
  gap: 8px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    padding: 8px;
  }
`

const SectionTitleContainer = styled(Row)`
  justify-content: space-between;
  gap: 4px;
`

const BringMessageText = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.small};
  color: ${(p) => p.theme.color.gray1};
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
`

const AutoMessageTextContainer = styled(Row)`
  gap: 24px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    padding: 16px;
  }
`

const MainTextWrapper = styled(Row)`
  gap: 16px;
  justify-content: space-between;
`

const RefreshBtn = styled(Tag)`
  gap: 8px;
  display: flex;
  border-radius: 100px;
  align-items: center;
  cursor: pointer;
`

const InfoContainer = styled(Row)`
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.color.background};
`
