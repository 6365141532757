import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { SemiBoldText } from 'components/common/text'
import { CheckBox } from 'components/common/check-box'
import { ProposalStore } from 'stores/scouts/proposal'
import { observer } from 'mobx-react'
import { useDashboardScoutPositions } from 'apis/hooks'
import { analytics } from 'infra/analytics'
import { Card } from 'components/common/card'
import { textEllipsis } from 'components/common/styles'

export const PositionSelector: React.FC<{ proposalStore: ProposalStore }> =
  observer(({ proposalStore }) => {
    const { data: positions } = useDashboardScoutPositions(
      proposalStore.request.id,
    )
    if (!positions) return null
    return (
      <>
        <Grid style={{ gap: 16 }}>
          {positions.map((item) => (
            <PositionCard
              key={item.id}
              onClick={() => {
                proposalStore.togglePosition(item.id)
                analytics.track('click_dsp_position', {
                  positionId: item.id,
                  positionName: item.name,
                })
              }}
            >
              <Row
                style={{
                  gap: 8,
                  justifyContent: 'space-between',
                }}
              >
                <NameText>
                  {item.name} · {item.careerType}
                </NameText>
                <CheckBox
                  onClick={() => null} // 상위에서 클릭함
                  checked={proposalStore.temp.positions.includes(item.id)}
                />
              </Row>
            </PositionCard>
          ))}
        </Grid>
      </>
    )
  })

const NameText = styled(SemiBoldText)`
  ${textEllipsis}
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media ${(p) => p.theme.deviceSize.mobile} {
    grid-template-columns: 1fr;
  }
`

const PositionCard = styled(Card)`
  border-radius: 8px;
  padding: 16px;
  gap: 8px;
  overflow: hidden;
  justify-content: flex-start;
  background-color: ${(p) => p.theme.color.background2};
  cursor: pointer;
`
