import { makeAutoObservable } from 'mobx'

export class StartupModalStore {
  isOpen: boolean = false
  id: number = 0
  constructor() {
    makeAutoObservable(this)
  }
  openModal(id: number) {
    this.isOpen = true
    this.id = id
  }

  closeModal() {
    this.isOpen = false
  }
}
