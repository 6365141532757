import styled from 'styled-components'
import { FiX } from 'react-icons/fi'
import { TbTrash } from 'react-icons/tb'
import { IdpCertificate } from 'types/common'
import Image from 'next/legacy/image'
import { Row } from 'components/common/layout'
import { useIsDesktopCSR } from 'utils/hooks'

const IconBox = styled.div`
  padding: 5px;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.color.line};
  background-color: ${(p) => p.theme.color.white1};
  cursor: pointer;
`

export const TrashIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <IconBox onClick={onClick}>
    <TbTrash size={20} />
  </IconBox>
)

export const XIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <IconBox onClick={onClick}>
    <FiX size={20} />
  </IconBox>
)

export const CertificateIcon: React.FC<{ data: IdpCertificate }> = ({
  data,
}) => {
  const isDesktop = useIsDesktopCSR()
  return (
    <BadgeBox>
      <Image
        width={isDesktop ? 48 : 44}
        height={isDesktop ? 48 : 44}
        alt={data.name}
        src={data.imageUrl}
        priority
      />
    </BadgeBox>
  )
}

const BadgeBox = styled(Row)`
  overflow: hidden;
  width: fit-content;
  border-radius: 16px;
  box-shadow: 0px 1px 8px rgba(161, 201, 255, 0.24);
  cursor: pointer;
`
