import styled from 'styled-components'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { BaseText, MediumText, SemiBoldText } from 'components/common/text'
import { Tag, TagSize, TagType } from 'components/common/tag'
import { Column } from 'components/common/layout'
import { TextArea } from 'components/common/input'

export const PositionApplicationFormContainer = styled(Column)`
  gap: 24px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    flex: 1;
    justify-content: space-between;
  }
`

export const PositionApplicationStepTitleText = styled(SemiBoldText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
  font-size: ${(p) => p.theme.fontSize.desc};
`

export const PositionApplicationStepSubText = styled(BaseText)`
  font-size: ${(p) => p.theme.fontSize.desc};
  span {
    font-weight: ${(p) => p.theme.fontWeight.bold};
  }
`

export const PositionApplicationNextBtn = styled(Button).attrs({
  size: BtnSize.MEDIUM,
  variant: BtnType.PRIMARY2,
})``

export const PositionApplicationSubmitBtn = styled(Button).attrs({
  size: BtnSize.MEDIUM,
  variant: BtnType.LINEAR,
})``

export const PositionApplicationPrevBtn = styled(Button).attrs({
  size: BtnSize.MEDIUM,
  variant: BtnType.SUPER_LOW,
})``

export const PositionApplicationCharCountText = styled(MediumText)`
  width: 60px;
  text-align: end;
  font-size: ${(p) => p.theme.fontSize.small};
  color: ${(p) => p.theme.color.gray2};
`

export const PositionApplicationNoticeText = styled(MediumText)`
  color: ${(p) => p.theme.color.black3};
  text-align: center;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
    letter-spacing: -0.8px;
  }
  span {
    color: ${(p) => p.theme.color.red};
  }
`

export const PositionApplicationTempSavingTag = styled(Tag).attrs({
  variant: TagType.LIGHT_GRAY,
  size: TagSize.SMALL,
})`
  padding: 2px 8px;
  border-radius: 100px;
`

export const PositionApplicationTextArea = styled(TextArea)`
  height: 100%;
  @media ${(p) => p.theme.deviceSize.mobile} {
    height: 140px;
  }
`

export const PositionApplicationTextAreaContainer = styled(Column)`
  gap: 8px;
  align-items: end;
  height: 100%;
`

export const PositionApplicationFitStepSectionText = styled(SemiBoldText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.desc};
  }
`
