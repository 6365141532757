import { Envelope } from 'types/common'

export class ApiError extends Error {
  status: number
  constructor(status: number, message?: string) {
    super(message)
    this.status = status
  }
}

export const filterError = async (res: Response) => {
  // return if res is ok
  if (res.ok) return res
  // check if body exists
  let jsonBody: Envelope
  try {
    jsonBody = await res.json()
  } catch (e) {
    // 1) json body doesn't exist
    // NOTE(gogo): consider sending the error to bugsnag
    throw new ApiError(res.status)
  }
  // 2) json body does exist
  throw new ApiError(jsonBody.status, jsonBody.msg || undefined)
}
