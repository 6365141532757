import React from 'react'
import { LanguageSkill } from 'types/common'
import { Column, Row } from 'components/common/layout'
import { useIsDesktopCSR } from 'utils/hooks'
import { Tag } from 'components/common/tag'
import {
  ProfileDetailDescriptionText,
  ProfileDetailLanguageContainer,
  ProfileDetailLanguageNameText,
} from 'components/scouts/profile-detail/styles'

export const LanguageSkillDetail: React.FC<{
  data: LanguageSkill
  isNarrow?: boolean
}> = ({ data, isNarrow }) => {
  const isDesktop = useIsDesktopCSR()
  const isMobileOrNarrow = isNarrow || !isDesktop
  return (
    <ProfileDetailLanguageContainer>
      <Row style={{ alignItems: 'start' }}>
        <ProfileDetailLanguageNameText
          isNarrow={isMobileOrNarrow}
          dangerouslySetInnerHTML={{ __html: data.language.name }}
        />
        <Column style={{ gap: 10 }}>
          <Tag
            style={{ width: 'fit-content' }}
            dangerouslySetInnerHTML={{ __html: data.level.name }}
          />
          {!isMobileOrNarrow && data.description && (
            <ProfileDetailDescriptionText
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          )}
        </Column>
      </Row>
      {isMobileOrNarrow && data.description && (
        <ProfileDetailDescriptionText
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      )}
    </ProfileDetailLanguageContainer>
  )
}
