import React from 'react'
import styled from 'styled-components'
import { Logo } from 'components/common/logo'
import { Column, Row } from 'components/common/layout'
import { BaseText, MediumText } from 'components/common/text'
import { EducationHistory } from 'types/common'
import { formatEducationPeriod } from 'utils/displays'
import { IsCsTag } from 'components/scouts/common/user-info-tag'

export const DevEducation: React.FC<{
  education: EducationHistory
  isNarrow?: boolean
}> = ({ education, isNarrow }) => {
  const imageSize = isNarrow ? 48 : 56
  const educationPeriod = formatEducationPeriod(
    education.admissionYear,
    education.graduationYear,
  )
  return (
    <Row style={{ gap: 12 }}>
      <Logo
        size={imageSize}
        src={education.school.image}
        placeholder='/college-placeholder.svg'
      />
      <Column style={{ gap: 4, flex: 1 }}>
        <SpaceBetween isNarrow={isNarrow}>
          <Column style={{ gap: 4 }}>
            <Row style={{ gap: 4 }}>
              <MediumText
                dangerouslySetInnerHTML={{ __html: education.school.name }}
              />
              {education.category && (
                <BaseText
                  dangerouslySetInnerHTML={{ __html: education.category.name }}
                />
              )}
            </Row>
            <Row style={{ gap: 4, flexWrap: 'wrap' }}>
              <BaseText dangerouslySetInnerHTML={{ __html: education.major }} />
              {education.isCs && <IsCsTag />}
            </Row>
          </Column>
          <Row style={{ gap: 4, alignItems: 'flex-end' }}>
            {educationPeriod && <GrayText>{educationPeriod} ‧ </GrayText>}
            <GrayText>{education.status?.name}</GrayText>
          </Row>
        </SpaceBetween>
      </Column>
    </Row>
  )
}

const SpaceBetween = styled(Row)<{ isNarrow?: boolean }>`
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  ${(p) => p.isNarrow && `flex-direction: column; gap: 4px;`}
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 4px;
    flex-direction: column;
  }
`

const GrayText = styled(BaseText)`
  color: ${(p) => p.theme.color.gray2};
  flex: none;
`
