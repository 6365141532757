import React, { useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { PositionApplicationStore } from 'stores/position-application'
import { Column, Row } from 'components/common/layout'
import {
  PositionApplicationNoticeText,
  PositionApplicationPrevBtn,
  PositionApplicationNextBtn,
  PositionApplicationFormContainer,
  PositionApplicationSubmitBtn,
} from 'containers/positions/application/styles'
import { PositionApplicationFulfillment } from 'containers/positions/application/fulfillment-form'
import { PositionApplicationFitReason } from 'containers/positions/application/fit-reason-form'
import { getPositionApplicationPreviewSkipStorage } from 'utils/storages'
import {
  hasFitApplicationFulfillment,
  hasFitApplicationFulfillmentDescription,
} from 'utils/validations'
import { useStores } from 'stores/globals'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { BaseText, SemiBoldText } from 'components/common/text'
import { theme } from 'infra/theme'
import { calcDateBefore } from 'utils/displays'
import { useTempApplication } from 'apis/hooks'
import { FiCheck } from 'react-icons/fi'
import { Balloon, BalloonType } from 'components/common/balloon'
import { TooltipIcon } from 'components/common/icon'
import { BoldText } from 'components/common/text'
import { PositionApplicationStepTitleText } from 'containers/positions/application/styles'
import Tippy from '@tippyjs/react'
import { calculatePositionFulfillmentScore } from 'utils/formatters'

export const PositionApplicationFitReasonStep: React.FC<{
  store: PositionApplicationStore
}> = observer(({ store }) => {
  const { positionListStore } = useStores()
  const { data: tempApplication } = useTempApplication(
    store.positionDetail?.id || 0,
  )
  const isSkipPreview = getPositionApplicationPreviewSkipStorage()
  const hasUnwrittenItems =
    !store.joinReason?.trim() ||
    !hasFitApplicationFulfillment(store.fulfillments) ||
    !hasFitApplicationFulfillmentDescription(store.fulfillments)
  const score = calculatePositionFulfillmentScore(store.fulfillments)
  const isActive = score >= 50

  const scoreColor = isActive
    ? theme.color.primary
    : score > 0
    ? theme.color.gray2
    : theme.color.alert

  const apply = async () => {
    try {
      if (hasUnwrittenItems) return store.setIsOpenMessageCheckModal(true)
      if (!confirm(store.applicationAlert)) return
      await store.apply(!!store.positionDetail?.userApplication.isPossibleApply)
      positionListStore.mutatePositionList()
      store.setIsOpenDoneModal(true)
    } catch (e) {
      alert(e)
    }
  }

  const updateFulfillments = async () => {
    if (!store.positionDetail?.id) return
    if (
      !confirm(
        '기존에 작성했던 채용 공고 적합도 내용이 사라져요.\n그래도 진행하시겠어요?',
      )
    )
      return
    await store.updateFulfillmentsAndSetTemp()
  }

  useEffect(() => {
    store.setCurTab(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {tempApplication?.hasNewConditions && (
        <ToastContainer>
          <CustomToast>
            <Column style={{ gap: 4 }}>
              <BaseText style={{ fontSize: 14, color: theme.color.black2 }}>
                {calcDateBefore(store.positionDetail?.updatedAt || '')}에 채용
                공고가 수정되었어요
              </BaseText>
              <SemiBoldText>변경된 채용 공고로 작성하시겠어요?</SemiBoldText>
            </Column>
            <UpdateBtn
              size={BtnSize.SMALL}
              variant={BtnType.PRIMARY2}
              onClick={updateFulfillments}
            >
              변경
            </UpdateBtn>
          </CustomToast>
        </ToastContainer>
      )}
      <PositionApplicationFormContainer>
        <Column style={{ gap: 16 }}>
          <Header>
            <TitleWrapper>
              <PositionApplicationStepTitleText>
                채용 공고 적합도
              </PositionApplicationStepTitleText>
              <Tippy
                trigger='click'
                content={
                  <Balloon variant={BalloonType.LOW_BLACK}>
                    기업의 채용 조건에 부합하는 본인의 강점을 어필해요
                  </Balloon>
                }
              >
                <Row style={{ cursor: 'pointer' }}>
                  <TooltipIcon />
                </Row>
              </Tippy>
            </TitleWrapper>
            <Row style={{ gap: 4 }}>
              <Circle style={{ backgroundColor: scoreColor }} size={18}>
                <FiCheck size={12} strokeWidth={4} color={theme.color.white1} />
              </Circle>
              <ScoreText style={{ color: scoreColor }}>{score}%</ScoreText>
            </Row>
          </Header>
          <Container>
            <PositionApplicationFulfillment store={store} />
            <PositionApplicationFitReason store={store} />
          </Container>
        </Column>
        <Column style={{ gap: 8, textAlign: 'center' }}>
          <PositionApplicationNoticeText>
            성의 있는 메시지로 서류 합격 가능성을 최대 <span>6배</span>{' '}
            높여보세요!
          </PositionApplicationNoticeText>
          <Row style={{ gap: 8 }}>
            <PositionApplicationPrevBtn onClick={() => store.movePrevStep()}>
              이전
            </PositionApplicationPrevBtn>
            {isSkipPreview ? (
              <PositionApplicationSubmitBtn onClick={apply}>
                지원하기
              </PositionApplicationSubmitBtn>
            ) : (
              <PositionApplicationNextBtn
                onClick={() => {
                  if (hasUnwrittenItems)
                    return store.setIsOpenMessageCheckModal(true)
                  store.setIsOpenPreviewModal(true)
                }}
              >
                {!!store.fitReason.trim() ||
                hasFitApplicationFulfillment(store.fulfillments) ||
                hasFitApplicationFulfillmentDescription(store.fulfillments)
                  ? '다음'
                  : '건너뛰기'}
              </PositionApplicationNextBtn>
            )}
          </Row>
        </Column>
      </PositionApplicationFormContainer>
    </>
  )
})

const Container = styled(Column)`
  gap: 40px;
  height: calc(100vh - 410px);
  max-height: 600px;
  justify-content: start;
  overflow: hidden scroll;
  @media ${(p) => p.theme.deviceSize.mobile} {
    height: 250px;
  }
`

const ToastContainer = styled(Row)`
  top: 60px;
  left: 0px;
  width: 100%;
  position: fixed;
  justify-content: center;
`
const CustomToast = styled(Row)`
  gap: 40px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #ffeaee;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
`

const UpdateBtn = styled(Button)`
  width: fit-content;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
`

const ScoreText = styled(BoldText)`
  font-size: ${(p) => p.theme.fontSize.desc};
`

const TitleWrapper = styled(Row)`
  gap: 8px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 16px;
  }
`

const Circle = styled(Row)<{ size: number }>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  justify-content: center;
  border-radius: 100px;
`

const Header = styled(Row)`
  top: 0;
  position: sticky;
  justify-content: space-between;
`
