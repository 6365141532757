import React from 'react'
import styled from 'styled-components'
import { ChatIcon } from 'components/common/icon'
import { useDashboardScoutsSummary } from 'apis/hooks'
import { AlertBadge } from 'components/common/badge'
import { useRouter } from 'next/router'
import { useIsDesktopCSR } from 'utils/hooks'
import { Row } from 'components/common/layout'

export const Chat: React.FC = () => {
  const router = useRouter()
  const isDesktop = useIsDesktopCSR()
  const { data: summary } = useDashboardScoutsSummary()
  return (
    <Container onClick={() => router.push('/dashboard/chat-rooms')}>
      {summary && summary.unreadMessagesCount !== 0 && (
        <AlertBadge
          size={isDesktop ? 20 : 10}
          top={isDesktop ? -4 : -2}
          right={isDesktop ? -6 : -3}
        >
          {isDesktop && summary.unreadMessagesCount}
        </AlertBadge>
      )}
      <ChatIcon strokeWidth={0.2} size={isDesktop ? 28 : 20} />
    </Container>
  )
}

const Container = styled(Row)`
  position: relative;
  cursor: pointer;
`
