import React, { useEffect } from 'react'
import { Modal } from 'components/common/modal'
import { useStores } from 'stores/globals'
import { observer } from 'mobx-react'
import { useScoutMyRequest } from 'apis/hooks'
import {
  ButtonContainer,
  CheckList,
  CheckListTitle,
  CheckListWrapper,
  MainButton,
  MainContent,
  ModalContent,
  ModalTitle,
  SubButton,
} from 'containers/positions/check-modal'

export const ProfileNotFilledModal: React.FC = observer(() => {
  const { applyModalStore } = useStores()
  const { data: profile } = useScoutMyRequest()
  const checkItemList = [
    { name: '학력', isChecked: !!profile?.educations.length },
    {
      name: '직무 경험',
      isChecked: !!profile?.careers.length || !!profile?.otherActivities.length,
    },
  ]
  useEffect(() => {
    if (
      !!profile?.educations.length &&
      (!!profile?.careers.length || !!profile?.otherActivities.length)
    )
      applyModalStore.setIsProfileNotFilledModalOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])
  return (
    <Modal width={340} disableCloseBtn>
      <ModalContent>
        <MainContent>
          <ModalTitle>프로필 필수 항목을 완성해야 지원할 수 있어요</ModalTitle>
          <CheckListWrapper>
            <CheckListTitle>프로필 필수 항목</CheckListTitle>
            <CheckList data={checkItemList} />
          </CheckListWrapper>
        </MainContent>
        <ButtonContainer>
          <SubButton
            onClick={() => {
              applyModalStore.setIsProfileNotFilledModalOpen(false)
              if (!profile) return // 구직조건 선택조차 하지 않아, profile 값이 null인 경우 토스하기로 넘기지 않음
              if (profile.hasRequestedInfoFill && !profile.hasRejectedInfoFill)
                return // 이미 토스하기 요청한 경우 넘기지 않음
              applyModalStore.setIsProfileFillRequestModalOpen(true)
            }}
          >
            나중에 하기
          </SubButton>
          <MainButton
            onClick={() => window.open('/scouts/my-profile', '_blank')}
          >
            프로필 완성하러 가기
          </MainButton>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  )
})
