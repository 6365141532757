import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import {
  PositionApplicationCharCountText,
  PositionApplicationNoticeText,
  PositionApplicationPrevBtn,
  PositionApplicationStepSubText,
  PositionApplicationStepTitleText,
  PositionApplicationNextBtn,
  PositionApplicationTempSavingTag,
  PositionApplicationFormContainer,
  PositionApplicationTextArea,
  PositionApplicationTextAreaContainer,
} from 'containers/positions/application/styles'
import { Column, Row } from 'components/common/layout'
import { PositionApplicationStore } from 'stores/position-application'
import { useDebounce, useIsDesktopCSR } from 'utils/hooks'
import { putStartupPositionApplicationTemp } from 'apis/request'

export const PositionApplicationJoinReasonStep: React.FC<{
  store: PositionApplicationStore
}> = observer(({ store }) => {
  const isDesktop = useIsDesktopCSR()
  const [isSavingTemp, setIsSavingTemp] = useState(false)
  const saveTempJoinReason = useDebounce(1000, async (v: string) => {
    await putStartupPositionApplicationTemp(store.positionDetail?.id || 0, {
      joinReason: v,
    })
    setIsSavingTemp(false)
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => store.setCurTab(1), [])
  return (
    <PositionApplicationFormContainer>
      <Column style={{ gap: 16, height: '100%' }}>
        <Column style={{ gap: 4 }}>
          {!isDesktop ? (
            <PositionApplicationStepSubText>
              <span>{store.startupDetail?.name}</span>에{' '}
              <span>합류하고 싶은 이유</span>
            </PositionApplicationStepSubText>
          ) : (
            <PositionApplicationStepTitleText>
              합류하고 싶은 이유를 작성해주세요
            </PositionApplicationStepTitleText>
          )}
        </Column>
        <PositionApplicationTextAreaContainer>
          <PositionApplicationTextArea
            value={store.joinReason || ''}
            placeholder={`‧ 채용 공고의 어떤 부분이 매력적으로 느껴졌는지 알려주세요\n‧ ${store.startupDetail?.name}에 관심을 가지게 된 이유를 작성해도 좋아요`}
            onChange={(e) => {
              if (e.target.value.length > 200) return
              setIsSavingTemp(true)
              store.setJoinReason(e.target.value)
              saveTempJoinReason(e.target.value)
            }}
          />
          <Row style={{ height: 30 }}>
            {isSavingTemp && (
              <PositionApplicationTempSavingTag>
                임시 저장 중
              </PositionApplicationTempSavingTag>
            )}
            <PositionApplicationCharCountText>
              {store.joinReason?.length || 0}/200
            </PositionApplicationCharCountText>
          </Row>
        </PositionApplicationTextAreaContainer>
      </Column>
      <Column style={{ gap: 8, textAlign: 'center' }}>
        <PositionApplicationNoticeText>
          성의 있는 메시지로 서류 합격 가능성을 최대 <span>6배</span>{' '}
          높여보세요!
        </PositionApplicationNoticeText>
        <Row style={{ gap: 8 }}>
          <PositionApplicationPrevBtn onClick={() => store.movePrevStep()}>
            이전
          </PositionApplicationPrevBtn>
          <PositionApplicationNextBtn onClick={() => store.moveNextStep()}>
            {store.joinReason ? '다음' : '건너뛰기'}
          </PositionApplicationNextBtn>
        </Row>
      </Column>
    </PositionApplicationFormContainer>
  )
})
