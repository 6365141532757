import styled from 'styled-components'
import { BaseText, SemiBoldText } from 'components/common/text'
import { BoldText } from 'components/common/text'
import { Column } from 'components/common/layout'

export const SectionTitle = styled(SemiBoldText)`
  margin-bottom: 8px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h3};
    font-weight: ${(p) => p.theme.fontWeight.bold};
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h2};
  }
`

export const SectionDesc = styled(BaseText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
  color: ${(p) => p.theme.color.black3};
  margin-bottom: 16px;
`

export const SubSectionTitle = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.h3};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h4};
  }
`

export const SectionPaper = styled(Column)`
  padding: 32px;
  gap: 30px;
  background-color: #f4f6f6;
  border-radius: 32px;
`

export const SubSectionWrapper = styled(Column)`
  gap: 8px;
`

export const NotFoundContent = styled(BoldText)`
  width: 100%;
  text-align: center;
  color: ${(p) => p.theme.color.gray2};
  font-size: ${(p) => p.theme.fontSize.h5};
`
