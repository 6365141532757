import { DashboardHeaderBanner } from 'components/dashboard/header-banner'
import { Row } from 'components/common/layout'
import { Chat } from 'components/misc/header/dashboard/dashboard-chat'
import { DashboardDropdownMenu } from 'components/misc/header/dashboard/dashboard-dropdown-menu'
import { DashboardHeaderTabMenu } from 'components/misc/header/dashboard/dashboard-header-tab-menu'
import { DashboardLogo } from 'components/misc/header/dashboard/dashboard-logo'
import { DashboardMobileHeaderTabs } from 'components/misc/header/dashboard/dashboard-mobile-header-tabs'
import { HeaderLogo } from 'components/misc/header/header-logo'
import {
  DashboardContentContainer,
  MainContainer,
} from 'components/misc/header/styles'
import { TriggerExceptTarget } from 'components/misc/trigger'
import React, { Suspense, useState } from 'react'
import { FiUser } from 'react-icons/fi'
import styled from 'styled-components'
import { useIsDesktopCSR } from 'utils/hooks'

export const DashboardHeader: React.FC = () => {
  const isDesktop = useIsDesktopCSR()
  return (
    <MainContainer>
      <DashboardHeaderBanner />
      <DashboardContentContainer>
        <Row style={{ gap: 16 }}>
          <HeaderLogo />
          <DashboardLogo />
        </Row>
        {isDesktop ? (
          <Row style={{ justifyContent: 'space-between', flex: 1 }}>
            <DashboardHeaderTabMenu />
            <Row style={{ gap: 16 }}>
              <Chat />
              <Avatar />
            </Row>
          </Row>
        ) : (
          <DashboardMobileHeaderTabs />
        )}
      </DashboardContentContainer>
    </MainContainer>
  )
}

const Avatar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <TriggerExceptTarget onTrigger={() => setIsOpen(false)}>
      <AvatarWrapper onClick={() => setIsOpen(!isOpen)}>
        <FiUser size={24} />
        <Suspense>{isOpen && <DashboardDropdownMenu />}</Suspense>
      </AvatarWrapper>
    </TriggerExceptTarget>
  )
}

const AvatarWrapper = styled(Row)`
  gap: 5px;
  position: relative;
  cursor: pointer;
`
