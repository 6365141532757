import { Column } from 'components/common/layout'
import { H5Text } from 'components/common/text'
import { KakaoLoginBtn } from 'components/misc/login'
import React from 'react'
import styled from 'styled-components'
import { useIsLoggedIn } from 'utils/hooks'

export const LoginBlurCta: React.FCC = ({ children }) => {
  const isLoggedIn = useIsLoggedIn()

  return (
    <>
      {isLoggedIn ? (
        children
      ) : (
        <Blur>
          {children}
          {!isLoggedIn && (
            <Container className='loginOnBlur'>
              <Title>이 스타트업의 채용설명회가 궁금하다면?</Title>
              <KakaoLoginBtn />
            </Container>
          )}
        </Blur>
      )}
    </>
  )
}
const Container = styled(Column)`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  @media (max-width: 680px) {
    width: 80%;
  }
`

const Title = styled(H5Text)`
  margin-bottom: 20px;
  text-align: center;
  font-weight: ${(p) => p.theme.fontWeight.medium};
  @media (max-width: 680px) {
    margin-bottom: 10px;
    font-size: ${(p) => p.theme.fontSize.default};
  }
`

const Blur = styled.div`
  position: relative;
  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.8);
  }
`
