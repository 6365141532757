import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { BoldText } from 'components/common/text'
import { observer } from 'mobx-react'
import { analytics } from 'infra/analytics'
import { useRouter } from 'next/router'

const STARTUP_DETAIL_TABS = [
  { name: 'effortForDev', label: '팀원을 위한 노력' },
  { name: 'solvingProblem', label: '해결하는 문제' },
  { name: 'startupStory', label: '창업 스토리' },
  { name: 'benefit', label: '보상 및 복지' },
  { name: 'reasonForInvesting', label: '투자받은 이유' },
  { name: 'team', label: '팀' },
  { name: 'presentation', label: '채용설명회' },
  { name: 'location', label: '근무 위치' },
]

const SCROLL_MARGIN = 140

export const StartupTab: React.FC<{ isModal?: boolean }> = observer(
  ({ isModal }) => {
    const router = useRouter()
    const [selectedTab, setSelectedTab] = useState(STARTUP_DETAIL_TABS[0].name)
    const [tabs, setTabs] = useState(STARTUP_DETAIL_TABS)
    const isSelected = (name: string) => selectedTab === name

    const onScroll = () => {
      const scrollY = isModal
        ? document.getElementById('modal-content')?.scrollTop || 0
        : window.scrollY
      const currScroll = Math.ceil(scrollY) + SCROLL_MARGIN

      for (let i = 0; i < tabs.length; i++) {
        // i가 tabs의 마지막 인덱스에 해당하면 마지막 섹션 선택
        if (i === tabs.length - 1) {
          setSelectedTab(tabs[i].name)
          return
        }
        // 다음 섹션의 위치를 계산
        const offsetTop =
          document.getElementById(tabs[i + 1].name)?.offsetTop || 0
        // 현재 스크롤 위치가 다음 섹션 위치보다 작으면 지금 섹션의 탭이 선택됨
        if (offsetTop > currScroll) {
          setSelectedTab(tabs[i].name)
          return
        }
      }
    }

    const scrollTo = (name: string) => {
      const page = isModal ? document.getElementById('modal-content') : window
      const scrollTop = document.getElementById(name)?.offsetTop
      if (!scrollTop) return
      page?.scroll({
        top: scrollTop - SCROLL_MARGIN,
        left: 0,
        behavior: 'smooth',
      })
      analytics.track('click_st_navigation_tab', { tabName: name })
    }

    useEffect(() => {
      isModal
        ? document
            .getElementById('modal-content')
            ?.addEventListener('scroll', onScroll)
        : document.addEventListener('scroll', onScroll)
      return () =>
        isModal
          ? document
              .getElementById('modal-content')
              ?.removeEventListener('scroll', onScroll)
          : document.removeEventListener('scroll', onScroll)
    })

    useEffect(() => {
      const existingTabs = STARTUP_DETAIL_TABS.filter(
        (tab) => document.getElementById(tab.name)?.offsetTop !== undefined, // 정보가 채워져서 보여지고 있는 섹션들만 걸러냄
      )
      // TODO: const 키워드로 tabs 변수를 썼었는데, 빌드 시 값이 비는 듯함 => 확인 필요
      setTabs(existingTabs)
      setSelectedTab(existingTabs[0].name)
    }, [router.asPath])

    return (
      <TabContainer isModal={isModal}>
        {tabs.map((tab) => (
          <TabName
            key={tab.name}
            isSelected={isSelected(tab.name)}
            onClick={() => scrollTo(tab.name)}
          >
            {tab.label}
          </TabName>
        ))}
      </TabContainer>
    )
  },
)

const TabContainer = styled(Row)<{ isModal?: boolean }>`
  top: ${(p) => (p.isModal ? 41 : 60)}px;
  gap: 30px;
  z-index: 99;
  position: sticky;
  margin: 40px 0 30px;
  overflow: scroll;
  border-bottom: 1px solid ${(p) => p.theme.color.line};
  background-color: ${(p) => p.theme.color.white1};
  overflow: scroll hidden;
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
  ::-webkit-scrollbar {
    display: none; // Chrome, Safari and Opera
  }
`

const TabName = styled(BoldText)<{ isSelected: boolean }>`
  color: ${(p) => (p.isSelected ? p.theme.color.primary : p.theme.color.gray2)};
  padding: ${(p) => (p.isSelected ? '12px 0 10px' : '12px 0')};
  ${(p) => p.isSelected && `border-bottom: 2px solid ${p.theme.color.primary}`};
  cursor: pointer;
  @media ${(p) => p.theme.deviceSize.desktop} {
    :hover {
      color: ${(p) => p.theme.color.primary};
    }
  }
  white-space: nowrap;
`
