import { Button } from 'components/common/button'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import { MediumText } from 'components/common/text'

export const BottomStickyBar = styled.div`
  width: 100%;
  bottom: 0;
  position: sticky;
  background-color: ${(p) => p.theme.color.white2};
  z-index: 99;
`

export const DashboardBottomStickyBar = styled(BottomStickyBar)`
  padding: 16px 0;
  border-top: 1px solid ${(p) => p.theme.color.line};
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 12px 0;
  }
`

export const BottomButtonContainer = styled(Row)`
  gap: 16px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    display: grid;
    grid-template-columns: 70px 1fr 1fr;
    gap: 8px;
  }
`

export const BottomButton = styled(Button)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    width: 200px;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 8px;
  }
`

export const BottomGuideText = styled(MediumText)`
  text-decoration: underline;
  color: ${(p) => p.theme.color.gray1};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
  }
`
