import React from 'react'
import { Badge, BadgeColor } from 'components/common/badge'

export const JobInterestLevelBadge: React.FC<{
  jobInterestLevel: number | null
}> = ({ jobInterestLevel }) => {
  if (jobInterestLevel === 1)
    return <Badge color={BadgeColor.ORANGE}>인턴만 희망</Badge>
  if (jobInterestLevel === 2)
    return <Badge color={BadgeColor.GREEN}>정규직 관심</Badge>
  if (jobInterestLevel === 3)
    return <Badge color={BadgeColor.RED}>꼭 정규직 희망</Badge>
  if (jobInterestLevel === 11)
    return <Badge color={BadgeColor.BLUE}>팀장급 희망</Badge>
  if (jobInterestLevel === 12)
    return <Badge color={BadgeColor.SECONDARY}>C-Level 희망</Badge>
  return <></>
}

export const ActivelySeekerBadge = () => (
  <Badge color={BadgeColor.RED} filled>
    적극 구직
  </Badge>
)

export const CommonCurationBadge = () => (
  <Badge color={BadgeColor.LIGHT_GREEN} filled>
    그룹바이 추천
  </Badge>
)

export const IsWorkingBadge = () => (
  <Badge color={BadgeColor.ORANGE} filled>
    재직중
  </Badge>
)
