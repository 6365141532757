import styled from 'styled-components'
import { theme } from 'infra/theme'

export enum SpeechBalloonType {
  GRAY = 'gray',
  GREEN = 'green',
  WHITE = 'white',
  LOW_GREEN = 'low-green',
}

const SpeechBalloonColorMapping = {
  [SpeechBalloonType.GRAY]: {
    color: theme.color.black1,
    backgroundColor: '#EEEEEE',
    border: '1px solid #EEEEEE',
  },
  [SpeechBalloonType.GREEN]: {
    color: theme.color.white1,
    backgroundColor: '#1DA57A',
    border: '1px solid #1DA57A',
  },
  [SpeechBalloonType.WHITE]: {
    color: theme.color.black1,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.color.line}`,
  },
  [SpeechBalloonType.LOW_GREEN]: {
    color: theme.color.black2,
    backgroundColor: theme.color.primary6,
    border: `1px solid ${theme.color.primary6}`,
  },
}

const CHAT_SPEECH_BALLOON_WIDTH = 244

export const SpeechBalloon = styled.span<{
  variant?: SpeechBalloonType
  maxWidth?: number
  position?: 'left' | 'right'
}>`
  ${(p) => SpeechBalloonColorMapping[p.variant || SpeechBalloonType.WHITE]};
  max-width: ${(p) => p.maxWidth || CHAT_SPEECH_BALLOON_WIDTH}px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.6;
  border-radius: ${(p) =>
    p.position === 'right'
      ? '16px 16px 0px 16px'
      : p.position === 'left'
      ? '16px 16px 16px 0px'
      : '16px'};
  white-space: pre-wrap;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  a {
    text-decoration: underline;
  }
`
