import React from 'react'
import { Video } from 'components/common/video'
import { Column } from 'components/common/layout'
import { SectionTitle } from 'components/startup/startup-styles'
import { LoginBlurCta } from 'components/misc/login-blur-cta'
import { analytics } from 'infra/analytics'

// 스타트업 채용설명회 컨테이너
export const StartupPresentation: React.FC<{
  pt: string
}> = ({ pt }) => (
  <Column id='presentation'>
    <SectionTitle>채용설명회</SectionTitle>
    <LoginBlurCta>
      <Video url={pt} onVideoClick={() => analytics.track('click_st_pt')} />
    </LoginBlurCta>
  </Column>
)
