import React from 'react'
import { Row } from 'components/common/layout'
import { BoldText } from 'components/common/text'
import { theme } from 'infra/theme'
import Image from 'next/legacy/image'
import styled from 'styled-components'

export const Notice: React.FCC<{ size?: 'default' | 'large' }> = ({
  children,
  size,
}) => (
  <NoticeContainer size={size}>
    <div className='icon'>
      <Image
        src='/info-primary.svg'
        alt='info'
        layout='responsive'
        height={20}
        width={20}
      />
    </div>
    <BoldText
      style={{
        flex: 1,
        color: theme.color.primary,
      }}
    >
      {children}
    </BoldText>
  </NoticeContainer>
)

const NoticeContainer = styled(Row)<{ size?: 'default' | 'large' }>`
  gap: 8px;
  width: fit-content;
  padding: ${(p) => (!p.size || p.size === 'default' ? '8px' : '16px 32px')};
  border-radius: 16px;
  background-color: ${(p) => p.theme.color.primary3};
  .icon {
    width: 20px;
    height: 20px;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 8px;
    span {
      font-size: 12px;
    }
    .icon {
      width: 12px;
      height: 12px;
    }
  }
`
