import styled from 'styled-components'
import {
  BaseText,
  DescText,
  H5Text,
  SemiBoldText,
  SmallText,
} from 'components/common/text'
import { Column, Row } from 'components/common/layout'

export const KeywordReadingCardText = styled(DescText)`
  font-weight: ${(p) => p.theme.fontWeight.medium};
`

export const KeywordReadingCardWrapper = styled(Column)`
  width: 90%;
  gap: 8px;
`

export const KeywordSelectorWrapper = styled(Column)`
  gap: 24px;
  height: 200px;
  justify-content: flex-start;
  overflow-y: scroll;
  @media ${(p) => p.theme.deviceSize.desktop} {
    margin-left: 40px;
  }
`

export const KeywordDropdownWrapper = styled(Row)`
  justify-content: space-between;
  cursor: pointer;
`

export const KeywordHeaderText = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.desc};
  color: ${(p) => p.theme.color.black2};
`

export const KeywordSubText = styled(SmallText)`
  color: ${(p) => p.theme.color.gray1};
`

export const MyProfileSectionHeader = styled(H5Text)`
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
`

export const CardDescription = styled(BaseText)`
  color: ${(p) => p.theme.color.gray1};
  @media ${(p) => p.theme.deviceSize.desktop} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    margin-right: 20px;
  }
`

export const StrongText = styled(DescText)`
  font-weight: ${(p) => p.theme.fontWeight.semiBold};
`

export const InputWrapper = styled(Row)`
  gap: 8px;
`

export const TitleWithInput = styled(Column)`
  gap: 8px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
  }
`

export const WorkExperienceHeader = styled(Column)<{ isNarrow?: boolean }>`
  @media ${(p) => p.theme.deviceSize.desktop} {
    ${(p) =>
      !p.isNarrow
        ? 'justify-content: space-between; flex-direction: row; gap: 40px;'
        : 'gap: 4px;'}
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 4px;
  }
`

export const WorkExperiencePeriodText = styled(BaseText)`
  color: ${(p) => p.theme.color.gray2};
  line-height: 1.6;
  flex: none;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 14px;
  }
`

export const SectionTitle = styled(SemiBoldText)`
  font-size: ${(p) => p.theme.fontSize.desc};
`
