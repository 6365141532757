import { Column, ExtendResponsiveLayout, Row } from 'components/common/layout'
import { DescText } from 'components/common/text'
import { ScoutBackground } from 'components/scouts/common/styles'
import styled from 'styled-components'

const CommonContainer = styled(Column)`
  height: 100%;
  overflow-y: scroll;
  justify-content: flex-start;
  border-right: 1px solid ${(p) => p.theme.color.gray3};
`

export const ChatRoomsContainer = styled(Row)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    height: calc(100vh - 120px);
  }
  overflow: hidden;
  align-items: flex-start;
  border: 1px solid ${(p) => p.theme.color.gray3};
  background-color: ${(p) => p.theme.color.white1};
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.03);
`

export const DashboardChatRoomsContainer = styled(ChatRoomsContainer)<{
  height: number
}>`
  height: calc(100vh - ${(p) => p.height}px);
`

export const ChatListContainer = styled(CommonContainer)`
  position: relative;
  justify-content: flex-start;
  width: 240px;
  flex: none;
`

export const WideChatRoomContainer = styled(CommonContainer)`
  flex: 1;
  border: none;
`

export const DashboardChatRoomWrapper = styled(Row)<{ isDimmed?: boolean }>`
  ${(p) => p.isDimmed && 'position: relative;'}
  flex: 1;
  height: 100%;
  justify-content: center;
`

export const ChatRoomContainer = styled(CommonContainer)`
  flex: 1;
  @media ${(p) => p.theme.deviceSize.desktop} {
    flex-basis: 600px;
  }
  justify-content: flex-end;
  overflow-y: hidden;
`

export const ChatInfoContainer = styled(CommonContainer)`
  flex: 1;
  flex-basis: 400px;
  max-width: 400px;
  justify-content: space-between;
  border: 0;
`

export const MessageContainer = styled(Column)`
  flex-direction: column-reverse;
  gap: 16px;
  overflow-y: scroll;
  padding-top: 100px;
  justify-content: flex-start;
  height: 100%;
`

export const ChatItemWrapper = styled(Row)`
  padding: 16px;
`

export const GuideBox = styled(Row)`
  justify-content: center;
  width: 100%;
  padding: 13px 0;
  background-color: ${(p) => p.theme.color.primary3};
  border-radius: 10px;
  color: ${(p) => p.theme.color.primary4};
  font-size: ${(p) => p.theme.fontSize.default};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
    padding: 10px 0;
  }
`

export const DescBox = styled.div`
  border-radius: 8px;
  background-color: ${(p) => p.theme.color.background2};
`

export const NoticeText = styled(DescText)`
  margin: 0 16px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
  text-align: center;
  color: ${(p) => p.theme.color.primary4};
  font-weight: ${(p) => p.theme.fontWeight.medium};
`

export const MeetTypeSelector = styled(Row)<{ isSelected: boolean }>`
  flex: 1;
  justify-content: center;
  padding: 8px;
  background-color: ${(p) =>
    p.isSelected ? p.theme.color.primary3 : p.theme.color.white1};
  color: ${(p) =>
    p.isSelected ? p.theme.color.primary : p.theme.color.black4};
  border-radius: 8px;
  border: 1px solid
    ${(p) => (p.isSelected ? p.theme.color.primary3 : '#EFF1ED')};
  cursor: pointer;
`

export const ChatBackground = styled(ScoutBackground)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    background-color: ${(p) => p.theme.color.white1};
    justify-content: flex-start;
  }
`

export const MobileChatContainer = styled(Column)<{
  isOpenChatRoom: boolean
}>`
  ${(p) => p.isOpenChatRoom && `height: calc(100dvh - 60px)`};
  justify-content: start;
  background-color: ${(p) => p.theme.color.white1};
`

export const ChatRoomsLayout = styled(ExtendResponsiveLayout)`
  padding-top: 24px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 0px;
  }
`
