import { makeAutoObservable } from 'mobx'

export class PositionModalStore {
  isOpen: boolean = false
  id: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  openModal(id: number) {
    this.isOpen = true
    this.id = id
  }

  closeModal() {
    this.isOpen = false
  }
}
