import React from 'react'
import {
  useDashboardScoutPositions,
  useDashboardScoutRequestDetail,
  useStartupDetailForProposal,
  useStartupUser,
} from 'apis/hooks'
import { DashboardTempScoutProposal } from 'types/common'
import { observer } from 'mobx-react'
import { DashboardProposalDetail } from 'components/dashboard/scouts/proposal/dashboard-proposal-detail'

export const ProposalPreview: React.FC<{
  startupId: number
  requestId: number
  proposal: DashboardTempScoutProposal
}> = observer(({ startupId, requestId, proposal }) => {
  const { data: user } = useStartupUser()
  const { data: startup } = useStartupDetailForProposal(startupId)
  const { data: request } = useDashboardScoutRequestDetail(requestId)
  const { data: positions } = useDashboardScoutPositions(requestId)

  if (!startup || !request || !positions || !user) return null
  const proposedPositions = positions.filter((item) =>
    proposal.positions.includes(item.id),
  )
  return (
    <DashboardProposalDetail
      startup={startup}
      request={request}
      proposal={proposal}
      proposedPositions={proposedPositions}
      isPublished={user.isPublished}
      isStartup
    />
  )
})
