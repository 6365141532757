import React from 'react'
import {
  SectionDesc,
  SectionPaper,
  SectionTitle,
} from 'components/startup/startup-styles'
import { Column } from 'components/common/layout'
import { TextViewer } from 'components/common/text-editor'
import { checkEditorDefaultValue } from 'utils/validations'

export const SolvingProblem: React.FC<{
  solvingProblem: string
}> = ({ solvingProblem }) => {
  if (!solvingProblem || checkEditorDefaultValue(solvingProblem)) return null
  return (
    <Column id='solvingProblem'>
      <SectionTitle>해결하는 문제</SectionTitle>
      <SectionDesc>
        문제에 공감한다면 과정에서 몰입의 행복을 느끼실 거예요.
      </SectionDesc>
      <SectionPaper>
        <TextViewer value={solvingProblem} />
      </SectionPaper>
    </Column>
  )
}
