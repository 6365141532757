import React from 'react'
import styled from 'styled-components'
import Image from 'next/legacy/image'
import { SlickSlider } from 'components/common/slider'

export const StartupImages: React.FC<{ images: string[]; width: number }> = ({
  images,
  width,
}) => {
  return (
    <>
      {images.length > 0 && (
        <SlickSliderWrapper>
          <SlickSlider settings={{ dots: true }}>
            {images.map((image, index) => (
              <Image
                key={index}
                src={image}
                alt={image}
                width={width}
                height={width * (9 / 16)}
                objectFit='cover'
                layout='responsive'
              />
            ))}
          </SlickSlider>
        </SlickSliderWrapper>
      )}
    </>
  )
}

const SlickSliderWrapper = styled.div`
  .slick-next,
  .slick-prev {
    display: flex !important;
    height: 100% !important;
    align-items: center;
    svg {
      color: ${(p) => p.theme.color.white1};
      stroke-width: 2px;
    }
  }
  .slick-next {
    box-shadow: inset -30px 0px 30px -15px rgb(0 0 0 / 25%);
  }
  .slick-prev {
    box-shadow: inset 30px 0px 30px -15px rgb(0 0 0 / 25%);
  }
  .slick-dots {
    li.slick-active {
      button::before {
        color: ${(p) => p.theme.color.white1};
      }
    }
    button::before {
      color: ${(p) => p.theme.color.white2};
    }
    bottom: 10px;
  }
`
