import { HOST_BASE_URL, IS_BROWSER, WITH_HTTP_ADDRESS } from 'infra/constants'
import NextImage, { ImageProps } from 'next/legacy/image'

export const Image: React.FC<ImageProps> = (props) => {
  if (!IS_BROWSER) {
    const url = WITH_HTTP_ADDRESS.test(props.src as string)
      ? (props.src as string)
      : `${HOST_BASE_URL}/${props.src}`
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        src={url}
        alt={props.alt as string}
        width={props.width}
        height={props.height}
      />
    )
  }

  return <NextImage {...props} />
}
